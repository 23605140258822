import React, { useRef, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { isDesktop } from 'react-device-detect';

import inlineeditorstyle from '../../css/editor/InlineEditor.module.scss'
import { UPDATE_STYLE } from '../../graphql/Style';
import {  DELETE_FILE } from '../../graphql/Images';

import SubmitButtonPrefix from './SubmitButtonPrefix';



const SubmitButton = ({ props }) => {

  const [updateStyle] = useMutation(UPDATE_STYLE);
  const [deleteFile] = useMutation(DELETE_FILE);

  const [isOpenEditField, setIsOpenEditField] = useState(false);
  const editFieldDivRef = useRef(null);

  const handleToggleCaption = async (id,required) => {

    try {
      const result = await updateStyle({
        variables: {
            myform: props.myform,
            buttoncaptiontoggle: !props.formStyle.buttoncaptiontoggle
        },
    });
        props.stylerefetch();
    } catch (error) {
        console.error('Error updating field:', error);
    }
};


  useEffect(() => {
    const handleClickOutsideEditField = (event) => {
      // Check if the ref element exists and if the event target is outside the ref element
      if (editFieldDivRef.current && !editFieldDivRef.current.contains(event.target)) {
        setIsOpenEditField(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideEditField);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEditField);
    };
  }, []);
 


  return (
    <React.Fragment>
      {isDesktop ? <li className={`${inlineeditorstyle.inline_sub_section}`} ref={editFieldDivRef}>
        <header onClick={() => setIsOpenEditField(!isOpenEditField)}>Prefix</header>
        {isOpenEditField && <SubmitButtonPrefix props={props} setIsOpenEditField={setIsOpenEditField}/>}
      </li> : <SubmitButtonPrefix  props={props} setIsOpenEditField={setIsOpenEditField}/>}
      <li className={`${props.formStyle.buttoncaptiontoggle ? inlineeditorstyle.selected : ''}`} onClick={handleToggleCaption}>Caption</li>
    </React.Fragment>
  );
};

export default SubmitButton;
