import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import createform from '../css/navigation/CreateForm.module.scss';
import { useMutation } from '@apollo/client';
import { Fade } from 'react-awesome-reveal';
import { CREATE_FORM } from '../graphql/Form';
import styleThemes from './styleThemes.json';
import CloseIcon from '@mui/icons-material/Close';

const CreateForm = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWizard, setIsWizard] = useState(false);
  const [formName, setFormName] = useState('');
  const [activeFormType, setactiveFormType] = useState('slideshow');
  const [activeStyle, setActiveStyle] = useState('Coriander');
  const [formError, setFormError] = useState('');
  const [step, setStep] = useState(1);
  const inputRef = useRef(null);
  const [createFormMutation] = useMutation(CREATE_FORM);

  const openCreateForm = () => {
    const targetDiv = document.getElementById('navigation_container');
    if (targetDiv) {
      targetDiv.style.zIndex = '0';
    }
    setIsModalOpen(true);
    setactiveFormType('slideshow');
    setActiveStyle('Coriander');
  };

  const handleCreateForm = async () => {
    // console.log(formName)
    if (step === 1) {
      if (!formName) {
        setFormError('Form name');
        return;
      } else if (!activeFormType) {
        setFormError('Please enter a form type.');
        return;
      } else if (!formName && !activeFormType) {
        setFormError('Please enter a form name and select a form type.');
        return;
      } else if (formName && activeFormType) {
        setStep(2);
        setFormError('');
      }
    } else if (step === 2) {
      if (!activeStyle) {
        setFormError('Form theme');
      } else {
        setFormError('');
        try {
          const selectedTheme = styleThemes.find((theme) => theme.name === activeStyle);
          if (selectedTheme) {
            // The theme was found, access its properties
            const { primaryColor, backgroundColor, font, formStyle, pagelayout, labelColor } = selectedTheme;
            const { data } = await createFormMutation({
              variables: {
                name: formName,
                description: '',
                status: '',
                weburl: '',
                archived: false,
                iswizard: isWizard,
                type: 'classic',
                landingsetting: '',
                style: {
                  primaryColor,
                  backgroundColor,
                  font,
                  formStyle,
                  pagelayout,
                  labelColor
                }
              }
            });

            const formID = data?.createForm?.id;
            if (formID) {
              window.location.href = '/editor/' + formID;
              // navigate(`/editor/${formID}`);
            }
          }
        } catch (error) {
          console.log('Error creating form:', error);
        }
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setStep(1);
    setFormError('');
    setFormName('');
    setactiveFormType('slideshow');
    setActiveStyle(null);
  };

  const handleFormClick = (iswizard, activeFormType) => {
    setIsWizard(iswizard);
    setactiveFormType(activeFormType);
    setFormError('');
  };

  const handleFormNameChange = (event) => {
    setFormName(event.target.value);
    setFormError('');
  };

  const handleThemeClick = (theme) => {
    setActiveStyle(theme);
    setFormError('');
  };

  const renderFormItems = () => {
    return (
      <Fade up duration={800} distance="50px" cascade>
        <ul className={createform.formTypeSelect}>
          <li onClick={() => handleFormClick(false, 'classic')} className={`${createform.classicform} ${activeFormType === 'classic' ? createform.active : ''}`}>
            <p>Classic form</p>
          </li>
          <li onClick={() => handleFormClick(true, 'slideshow')} className={`${activeFormType === 'slideshow' ? createform.active : ''}`}>
            <p>Slideshow form</p>
          </li>
        </ul>
      </Fade>
    );
  };

  const renderThemes = () => {
    return (
      <ul className={createform.themes}>
        {styleThemes.map((theme, index) => (
          <li key={index} style={{ backgroundColor: theme?.backgroundColor }} onClick={() => handleThemeClick(theme.name)} onKeyDown={handleKeyPress} tabIndex="0" className={`${activeStyle === theme.name ? createform.active : ''}`}>
            <label htmlFor={`themeRadio${index}`}>
              <input type="radio" id={`themeRadio${index}`} name="themeRadio" value={theme.name} checked={activeStyle === theme.name} onChange={() => handleThemeClick(theme.name)} />
              <div className={createform.color} style={{ backgroundColor: theme.primaryColor }}></div>
              <div className={createform.color} style={{ backgroundColor: theme.labelColor }}></div>
              <h4>{theme.name}</h4>
            </label>
          </li>
        ))}
      </ul>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreateForm();
    setFormError('');
  };
  const handleKeyPress = (event) => {
    // Check if the Enter key is pressed (key code 13)
    // console.log(formName)
    if (event.keyCode === 13) {
      event.preventDefault();
      setFormError('');
      handleCreateForm(); // Trigger the function
    }
  };

  useEffect(() => {
    // Attach the event listener to the whole document
    document.addEventListener('keydown', handleKeyPress);
    // Clean up the event listener on component unmount
    if (isModalOpen) {
      // inputRef && inputRef.current.focus();
      // setActiveStyle("Pepper");
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isModalOpen]);

  return (
    <div>
      {isModalOpen && (
        <div className={`${createform.modal} ${createform.create}`} onClick={closeModal}>
          <div className={createform.close}>
            <CloseIcon />
          </div>

          <div className={createform.modal_content} onClick={(e) => e.stopPropagation()}>
            {step === 1 && (
              <>
                <form onSubmit={handleSubmit}>
                  <h2>
                    Create a New Form <span>Step 1 of 2</span>
                  </h2>
                  <h3>Add a Title and Type</h3>

                  <label htmlFor="formName">
                    <span>
                      Form Name <i>*</i>
                    </span>
                  </label>
                  <input id="formName" placeholder="Enter a name" ref={inputRef} type="text" value={formName} onChange={handleFormNameChange} onKeyDown={handleKeyPress} className={formError === 'Form name' && createform.inputError} />
                  {formError === 'Form name' && <p className={createform.error}>Please enter a form name</p>}
                  <label htmlFor="formType">
                    <span>
                      Form Type<i>*</i>
                    </span>
                  </label>
                  {renderFormItems()}
                  <div className={createform.actions}>
                    <button className={createform.primary} type="submit" disabled={!formName}>
                      Next
                    </button>
                  </div>
                </form>
              </>
            )}

            {step === 2 && (
              <>
                <form onSubmit={handleSubmit}>
                  <h2>
                    Create a New Form <span>Step 2 of 2</span>
                  </h2>
                  <h3>Select a Theme</h3>
                  {formError === 'Form theme' && <p className={createform.error}>Please select a theme</p>}

                  {renderThemes()}
                  <input type="text" style={{ display: 'none' }} onKeyDown={handleKeyPress} />

                  <div className={createform.actions}>
                    <button
                      className={createform.secondary}
                      onClick={() => {
                        setStep(1);
                        setFormError('');
                      }}>
                      Previous
                    </button>
                    <button className={createform.primary} type="submit" disabled={!activeStyle}>
                      Create Form
                    </button>
                  </div>
                </form>
              </>
            )}
            <div className={createform.image}>
              {activeFormType === 'classic' ? (
                <p className={createform.title}>
                  <strong>{activeFormType} Survey</strong>
                  Ideal for longer forms, users can scroll to complete the survey.
                </p>
              ) : (
                <p className={createform.title}>
                  <strong>{activeFormType} Survey</strong>
                  Engaging and interactive, users click next to progress through the survey.
                </p>
              )}


              {styleThemes.map((theme, index) =>
                theme.name === activeStyle ? (
                  activeFormType === 'classic' ? (
                    <div className={createform.mockup} style={{ backgroundColor: theme.backgroundColor, color: theme.labelColor }} key={index}>
                      <div className={createform.mockup_classicframes} style={{paddingBottom: '40px'}}>
                        <div className={createform.mockup_block__classic}>
                          <div className={createform.mockup__question}>
                            <h1 style={{color: theme.labelColor, marginBottom: 0 }}>Welcome to our form</h1>
                          </div>
                        </div>
                        <div className={createform.mockup_block__classic}>
                          <div className={createform.mockup__question}>
                            <strong>What's your name?</strong>
                            <div className={createform.mockup__input}>Enter name</div>
                          </div>
                        </div>
                        <div className={createform.mockup_block__classic}>
                          <div className={createform.mockup__question}>
                            <strong>What's your email?</strong>
                            <div className={createform.mockup__input}>Enter name</div>
                          </div>
                        </div>
                        <div className={createform.mockup_block__classic}>
                          <img src="https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/marketing/app/classic.jpg" />
                        </div>
                        <div className={createform.mockup_block__classic}>
                          <div className={createform.mockup__question}>
                            <strong>Pick a season</strong>
                            <ul className={createform.mockup__multichoice}>
                              <li>
                                <strong>☀️</strong> summer
                              </li>
                              <li>
                                <strong>🍂</strong> autumn
                              </li>
                              <li>
                                <strong>🌾</strong> spring
                              </li>
                              <li>
                                <strong>❄️</strong> winter
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className={createform.mockup__button__classic}>Continue</div>
                      </div>
                    </div>
                  ) : (
                    // mockup of the slideshow
                    <div className={createform.mockup} style={{ backgroundColor: theme.backgroundColor, color: theme.labelColor }}>
                      <div className={createform.mockup_slideshowframes}>
                        <div className={createform.mockup_block__slideshow}>
                          <div className={createform.mockup__image}>
                            <img src="https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/marketing/app/3.jpg" />
                          </div>

                          <div className={createform.mockup__question}>
                            <strong>What's your name?</strong>
                            <div className={createform.mockup__input}>Enter name</div>
                          </div>
                        </div>
                        <div className={createform.mockup_block__slideshow}>
                          <div className={createform.mockup__image}>
                            <img src="https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/marketing/app/2.jpg" />
                          </div>
                          <div className={createform.mockup__question}>
                            <strong>What's your email?</strong>
                            <div className={createform.mockup__input}>Enter name</div>
                          </div>
                        </div>
                        <div className={createform.mockup_block__slideshow}>
                          <div className={createform.mockup__image}>
                            <img src="https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/marketing/app/1.jpg" />
                          </div>

                          <div className={createform.mockup__question}>
                            <strong>Pick a season</strong>
                            <ul className={createform.mockup__multichoice}>
                              <li>
                                <strong>☀️</strong> summer
                              </li>
                              <li>
                                <strong>🍂</strong> autumn
                              </li>
                              <li>
                                <strong>🌾</strong> spring
                              </li>
                              <li>
                                <strong>❄️</strong> winter
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className={createform.mockup__button__slideshow}>Continue</div>
                    </div>
                  )
                ) : (
                  ''
                )
              )}
            </div>
          </div>
        </div>
      )}

      <button className={createform.createFormButton} onClick={openCreateForm}>
        Create a Form
      </button>
    </div>
  );
};

export default CreateForm;
