import React, { useState, useEffect } from 'react';

import staffstyle from '../css/staff/Staff.module.scss'
import Templates from './Templates';
import Create from './Create'
import Category from './Category'


const StaffPage = ({ props, optionrefetch }) => {

    const [selectPage, setSelectPage] = useState('templates')

    return (
        <main className={`${staffstyle.container}`}>
            <nav className={`${staffstyle.nav}`}>
                <ul>
                    <li onClick={()=> setSelectPage('templates')} className={`${selectPage === 'templates' ? staffstyle.active_li : ''}`}>View Templates</li>
                    <li onClick={()=> setSelectPage('create')} className={`${selectPage === 'create' ? staffstyle.active_li : ''}`}>Create Template</li>
                    <li onClick={()=> setSelectPage('category')} className={`${selectPage === 'category' ? staffstyle.active_li : ''}`}>Category list</li>
                </ul>
            </nav>
            <div className={`${staffstyle.content}`}>
            {selectPage === 'templates' && <Templates />}
            {selectPage === 'create' && <Create />}
            {selectPage === 'category' && <Category />}
            </div>
        </main>
    );
};
export default StaffPage;

