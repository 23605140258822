import gql from "graphql-tag";

export const GET_FORM = gql`
  query($id: String!) {
    form(id: $id) {
      id
      name
      description
      status
      weburl
      calendarID
      logo
      pageview
      created_at
      updated_at
      archived
      iswizard
    }
  }
`;

export const GET_ALL_FORM = gql`
  query forms($page: Int, $order:String) {
    forms(page: $page, order: $order) {
      forms {
        id
        name
        description
        calendarID
        status
        weburl
        logo
        iswizard
        created_at
        updated_at
        recordCount
        pageview
      }
      totalCount
    }
  }
`;

export const GET_USER_FORMS = gql`
  query userForms($page: Int, $order:String) {
    userForms(page: $page, order: $order) {
      forms {
        id
        name
        description
        status
        calendarID
        weburl
        logo
        iswizard
        created_at
        updated_at
        recordCount
        pageview
      }
      totalCount
    }
  }
`;


export const CREATE_FORM = gql`
  mutation createForm(
    $name: String
    $description: String
    $status: String
    $weburl: String
    $archived: Boolean
    $iswizard: Boolean
    $landingsetting: String
    $style: StyleInput
  ) {
    createForm(
      name: $name
      description: $description
      status: $status
      weburl: $weburl
      archived: $archived
      iswizard: $iswizard
      landingsetting: $landingsetting
      style: $style
    ) {
      id
    }
  }
`;

export const StyleInput = gql`
input StyleInput {
  myform: String
  font: String
  primaryColor: String
  selectionColor: String
  labelColor: String
  formStyle: String
  backgroundColor: String
  pagelayout: String
  headerTextAlign: String
  fieldbgcolor: String
  logo: String
  logosize: String
  logourl: String
  bgimgposition: String
  bgimgurl: String
  backgroundformcolor: String
  buttonsubmittext: String
  bgimgtint: String
  showshadowbgthankyou: Boolean
  buttonprefix: String
  buttoncaption: String
  buttoncaptiontoggle: String
  videobackgroundtype: String
  videobackgroundurl: String
}
`;

export const UPDATE_FORM = gql`
  mutation updateForm(
    $id: String!, 
    $name: String, 
    $description: String, 
    $weburl: String, 
    $logo: String,
    $archived: Boolean
    $iswizard: Boolean
    $calendarID: String
    ) {
    updateForm(
      id: $id
      name: $name
      description: $description
      logo: $logo
      weburl: $weburl
      archived: $archived
      iswizard: $iswizard
      calendarID: $calendarID
    ) {
      id
      name
      description
      logo
      weburl
      archived
      iswizard
    }
  }
`;


export const UPDATE_WEBURL = gql`
  mutation updateForm(
    $id: String!
    $weburl: String, 
    $status: String
    ) {
    updateForm(
      id: $id
      weburl: $weburl
      status: $status
    ) {
      id  
      weburl
      status
    }
  }
`;




export const INCREMENT_PG_VIEW = gql`
  mutation incrementPageView ($id: String) {
    incrementPageView(id: $id)
  }
`;


export const ENTRY_SUMMARY = gql`
query($myform: String!) {
  entrySummary(myform: $myform) {
    totalAnswers
    totalRecord
    totalFields
    completionRate
    }
  }
  `;


export const CLONE_FORM = gql`
mutation cloneForm(
  $id: String
  $description: String
  $name: String
  $status: String
  $iswizard: Boolean
) {
  cloneForm(
    id: $id
    description: $description
    name: $name
    status: $status    
    iswizard: $iswizard
  ) {
    id
  }
}
`;

export const REMOVE_FORM= gql`
  mutation ($id: String!) {
    deleteForm(id: $id)
  }
`;

export const GET_FORM_RESULT_BY_QUESTION = gql`
  query GetFormResultByQuestion($myform: ID!, $orderBy: String!, $asc: Boolean!) { 
    getFormResultByQuestion(myform: $myform, orderBy:$orderBy, asc:$asc) {
      fieldId
      totalAnswers
      totalRecords
      completionRate
      orderInForm
      fieldLabel
      fieldType
    }
  }
`;