import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const MetaHelmet = ({ name, description, link, keywords, author }) => {
  const meta_name = name ? name : 'Untitled';
  const meta_description = description ? description : 'Some description';
  const meta_link = link ? link : '';
  const meta_keywords = keywords ? keywords : 'survey forms, form templates, online surveys, data collection, event registration';
  const meta_author = author ? author : '';

  return (
    <div>
      <Helmet>
        <title>{meta_name}</title>
        <meta name="description" content={meta_description} />
        <meta property="og:title" content={meta_name} />
        <meta property="og:description" content={meta_description} />
        <link rel="canonical" href={meta_link} />
        <meta name="keywords" content={meta_keywords} />
        <meta name="author" content={meta_author} />
        <meta name="language" content="en" />
      </Helmet>
    </div>
  );
};
export default MetaHelmet;
