import gql from "graphql-tag";


export const CREATE_FILE = gql`
  mutation($pictureUrl: String!, $name: String, $fileKey: String) {
    createChampion( pictureUrl: $pictureUrl, name: $name, fileKey: $fileKey) {
      id
      name
    }
  }
`;

export const S3_SIGN_MUTATION = gql`
  mutation($filename: String!, $filetype: String!) {
    signS3(filename: $filename, filetype: $filetype) {
      url
      signedRequest
    }
  }
`;

export const DELETE_FILE = gql`
  mutation($pictureUrl: String!, $myfield: String) {
    deleteImage(pictureUrl: $pictureUrl, myfield: $myfield)
  }
`;