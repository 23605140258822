import React, {useEffect, useState} from "react";
import axios from "axios";
import {createApi} from "unsplash-js";
import Masonry from "react-masonry-css";
import {Link} from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import EmptyContent from "../../utils/EmptyContent";

import inlinegallerystyle from "../../css/editor/InlineEditorGallery.module.scss";

const Unsplash = ({setURL, handleUpdateCaption}) => {

  const [unsplashSearchQuery, setUnpslashSearchQuery] = useState("");
  const [unsplashSearchResult, setunsplashSearchResult] = useState([]);
  const unsplashclient = createApi({
    accessKey:
      "b48103ea7a840f52c8ffbc8394c1342e9015f0c439f47d2dd88d92e444f1dca2"
  });

  const handleSubmit = (e) => {
    console.log("handle submit");

    // Perform the search or any other action you want to take on form submission
    unsplashclient.search
      .getPhotos({
        query: unsplashSearchQuery,
        perPage: 12
      })
      .then((photos) => {
        setunsplashSearchResult(photos);
      })
      .catch((error) => {
        console.error("Error fetching Unsplash photos:", error);
      });
  };


  const fetchLatestUnsplashPhotos = () => {
    unsplashclient.photos
      .list({perPage: 30, orderBy: "latest"})
      .then((photos) => {
        setunsplashSearchResult(photos);
      })
      .catch((error) => {
        console.error("Error fetching latest Unsplash photos:", error);
      });
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    // Fetch the latest Unsplash photos when the component mounts
    fetchLatestUnsplashPhotos();
  }, []);

  return (
    <div className={`${inlinegallerystyle.gallery_container}`}>
      <div className={`${inlinegallerystyle.gallery_search_wrapper}`}>
        <div
          className={`${inlinegallerystyle.gallery_search} ${inlinegallerystyle.add_url_input}`}>
          <input
            placeholder="Enter keywords (e.g. phone), then press enter"
            type="text"
            value={unsplashSearchQuery}
            onChange={(e) => setUnpslashSearchQuery(e.target.value)}
            onKeyDown={handleInputKeyDown}
          />
          {unsplashSearchResult && (
            <span
              onClick={() => handleSubmit()}
              className={inlinegallerystyle.gallery_search_button}>
              <SearchIcon />
            </span>
          )}
        </div>
        {unsplashSearchResult && (
          <p className={`${inlinegallerystyle.gallery_search_instruction}`}>
            Enter to search
          </p>
        )}
      </div>

      <div className={`${inlinegallerystyle.gallery_items}`}>

        {unsplashSearchResult && (
          <Masonry
            breakpointCols={{
              default: 4, // Number of columns by default
              1100: 4, // Number of columns for screens 1100px and up
              700: 2 // Number of columns for screens 700px and up
            }}
            className={`${inlinegallerystyle.my_masonry_grid}`}
            columnClassName={`${inlinegallerystyle.my_masonry_grid_column}`}>
            {unsplashSearchResult &&
            unsplashSearchResult.response &&
            unsplashSearchResult.response.results !== "undefined"
              ? unsplashSearchResult.response.results.map((result, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      handleUpdateCaption(
                        result.urls.regular,
                        result.user.name,
                        result.user.links.html,
                        result.alt_description,
                        "unsplash"
                      )
                    }
                    className={`${inlinegallerystyle.gallery_item}`}>
                    <img
                      key={result.id}
                      src={result.urls.regular}
                      alt={result.alt_description}
                    />
                    <span className={inlinegallerystyle.link}>
                      Photo by{" "}
                      <Link
                        target="_blank"
                        to={result.user.links.html}
                        alt={result.alt_description}>
                        {result.user.name}
                      </Link>
                    </span>
                  </div>
                ))
              : <EmptyContent />}

            {/* if theres no result found */}
            {unsplashSearchResult &&
            unsplashSearchResult.response &&
            unsplashSearchResult.response.results !== "undefined"
              ? unsplashSearchResult.response.total_pages < 1 && (
                  <EmptyContent />
                )
              : ""}
          </Masonry>
        )}
      </div>
      {/* Rest of your component code */}
    </div>
  );
};

export default Unsplash;
