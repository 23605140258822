import React, { useState, useEffect } from 'react';


const Templates = ({ props, optionrefetch }) => {

    return (
      <div>
     template

        </div>
    );
};
export default Templates;

