import gql from "graphql-tag";

export const GET_TEMPLATE_CATEGORIES = gql`
query($mytemplate: String){
  templatecategories(
    mytemplate: $mytemplate
  ){
    title
    id
    featured
  }
}
`;
export const TEMPLATE = gql`
query($shorthand: String!) {
    template(shorthand: $shorthand) {
        id
        name
        description
        category
        shorthand
        featured
        updated_at
        myform
        previewImage
        pageview
        rating
    },
  }
`;
export const GET_UNIQUE_FIELD_TYPES = gql`
  query UniqueFieldTypes($myform: String!) {
    uniqueFieldTypes(myform: $myform) {
      type
      category
      count
    }
  }
`;
export const GET_COMPLETION_TIME = gql`
query($myform: String!) {
  completionTime(myform: $myform) {
    approx_time
    total_questions
    },
  }
`;

export const ALL_CATEGORIES = gql`
  query {
    allcategories {
      title
    }
  }
`;


export const UPDATE_TEMPLATE = gql`
  mutation(
    $id: String
    $name: String
    $description: String
    $category: String
    $myform: String
    $previewImage: String
    $featured: Boolean
    $shorthand: String
  ) {
    updateTemplate(
      id: $id
      name: $name
      description: $description
      category: $category
      myform: $myform
      previewImage: $previewImage
      featured: $featured
      shorthand: $shorthand
    ) {
      id
    }
  }
`;
export const CATEGORY_TEMPLATES = gql`
  query($title: String!, $page: Int!) {
    categorytemplates(title: $title, page: $page) {
      total
      totalPages
      currentPage
      templates {
        id
        name
        description
        category
        shorthand
        featured
        updated_at
        myform
        previewImage
        pageview
        rating
      }
    }
  }
`;

export const SEARCH_TEMPLATES = gql`
query SearchTemplates($page: Int, $name: String) {
  searchTemplates(page: $page, name: $name) {
    totalPages
    currentPage
    templates{
      id
      name
      description
      category
      shorthand
      featured
      updated_at
      myform
      previewImage
      pageview
      rating
    }
  }
}

`;


export const RATE_TEMPLATE = gql`
  mutation RateTemplate($id: ID!, $rating: Int!) {
    rateTemplate(id: $id, rating: $rating) {
      id
      rating
    }
  }
`;


export const CLONE_TEMPLATE = gql`
  mutation CloneForm($id: String!) {
    cloneForm(id: $id) {
      id
    }
  }
`;


export const DELETE_CATEGORY_ROW= gql`
  mutation(
    $id: String
  ) {
    deleteCategoryrows(
      id: $id
    )
  }
`;


export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate(
    $id: String!
  ) {
    deleteTemplate(
      id: $id
    )
  }
`;


export const CREATE_CATEGORY_ROWS= gql`
  mutation(
    $mytemplate: String
    $title: String
    $featured: Boolean
  ) {
    createCategoryrows(
      mytemplate: $mytemplate
      title: $title
      featured: $featured
    )
  }
`;


export const UPDATE_SINGLE_CATEGORY= gql`
  mutation(
    $id: String
    $mytemplate: String
    $featured: Boolean    
    $title: String   
  ) {
    updateSingleCategory(
      id: $id
      mytemplate: $mytemplate
      featured: $featured
      title: $title
    )
  }
`;



export const CREATE_TEMPLATE = gql`
  mutation createTemplate(
    $name: String
    $description: String
    $category: String
    $myform: String
    $previewImage: String
    $featured: Boolean
    $shorthand: String
  ) {
    createTemplate(
      name: $name
      description: $description
      category: $category
      myform: $myform
      previewImage: $previewImage
      featured: $featured
      shorthand: $shorthand
    ){
      id
    }
  }
`;
