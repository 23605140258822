import React from 'react';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import LockIcon from '@mui/icons-material/Lock';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


const SubmitButtonPrefixes = ({ prefix }) => {

    return (
        <React.Fragment>
            {prefix === '' && <ImageNotSupportedIcon />}
            {prefix === 'lock' && <LockIcon />}
            {prefix === 'clock' && <WatchLaterIcon />}
            {prefix === 'video' && <PlayCircleIcon />}
            {prefix === 'pdf' && <PictureAsPdfIcon />}
        </React.Fragment>
    );
};
export default SubmitButtonPrefixes;

