import React from 'react';

const Spinner = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '60px',
      height: '100vh',
      margin: 'auto'
    }}
  >
    <div
      style={{
        width: '20px',
        height: '20px',
        backgroundColor: '#c48460',
        borderRadius: '50%',
        animation: 'bounce 1s infinite'
      }}
    ></div>
    <style>
      {`
        @keyframes bounce {
          0%, 50% {
            transform: translateY(-20px);
            border-radius: 50%;
          }
          25% {
            transform: translateY(0) scale(1, 0.5);
            border-radius: 12px;
          }
          75% {
            transform: translateY(0) scale(1, 0.5);
            border-radius: 12px;
          }
          100% {
            transform: translateY(-20px);
            border-radius: 50%;
          }
        }
      `}
    </style>
  </div>
);

export default Spinner;
