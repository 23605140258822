import React from 'react';


export const BlockName = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2861)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#53248E" />
                <path d="M8 8C9.38125 8 10.5 6.88125 10.5 5.5C10.5 4.11875 9.38125 3 8 3C6.61875 3 5.5 4.11875 5.5 5.5C5.5 6.88125 6.61875 8 8 8ZM8 9.25C6.33125 9.25 3 10.0875 3 11.75V13H13V11.75C13 10.0875 9.66875 9.25 8 9.25Z" fill="#53248E" />
            </g>
            <defs>
                <clipPath id="clip0_10_2861">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};


export const BlockEmail = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2866)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#53248E" />
                <path d="M12 4H4C3.45 4 3.005 4.45 3.005 5L3 11C3 11.55 3.45 12 4 12H12C12.55 12 13 11.55 13 11V5C13 4.45 12.55 4 12 4ZM12 6L8 8.5L4 6V5L8 7.5L12 5V6Z" fill="#53248E" />
            </g>
            <defs>
                <clipPath id="clip0_10_2866">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};


export const BlockPhone = (props) => {
    return (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_503_360" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
<path d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z" fill="white"/>
</mask>
<g mask="url(#mask0_503_360)">
<path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#53248E"/>
</g>
<path d="M8.67222 10.9889C7.1 10.1889 5.81667 8.9 5.01111 7.32778L6.23333 6.10556C6.38333 5.95556 6.43333 5.73333 6.36667 5.53889C6.16111 4.91667 6.05 4.24444 6.05 3.55556C6.05 3.25 5.8 3 5.49444 3L3.55556 3C3.25 3 3 3.25 3 3.55556C3 8.77222 7.22778 13 12.4444 13C12.75 13 13 12.75 13 12.4444V10.5C13 10.1944 12.75 9.94444 12.4444 9.94444C11.75 9.94444 11.0833 9.83333 10.4611 9.62778C10.2667 9.56667 10.05 9.61111 9.89444 9.76667L8.67222 10.9889Z" fill="#53248E"/>
</svg>

    );
};


export const BlockWebsite = (props) => {
    return (

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2877)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#53248E" />
                <path d="M7.54545 3L3 6.33333V13H5.84091V9.11111H9.25V13H12.0909V6.33333L7.54545 3Z" fill="#53248E" />
            </g>
            <defs>
                <clipPath id="clip0_10_2877">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};

export const BlockLogo = (props) => {
    return (

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_4_123)">
<path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#D07D00"/>
<rect x="2" y="4" width="6" height="3.42857" rx="0.5" fill="#D07D00"/>
<rect x="9" y="4" width="5" height="3.42857" rx="0.5" fill="#D07D00"/>
<rect x="2" y="8.57141" width="4" height="3.42857" rx="0.5" fill="#D07D00"/>
<rect x="7" y="8.57141" width="7" height="3.42857" rx="0.5" fill="#D07D00"/>
</g>
<defs>
<clipPath id="clip0_4_123">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

    );
};

export const BlockNumber = (props) => {
    return (

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2882)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#53248E" />
                <path d="M3.58826 5.11538H4.62408V10.8846H3.43288V6.31868L2.29348 6.62363L2 5.6511L3.58826 5.11538Z" fill="#53248E" />
                <path d="M5.38988 10.8846V10.0357L7.45289 8.00824C7.91325 7.5467 8.14344 7.16209 8.14344 6.8544C8.14344 6.62912 8.06863 6.4478 7.91901 6.31044C7.77514 6.17308 7.58812 6.1044 7.35794 6.1044C6.90333 6.1044 6.56669 6.32967 6.34802 6.78022L5.34672 6.21978C5.53662 5.82418 5.80996 5.52198 6.16675 5.31319C6.52353 5.1044 6.91484 5 7.34068 5C7.88736 5 8.35635 5.16484 8.74766 5.49451C9.13897 5.81868 9.33463 6.25824 9.33463 6.81319C9.33463 7.41209 9.00374 8.02747 8.34197 8.65934L7.15941 9.78846H9.42958V10.8846H5.38988Z" fill="#53248E" />
                <path d="M12.6189 7.45604C13.0275 7.57143 13.3584 7.77473 13.6116 8.06593C13.8705 8.35165 14 8.6978 14 9.1044C14 9.6978 13.79 10.1621 13.3699 10.4973C12.9555 10.8324 12.4463 11 11.842 11C11.3702 11 10.9472 10.8984 10.5732 10.6951C10.2049 10.4863 9.93728 10.1813 9.7704 9.78022L10.789 9.21978C10.9386 9.66484 11.2896 9.88736 11.842 9.88736C12.147 9.88736 12.383 9.81868 12.5499 9.68132C12.7225 9.53846 12.8088 9.34615 12.8088 9.1044C12.8088 8.86813 12.7225 8.67857 12.5499 8.53571C12.383 8.39286 12.147 8.32143 11.842 8.32143H11.5831L11.1256 7.66209L12.3168 6.17857H9.95167V5.11538H13.7497V6.05495L12.6189 7.45604Z" fill="#53248E" />
            </g>
            <defs>
                <clipPath id="clip0_10_2882">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};

export const BlockShortAnswer = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2887)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#53248E" />
                <rect x="3" y="7" width="10" height="4" rx="1" fill="#53248E" />
                <rect x="3" y="4" width="5" height="1" rx="0.5" fill="#53248E" />
            </g>
            <defs>
                <clipPath id="clip0_10_2887">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};



export const BlockLongAnswer = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2893)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#53248E" />
                <rect x="3" y="6" width="10" height="6" rx="1" fill="#53248E" />
                <rect x="3" y="4" width="5" height="1" rx="0.5" fill="#53248E" />
            </g>
            <defs>
                <clipPath id="clip0_10_2893">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>



    );
};




export const BlockDate = (props) => {
    return (

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2899)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#53248E" />
                <path d="M11.29 3.81818H10.885V3H10.075V3.81818H6.025V3H5.215V3.81818H4.81C4.3645 3.81818 4 4.18636 4 4.63636V11.1818C4 11.6318 4.3645 12 4.81 12H11.29C11.7355 12 12.1 11.6318 12.1 11.1818V4.63636C12.1 4.18636 11.7355 3.81818 11.29 3.81818ZM11.29 11.1818H4.81V5.86364H11.29V11.1818Z" fill="#53248E" />
            </g>
            <defs>
                <clipPath id="clip0_10_2899">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};


export const BlockInlineMC = (props) => {
    return (

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2908)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#C96B5E" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12ZM8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13Z" fill="#C96B5E" />
                <circle cx="8" cy="8" r="3" fill="#C96B5E" />
            </g>
            <defs>
                <clipPath id="clip0_10_2908">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};


export const BlockDropdownMC = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2914)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#C96B5E" />
                <path d="M8.84615 11.6566C8.45392 12.2793 7.54608 12.2793 7.15385 11.6566L3.76935 6.28294C3.34993 5.61702 3.82851 4.75 4.6155 4.75L11.3845 4.75C12.1715 4.75 12.6501 5.61702 12.2307 6.28294L8.84615 11.6566Z" fill="#C96B5E" />
            </g>
            <defs>
                <clipPath id="clip0_10_2914">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>



    );
};


export const BlockSliderMC = (props) => {
    return (

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_543_30)">
<path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#C96B5E"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.125 6H2.875C2.94404 6 3 6.08153 3 6.1821V10.8179C3 10.9185 2.94404 11 2.875 11H2.125C2.05596 11 2 10.9185 2 10.8179V6.1821C2 6.08153 2.05596 6 2.125 6Z" fill="#C96B5E"/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.125 6H13.875C13.944 6 14 6.08153 14 6.1821V10.8179C14 10.9185 13.944 11 13.875 11H13.125C13.056 11 13 10.9185 13 10.8179V6.1821C13 6.08153 13.056 6 13.125 6Z" fill="#C96B5E"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11 4H5C4.44772 4 4 4.44772 4 5V11.4486C4 12.0009 4.44772 12.4486 5 12.4486H11C11.5523 12.4486 12 12.0009 12 11.4486V5C12 4.44772 11.5523 4 11 4ZM7.67227 10L10 7.49087L9.38018 7L7.62616 8.89071L6.57725 7.92159L6 8.45493L7.67227 10Z" fill="#C96B5E"/>
</g>
<defs>
<clipPath id="clip0_543_30">
<rect width="16" height="16" rx="2" fill="white"/>
</clipPath>
</defs>
</svg>


    );
};


export const BlockRating = (props) => {
    return (

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2932)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#AD7745" />
                <path d="M7.54384 3.01636C7.72053 2.62267 8.27947 2.62267 8.45616 3.01636L9.54722 5.44732C9.61993 5.60931 9.7731 5.72059 9.94963 5.73968L12.5988 6.02613C13.0278 6.07253 13.2005 6.6041 12.8807 6.89381L10.9059 8.68267C10.7743 8.80188 10.7158 8.98194 10.7522 9.15573L11.2984 11.7637C11.3868 12.1861 10.9346 12.5146 10.5603 12.3L8.2487 10.9746C8.09466 10.8863 7.90534 10.8863 7.7513 10.9746L5.43973 12.3C5.06538 12.5146 4.61319 12.1861 4.70165 11.7637L5.24784 9.15573C5.28424 8.98194 5.22574 8.80188 5.09414 8.68267L3.11932 6.89381C2.7995 6.6041 2.97222 6.07253 3.40124 6.02613L6.05037 5.73968C6.2269 5.72059 6.38007 5.60931 6.45278 5.44732L7.54384 3.01636Z" fill="#AD7745" />
            </g>
            <defs>
                <clipPath id="clip0_10_2932">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};


export const BlockScale = (props) => {
    return (

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2937)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#AD7745" />
                <circle cx="1.31579" cy="1.31579" r="1.31579" transform="matrix(1 0 0 -1 3 9.31592)" fill="#AD7745" />
                <circle cx="1.31579" cy="1.31579" r="1.31579" transform="matrix(1 0 0 -1 6.68457 9.31592)" fill="#AD7745" />
                <circle cx="1.31579" cy="1.31579" r="1.31579" transform="matrix(1 0 0 -1 10.3682 9.31592)" fill="#AD7745" />
                <rect width="7.36842" height="0.526316" transform="matrix(1 0 0 -1 4.31543 8.26318)" fill="#AD7745" />
            </g>
            <defs>
                <clipPath id="clip0_10_2937">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};


export const BlockSignature = (props) => {
    return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_545_44)">
    <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#53248E"/>
    <path d="M8.24982 13.0001C7.71591 13.0001 7.22303 12.8156 6.83463 12.4531C6.22929 11.8886 6.04031 11.0053 6.35285 10.2026C6.76587 9.14249 7.65646 8.27519 8.71076 7.84708C8.38558 7.70623 7.87961 7.66985 7.12965 8.03119C7.02939 8.07955 6.8841 8.16761 6.73016 8.26099C5.95069 8.73413 4.32855 9.71833 3.04467 8.34462C2.50078 7.76279 2.27253 6.9647 2.41782 6.15506C2.57841 5.26092 3.15624 4.50541 4.00292 4.08196C4.38733 3.89054 4.85292 4.04581 5.04457 4.42911C5.23622 4.81263 5.08073 5.27889 4.69743 5.47076C4.29328 5.67284 4.01911 6.02242 3.94591 6.4299C3.91197 6.61955 3.89667 6.98289 4.17904 7.28478C4.50622 7.63547 4.89817 7.55673 5.92496 6.93364C6.11151 6.82052 6.28785 6.7136 6.4551 6.63308C8.44214 5.67483 9.86643 6.4432 10.4824 7.39236C10.5232 7.45469 10.5611 7.52146 10.5975 7.59177C11.3615 7.7029 12.0209 8.0933 12.4767 8.72282C12.7283 9.06996 12.6504 9.55574 12.3033 9.80728C11.9564 10.059 11.4706 9.98074 11.219 9.63382C11.1538 9.54398 11.0831 9.46834 11.0081 9.40535C11.0247 9.92063 10.9708 10.4454 10.8391 10.9082C10.5929 11.773 10.1038 12.4001 9.42435 12.7204C9.02707 12.9074 8.62802 13.0001 8.24982 13.0001ZM9.44386 9.23056C8.71786 9.473 8.07548 10.0584 7.79998 10.7664C7.71813 10.976 7.75406 11.1872 7.89381 11.3176C8.0757 11.4873 8.40022 11.4862 8.76156 11.3156C9.09273 11.1595 9.26197 10.7762 9.3456 10.4825C9.46028 10.0794 9.47958 9.62872 9.44386 9.23056Z" fill="#53248E"/>
    </g>
    <defs>
    <clipPath id="clip0_545_44">
    <rect width="16" height="16" rx="2" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    );
};


export const BlockUploadFile = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_2957)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#53248E" />
                <path d="M6.28571 10.6471H9.71429V7.11765H12L8 3L4 7.11765H6.28571V10.6471ZM4 11.8235H12V13H4V11.8235Z" fill="#53248E" />
            </g>
            <defs>
                <clipPath id="clip0_10_2957">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};


export const BlockHeroText = (props) => {
    return (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_4_123)">
<path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#D07D00"/>
<path d="M5.12641 12V4.45286H3V3H8.57909V4.45286H6.45268V12H5.12641Z" fill="#D07D00"/>
<path d="M11.9258 12C10.8078 12 10.0734 11.4086 10.0734 9.98143V6.6H9.07601V5.26286H10.1502V3.37286H11.3778V5.26286H13V6.6H11.3778V9.96857C11.3778 10.4443 11.5422 10.65 11.9368 10.65H12.9342V12H11.9258Z" fill="#D07D00"/>
</g>
<defs>
<clipPath id="clip0_4_123">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>



    );
};
export const BlockParagraphText = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12_3161)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#D07D00" />
                <path d="M9.66667 7.2H3V8.8H9.66667V7.2ZM3 12H13V10.4H3V12ZM3 4V5.6H13V4H3Z" fill="#D07D00" />
            </g>
            <defs>
                <clipPath id="clip0_12_3161">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};
export const BlockBulletPonts = (props) => {
    return (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_4_123)">
<path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#D07D00"/>
<path d="M5 6V7.66667H14V6H5Z" fill="#D07D00"/>
<path d="M5 8.83333H12.0588V10.5H5V8.83333Z" fill="#D07D00"/>
<path d="M3.3 6C2.85817 6 2.5 6.35817 2.5 6.8V6.8C2.5 7.24183 2.85817 7.6 3.3 7.6V7.6C3.74183 7.6 4.1 7.24183 4.1 6.8V6.8C4.1 6.35817 3.74183 6 3.3 6V6Z" fill="#D07D00"/>
</g>
<defs>
<clipPath id="clip0_4_123">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>



    );
};
export const BlockCards = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12_3161)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#D07D00" />
                <path d="M9.66667 7.2H3V8.8H9.66667V7.2ZM3 12H13V10.4H3V12ZM3 4V5.6H13V4H3Z" fill="#D07D00" />
            </g>
            <defs>
                <clipPath id="clip0_12_3161">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};
export const BlockContactDetails = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12_3250)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#BF5D8C" />
                <path d="M8 8C9.38125 8 10.5 6.88125 10.5 5.5C10.5 4.11875 9.38125 3 8 3C6.61875 3 5.5 4.11875 5.5 5.5C5.5 6.88125 6.61875 8 8 8ZM8 9.25C6.33125 9.25 3 10.0875 3 11.75V13H13V11.75C13 10.0875 9.66875 9.25 8 9.25Z" fill="#BF5D8C" />
            </g>
            <defs>
                <clipPath id="clip0_12_3250">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};
export const BlockTOC = (props) => {
    return (

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12_3255)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#BF5D8C" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.8 4H3.97778C3.44 4 3 4.44 3 4.97778V11.8222C3 12.36 3.44 12.8 3.97778 12.8H11.8C12.3378 12.8 12.7778 12.36 12.7778 11.8222V4.97778C12.7778 4.44 12.3378 4 11.8 4ZM6.91111 10.8444H4.46667V9.86667H6.91111V10.8444ZM6.91111 8.88889H4.46667V7.91111H6.91111V8.88889ZM6.91111 6.93333H4.46667V5.95556H6.91111V6.93333ZM9.26755 9.86667L7.88889 8.47822L8.57822 7.78889L9.26755 8.48311L10.8173 6.93333L11.5116 7.62756L9.26755 9.86667Z" fill="#BF5D8C" />
            </g>
            <defs>
                <clipPath id="clip0_12_3255">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};
export const BlockVoucher = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12_3332)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#BF5D8C" />
                <path d="M13.5424 4H6.20823V4.95455C6.20823 5.13042 6.06592 5.27273 5.89005 5.27273C5.71418 5.27273 5.57186 5.13042 5.57186 4.95455V4H3.31818C3.14231 4 3 4.14231 3 4.31818V6.22727C3 6.40314 3.14231 6.54545 3.31818 6.54545C3.84454 6.54545 4.27273 6.97364 4.27273 7.5C4.27273 8.02636 3.84454 8.45455 3.31818 8.45455C3.14231 8.45455 3 8.59686 3 8.77273V10.6818C3 10.8577 3.14231 11 3.31818 11H5.54545V10.0455C5.54545 9.86958 5.68777 9.72727 5.86364 9.72727C6.03951 9.72727 6.18182 9.86958 6.18182 10.0455V11H13.5424C13.7183 11 13.8606 10.8577 13.8606 10.6818V4.31818C13.8606 4.14231 13.7183 4 13.5424 4ZM6.18182 8.77273C6.18182 8.9486 6.03951 9.09091 5.86364 9.09091C5.68777 9.09091 5.54545 8.9486 5.54545 8.77273V8.13636C5.54545 7.96049 5.68777 7.81818 5.86364 7.81818C6.03951 7.81818 6.18182 7.96049 6.18182 8.13636V8.77273ZM6.18182 6.86364C6.18182 7.03951 6.03951 7.18182 5.86364 7.18182C5.68777 7.18182 5.54545 7.03951 5.54545 6.86364V6.22727C5.54545 6.0514 5.68777 5.90909 5.86364 5.90909C6.03951 5.90909 6.18182 6.0514 6.18182 6.22727V6.86364Z" fill="#BF5D8C" />
            </g>
            <defs>
                <clipPath id="clip0_12_3332">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};
export const BlockImage = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12_3385)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#4B6E97" />
                <path d="M12 11.1111V4.88889C12 4.4 11.6 4 11.1111 4H4.88889C4.4 4 4 4.4 4 4.88889V11.1111C4 11.6 4.4 12 4.88889 12H11.1111C11.6 12 12 11.6 12 11.1111ZM6.44444 8.66667L7.55556 10.0044L9.11111 8L11.1111 10.6667H4.88889L6.44444 8.66667Z" fill="#4B6E97" />
            </g>
            <defs>
                <clipPath id="clip0_12_3385">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};
export const BlockVideo = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12_3395)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#4B6E97" />
                <path d="M11 7.25V5.5C11 5.225 10.775 5 10.5 5H4.5C4.225 5 4 5.225 4 5.5V10.5C4 10.775 4.225 11 4.5 11H10.5C10.775 11 11 10.775 11 10.5V8.75L13 10.75V5.25L11 7.25Z" fill="#4B6E97" />
            </g>
            <defs>
                <clipPath id="clip0_12_3395">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};
export const BlockPDF = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12_3425)">
                <path opacity="0.2" d="M0 0H16V16H0V0Z" fill="#4B6E97" />
                <path d="M12 3H6C5.45 3 5 3.45 5 4V10C5 10.55 5.45 11 6 11H12C12.55 11 13 10.55 13 10V4C13 3.45 12.55 3 12 3ZM7.75 6.75C7.75 7.165 7.415 7.5 7 7.5H6.5V8.5H5.75V5.5H7C7.415 5.5 7.75 5.835 7.75 6.25V6.75ZM10.25 7.75C10.25 8.165 9.915 8.5 9.5 8.5H8.25V5.5H9.5C9.915 5.5 10.25 5.835 10.25 6.25V7.75ZM12.25 6.25H11.5V6.75H12.25V7.5H11.5V8.5H10.75V5.5H12.25V6.25ZM6.5 6.75H7V6.25H6.5V6.75ZM4 5H3V12C3 12.55 3.45 13 4 13H11V12H4V5ZM9 7.75H9.5V6.25H9V7.75Z" fill="#4B6E97" />
            </g>
            <defs>
                <clipPath id="clip0_12_3425">
                    <rect width="16" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};