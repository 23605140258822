import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { SEARCH_TEMPLATES } from '../graphql/Template';
import { IconPoodle } from '../utils/EmptyStateIcon';
import template from '../css/template/Template.module.scss';
import Navigation from '../nav/Navigation';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import Pagination from '../utils/Pagination';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import SearchIcon from '@mui/icons-material/Search';

function TemplateSearch({initialValue, hideSearchBar, setTargetTemplate, setCreateTemplateVisible}) {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    performSearch(value.trim(), 1);
    
  };

  const [searchTemplates, { data: searchResults, loading: searchLoading, error: searchError, refetch: searchRefetch }] = useLazyQuery(SEARCH_TEMPLATES);
  const handleTemplateClick = async (template) =>{
    setTargetTemplate(template)
    setCreateTemplateVisible(true)
  }
  const performSearch = async (searchTerm, page) => {
    try {
      await searchTemplates({
        variables: {
          category: null,
          name: searchTerm,
          page: page,
        },
      });
    } catch (error) {
      console.error('Search error:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    console.log(page + "current page Templates component")
    performSearch(searchValue.trim(), page);
  };

  const renderRating = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;

    const stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(<StarIcon key={`star-${i}`} />);
    }

    if (hasHalfStar) {
      stars.push(<StarHalfIcon key={`star-half`} />);
    }

    const emptyStars = 5 - stars.length;
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<StarOutlineIcon key={`star-empty-${i}`} />);
    }

    return stars;
  };

  useEffect(() => {
    // Perform search with empty string on component load
    if (!initialValue) {
        performSearch('', 1);
    }else {
        performSearch(initialValue, 1);
    }
  }, []);

  useEffect(() => {
    if (searchResults?.searchTemplates) {
      setCurrentPage(searchResults.searchTemplates.currentPage);
      setTotalPages(searchResults.searchTemplates.totalPages);
    }
  }, [searchResults]);


  return (
    <div>
      
      {!hideSearchBar?
      <div className={template.template_search__wrapper}>
        <form className={template.template_search}>
          <input
            type="search"
            placeholder="Search for a keyword"
            value={searchValue}
            onChange={handleSearchInputChange}
          />
          <a type="submit">
            <SearchIcon />
            </a>
        </form>
        </div>
        :''}
      <div className={template.template_search}>
          {searchLoading ? (
            <p>Loading search results...</p>
          ) : searchError ? (
            <p>Error occurred while searching templates. Check the console for details.</p>
          ) : searchResults?.searchTemplates?.templates?.length > 0 ? (
            <div className={template.template_search__items}>
            {searchResults.searchTemplates.templates.map((searchTemplate, index) => (
              <div onClick={()=>handleTemplateClick(searchTemplate.shorthand)} style={{padding:"12px",backgroundColor:"white", border:"none"}} className={template.template_search__item} >
              {/* <div  key={`template-${searchTemplate.id}`}></div> */}
                <div className={template.header}>
                  <h3>{searchTemplate.name}</h3>
                  <article className={template.meta__search}>
                  <label>
                    <VisibilitySharpIcon /> <i>{searchTemplate && (searchTemplate.pageview === null ? ' 0' : searchTemplate.pageview)} Views</i>
                  </label>
                  <label className={template.rater}>{renderRating(searchTemplate.rating)}</label>
                  </article>
                </div>
                <img src={searchTemplate.previewImage} alt={searchTemplate.name} title={searchTemplate.name} className={template.template_preview_image} />

              </div>
              
            ))}
        </div>

          ) : (
            <div className={template.search_emptyState}>
              <img src="/IconHotDog.svg" />
              <h1>No templates found</h1>
              <p>Try searching something else?</p>
            </div>
          )}
      </div>
        {totalPages > 0 && (
          <div className={template.pagination}>
            {totalPages > 0 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}
    </div>
  );
}

export default TemplateSearch;
