import React from 'react';
import pagination from '../css/utils/Pagination.module.scss';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
function Pagination({ currentPage, totalPages, onPageChange }) {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className={pagination.pagination} style={{ display: totalPages === 1 ? 'none' : 'flex' }}>
      {/* {currentPage + " current page" + totalPages + " totalpages"} */}
      <button
        disabled={currentPage === 1 | !currentPage }
        onClick={() => onPageChange(currentPage - 1)}
        style={{ display: currentPage === 1 ? 'none' : 'inline-block' }}
      >
        <ChevronLeftIcon />
      </button>
      {pageNumbers.map((number) => (
        <button
          key={number}
          className={currentPage === number ? pagination.active : ''}
          onClick={() => onPageChange(number)}
        >
          {number}

        </button>
      ))}
      <button
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
        style={{ display: currentPage === totalPages ? 'none' : 'inline-block' }}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );
}

export default Pagination;
