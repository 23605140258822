import React, { useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ProtectedRoute from './utils/ProtectedRoute';
import ProtectedRouteMember from './utils/ProtectedRouteMember';
import ProtectedRouteStaff from './utils/ProtectedRouteStaff';

// import stripePromise from './stripe.js'; // Replace with the correct path to stripe.js

import './App.scss';
import Homepage from './Homepage';
// import Ai from './hub/Ai';

import Page404 from './utils/Page404';
import TemplatesAdmin from './staff/TemplatesAdmin'
import ManageTemplate from './staff/ManageTemplate'
import CreateTemplate from './staff/CreateTemplate'
import Editor from './editor/Editor';
// import Form from './form/Form';
// import Template from './template/Template';
// import Templates from './template/Templates';
// import CategoryTemplates from './template/CategoryTemplates';
// import Register from './user/Register';
// import Login from './user/Login';
// import Premium from './user/Premium';
// import ChangePassword from './user/ChangePassword';
// import PasswordResetRequest from './user/PasswordResetRequest';
import StaffPage from './private/StaffPage';


import AirtableOAuth from './hub/AirtableOauth';
import AirtableCallback from './hub/AirtableCallback';
import calendarOAuth from './hub/CalendarOAuth'

const SpiceRack = lazy(() => import('./spice-rack/SpiceRack'));
const Ai = lazy(() => import('./hub/Ai'));
const Form = lazy(() => import('./form/Form'));

const Template = lazy(() => import('./template/Template'));
const Templates = lazy(() => import('./template/Templates'));

const CategoryTemplates = lazy(() => import('./template/CategoryTemplates'));


const Register = lazy(() => import('./user/Register'));
const Login = lazy(() => import('./user/Login'));
const Premium = lazy(() => import('./user//Premium'));
const ChangePassword = lazy(() => import('./user/ChangePassword'));
const PasswordResetRequest = lazy(() => import('./user/PasswordResetRequest'));


function App() {
  // const [stripeLoaded, setStripeLoaded] = useState(false);
  // useEffect(() => {
  //   // Load Stripe only once when the component mounts
  //   stripePromise.then(() => {
  //     setStripeLoaded(true);
  //   });
  // }, []);

  // if (!stripeLoaded) {
  //   // You can show a loading screen here until Stripe is initialized
  //   return <div>Loading...</div>;
  // }

  return (
    <HelmetProvider>
      <React.Fragment>
        <Router>
          <Routes>
            <Route path="/" element={<ProtectedRoute element={Homepage} />} />
            <Route path="/hub" element={<ProtectedRoute element={Homepage} />} />
            <Route path="/auth-Airtable" element={<ProtectedRoute element={AirtableOAuth} />} />
            <Route path="/calendarOAuth" element={<ProtectedRoute element={calendarOAuth} />} />
            <Route path="/connect-Airtable" element={<ProtectedRoute element={AirtableCallback} />} />
            <Route path="/hub/:myformId" element={<ProtectedRoute element={Homepage} />} />
            <Route path="/register" element={<Suspense fallback={<div></div>}><Register /></Suspense>} />
            <Route path="/login" element={<Suspense fallback={<div></div>}><Login /></Suspense>} />
            <Route path="/ai" element={<Suspense fallback={<div>Loading...</div>}><Ai /></Suspense>} />
            <Route path="/pantry" element={<Suspense fallback={<div>Loading...</div>}><SpiceRack /></Suspense>} />
            

            
            <Route path="/staff/update-template/:shorthand" element={<ProtectedRouteStaff element={ManageTemplate} />} />
            <Route path="/staff/create-template" element={<ProtectedRouteStaff element={CreateTemplate} />} />
            <Route path="/staff/templates" element={<ProtectedRouteStaff element={TemplatesAdmin} />} />
            
            <Route path="/password-reset/:resetToken" element={<Suspense fallback={<div></div>}><ChangePassword /></Suspense>} />
            <Route path="/forgot" element={<Suspense fallback={<div></div>}><PasswordResetRequest /></Suspense>} />
            <Route exact path="/premium" element={<Suspense fallback={<div></div>}><Premium /></Suspense>} />
{/* 
            <Route exact path="/editor/:myform" element={<Editor />} /> */}
            <Route exact path="/editor/:myform" element={<ProtectedRoute element={Editor} />} />
            <Route exact path="/private/" element={<ProtectedRoute element={StaffPage} />} />

            <Route exact path="/form/:myform" element={<Suspense fallback={<div></div>}><Form /></Suspense>} />
            <Route exact path="/template/:shorthand" element={<Suspense fallback={<div></div>}><Template /></Suspense>} />
            <Route exact path="/templates" element={<Suspense fallback={<div></div>}><Templates /></Suspense>} />
            <Route exact path="/category/:category" element={<Suspense fallback={<div></div>}><CategoryTemplates /></Suspense>} />
            
            {/* <Route exact path="/private/sandbox/form/:myform" element={<ProtectedRoute element={FormSandbox} />} />
            <Route exact path="/private/sandbox/gallery/:myform" element={<ProtectedRoute element={GallerySandbox} />} />
            <Route exact path="/private/sandbox/multichoice/:myform" element={<ProtectedRoute element={MultichoiceSandbox} />} />
            <Route exact path="/private/sandbox/:myform" element={<ProtectedRoute element={Sandbox} />} />
            
             */}
            
            <Route path='*' element={<Page404 />} />
          </Routes>
        </Router>
      </React.Fragment>
    </HelmetProvider>
  );
}

export default App;
