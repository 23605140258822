import React, { useState, useRef, useEffect } from 'react';
import styles from '../css/navigation/Navigation.module.scss';
import { formatDateTime } from '../utils/Formatting';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useQuery, useMutation } from '@apollo/client';
import { MY_NOTIFICATIONS, UPDATE_NOTIFICATION, UNREAD_NOTIFICATION_COUNT } from '../graphql/User';
import { Link } from 'react-router-dom';

function Notification({ notificationOpen }) {
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const [noMoreToFetch, setNoMoreToFetch] = useState(false);
  const [offset, setOffset] = useState(1);
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const { data: notificationData, loading, error, fetchMore } = useQuery(MY_NOTIFICATIONS, {
    variables: { offset: null }, // Number of notifications to fetch initially
  });
  const { data: unreadNotificationData, unreadNotificationLoading, unreadNotificationError, refetch:unreadNotificationRefetch } = useQuery(UNREAD_NOTIFICATION_COUNT);
  const notificationMenuRef = useRef(null);
  
  const toggleMenu = () => {
    setNotificationMenuOpen(!notificationMenuOpen);
    notificationOpen(notificationMenuOpen);
    const targetDiv = document.getElementById('navigation_container');
    if (targetDiv) {
      targetDiv.style.zIndex = '2';
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (notificationMenuRef.current && !notificationMenuRef.current.contains(event.target)) {
        setNotificationMenuOpen(false);
        notificationOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
//   const NOTIFICATIONS_PER_PAGE = 2;

  // Function to load more notifications
  const handleLoadMore = () => {
    if (notificationData?.mynotifications.length) {
        setOffset((prevOffset) => prevOffset + 1); // Increment the offset
        const lastNotificationCursor = notificationData.mynotifications[notificationData.mynotifications.length - 1].cursor;
      fetchMore({
        variables: { after: lastNotificationCursor, offset: offset },
        updateQuery: (prevResult, { fetchMoreResult }) => {
            console.log(prevResult?.mynotifications.length + " a")
            console.log(fetchMoreResult?.mynotifications.length + " b")

          if (!fetchMoreResult) {
            return prevResult
            };
            
        console.log(fetchMoreResult)   
        if (fetchMoreResult?.mynotifications.length === 0 ){
                setNoMoreToFetch(true)
                console.log(noMoreToFetch)
            }
          return {
            ...prevResult,
            mynotifications: [...prevResult.mynotifications, ...fetchMoreResult.mynotifications],
          };
        },
      });
    }
  };

  const handleNotificationClick = async (notificationId) => {
    try {
      await updateNotification({
        variables: {
          id: notificationId,
          seen: true,
        },
      });
      unreadNotificationRefetch();
    } catch (error) {
      console.error('Error updating notification:', error.message);
    }
  };

  return (
    <div className={styles.navigation_notification}> 
      <>
        <div className={`${styles.notification_count} ${unreadNotificationData?.unreadNotificationCount >= 1 && styles.active}`} onClick={toggleMenu}>
          <NotificationsIcon />
          {unreadNotificationData?.unreadNotificationCount >= 1 && <span className={styles.unread}></span>}
        </div>
     
        {notificationMenuOpen ? (
          <ul className={styles.notification_list}  ref={notificationMenuRef}>
            <li className={styles.notification_header}>Notifications {!unreadNotificationData?.unreadNotificationCount? '':'('+unreadNotificationData?.unreadNotificationCount+')'}</li>
            {notificationData?.mynotifications.length >= 1 ?
            <>
            {notificationData?.mynotifications.map((notification) => (
              <li
                key={notification.id}
                className={styles.notification_item}
                onClick={() => handleNotificationClick(notification.id)}
              >
                <h2>
                  {notification.totalEventCount <= 1
                    ? 'A new record received for '
                    : `${notification.totalEventCount} new records received for `}
                  <a onClick={() => handleNotificationClick(notification.id)} href={'/hub/' + notification.myform +'/?report=responses#reporting_section'}> {notification.context}</a>
                </h2>
                <span>
                  {notification.seen === true ? '' : <span className={styles.unread}> </span>}
                  {formatDateTime(new Date(notification.created_at))}
                </span>
              </li>
            ))}
            <li className={styles.notification_footer}>
                {!noMoreToFetch?<a onClick={handleLoadMore}>Load more</a>
                :<span>No more notifications</span>}
            </li>
            </> :
            <li className={styles.notification_empty}>
                <h2>Nothing to see here</h2>
                <p>No notifications yet!</p>
                
            </li>
            }
            
            {/* {notificationData?.mynotifications?.length >= NOTIFICATIONS_PER_PAGE && (

            )} */}
          </ul>
        ) : (
          ''
        )}
      </>
    </div>
  );
}

export default Notification;
