import React from 'react';
import styles from '../css/navigation/Navigation.module.scss';

function AvatarPicker({ onSelect }) {
  const avatars = [
    'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/avatar/1.svg',
    'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/avatar/2.svg',
    'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/avatar/3.svg',
    'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/avatar/4.svg',
    'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/avatar/5.svg',
    'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/avatar/6.svg',
    'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/avatar/7.svg',
    'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/avatar/8.svg',
  ];

  return (
    <div className={styles.avatars_wrapper}>
      <ul className={styles.avatars}>
        {avatars.map((avatar, index) => (
          <li key={index} className={styles.avataritem_wrapper}>
            <div
              className={styles.avataritem}
              onClick={() => onSelect(avatar)} // Call the onSelect function with the selected avatar
            >
              <img src={avatar} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AvatarPicker;