import React, { useState, useEffect } from 'react';
import { CREATE_GOOGLE_CALENDAR_EVENT } from '../graphql/Field'; // Import your mutation
import { useMutation } from '@apollo/client';

const AvailableTimeSlots = ({ calendarEvents }) => {
  // Define the date for which you want to find available time slots
  const targetDate = '2023-10-18';
  const startTime = new Date(`${targetDate}T00:00:00+11:00`);
  const endTime = new Date(`${targetDate}T23:59:59+11:00`);

  // Time increment (30 minutes)
  const timeIncrementMinutes = 30;
  const timeIncrementMilliseconds = timeIncrementMinutes * 60 * 1000;

  const [createEvent] = useMutation(CREATE_GOOGLE_CALENDAR_EVENT);

  // Create state to hold available time slots
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

  // Function to find available time slots
  const findAvailableTimeSlots = () => {
    const busyTimeSlots = calendarEvents?.getGoogleCalendarEvents?.map((event) => ({
      start: new Date(event.start.dateTime),
      end: new Date(event.end.dateTime),
    }));

    const bookedTimeSlots = busyTimeSlots.map((busySlot) => ({
      start: busySlot.start.getTime(),
      end: busySlot.end.getTime(),
    }));

    const slots = [];
    let currentTime = startTime;

    while (currentTime < endTime) {
      const isAvailable = !bookedTimeSlots.some((bookedSlot) => {
        return currentTime.getTime() >= bookedSlot.start && currentTime.getTime() < bookedSlot.end;
      });

      if (isAvailable) {
        slots.push({
          start: new Date(currentTime),
          end: new Date(currentTime.getTime() + timeIncrementMilliseconds),
        });
      }

      currentTime = new Date(currentTime.getTime() + timeIncrementMilliseconds);
    }

    setAvailableTimeSlots(slots);
  };

  // Function to create a Google Calendar event
  const createGoogleCalendarEvent = (slot) => {
    createEvent({
      variables: {
        input: {
          title: 'Your Event Title', // Set the event title
          start: slot.start.toISOString(),
          end: slot.end.toISOString(),
          // ...other event fields
        },
      },
    })
      .then((result) => {
        console.log('Event created:', result.data.createGoogleCalendarEvent);
        // You can update the UI or provide feedback to the user here.
      })
      .catch((error) => {
        console.error('Error creating event:', error);
        // Handle errors or show error messages to the user.
      });
  };

  // Call the function to find available time slots when the component mounts
  useEffect(() => {
    findAvailableTimeSlots();
  }, [calendarEvents]);

  return (
    <div>
      <h2>Available 30-Minute Time Slots</h2>
      <ul>
        {availableTimeSlots.map((slot, index) => (
          <li key={index}>
            {slot.start.toLocaleTimeString()} - {slot.end.toLocaleTimeString()}
            <button
              onClick={() => createGoogleCalendarEvent(slot)} // Handle event creation when clicked
            >
              Create Event
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AvailableTimeSlots;
