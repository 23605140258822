
// export const TemplateCategory = [
//     {category:''},
//     {category:'New'},
//     {category:'Featured'},
//     {category: 'Use Case'},
//     {category: "Homepage"}

// ]

export const TemplateCategory = [
    {label:'popular',name: 'popular'},
    {label:'contact',name: 'contact'},
    {label:'design',name: 'design'},
    {label:'customer success',name: 'customer success'},
    {label:'marketing',name: 'marketing'},
    {label:'growth',name: 'growth'},
    {label:'product',name: 'product'},
    {label:'webinar',name: 'webinar'},
    {label:'homepage',name: 'Homepage'},
    {label:'Diversity, equity & inclusion',name: 'Diversity, equity & inclusion'},
    {label:'Listen More Series: Be a Better Boss',name: 'Listen More Series: Be a Better Boss'},
    {label:'events', name:'events'},
    {label:'feedback', name:'feedback'},
    {label:'lead generation', name:'lead generation'},
    {label:'education', name:'education'},
    {label:'volunteers', name:'volunteers'},
    {label:'registration', name:'registration'},
    {label:'employe experience', name:'employee experience'},
    {label:'scrum', name:'scrum'}
]

export const ExtraCategory =[
    {label:'recent', name:'recent'},
    {label:'seasonal', name:'seasonal'},
    {label:'quiz1', name:'quiz1'},
    {label:'quiz2', name:'quiz2'},
    {label:'product1', name:'product2'}
]




export default TemplateCategory