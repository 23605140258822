import { gql } from '@apollo/client';

export const FIND_PREVIEW_FIELDS_FOR_FORM = gql`
  query($myform: String!){
    findPreviewFieldsForReporting(myform: $myform){
      label
      id
      type
    }
  }
`;
export const pathIDObject = gql`
  input pathIDObject {
    pathID: String,
    fieldID: String
  }
`;
export const EventInput = gql`
input EventInput {
  title: String
  start: String
  end: String
}`
export const GET_FIELDS = gql`
  query($myform: String!, $pathIDObject: [pathIDObject], $section: String!) {
    formFields(myform: $myform, pathIDObject: $pathIDObject, section: $section) {
      id
      pathID
      value
      type
      section
      relatedFieldID
      imgsize
      link
      linktoggle
      order
      required
      radiotype
      readwritepermission
      startlegend
      endlegend
      label
      framedevicestyle
      shadowstyle
      description
      category
      subfieldtype
      caption
      valuetoggle
      descriptiontoggle
      headingtoggle
      heading
      prefix
      imageOnFieldUrl
      imageOnFieldType
      imageOnFieldAuthorName
      imageOnFieldAuthorUrl
      imageOnFieldAuthorAltText
      imageOnFieldLayoutPosition
      logoFieldFilter
      logoFieldScroller
      mediaFieldType
      answernote
      answernoteheading
      autoplay
    }
  }
`;
export const CREATE_GOOGLE_CALENDAR_EVENT = gql`
  mutation CreateGoogleCalendarEvent($input: EventInput!) {
    createGoogleCalendarEvent(input: $input) {
      id
      title
      start
      end
    }
  }
`;
export const GET_GOOGLE_CALENDAR_EVENTS = gql`
  query getGoogleCalendarEvents($calendar: String, $myform:String, $startTime:String, $endTime:String) {
    getGoogleCalendarEvents(calendar:$calendar, myform:$myform, startTime:$startTime, endTime:$endTime) {
      start {
        dateTime
        timeZone
      }
      end {
        dateTime
        timeZone
      }
      htmlLink
    }
  }
`;
export const GET_GOOGLE_CALENDARS = gql`
  query getGoogleCalendars($code: String) {
    getGoogleCalendars(code: $code) {
        id
        summary
        description
    }
  }
`;


export const GET_READWRITE_FIELDS = gql`
  query($myform: String!) {
    getReadWriteField(myform: $myform) {
      id
      pathID
      value
      type
      section
      imgsize
      link
      linktoggle
      order
      required
      radiotype
      readwritepermission
      startlegend
      endlegend
      label
      framedevicestyle
      shadowstyle
      description
      category
      subfieldtype
      caption
      valuetoggle
      descriptiontoggle
      headingtoggle
      heading
      prefix
      imageOnFieldUrl
      imageOnFieldType
      imageOnFieldAuthorName
      imageOnFieldAuthorUrl
      imageOnFieldAuthorAltText
      imageOnFieldLayoutPosition
      logoFieldFilter
      logoFieldScroller
      mediaFieldType
      answernote
      answernoteheading
      autoplay
    }
  }
`;

export const ALL_PATHS = gql`
query($myfield: String!){
  allPaths(myfield: $myfield){
    id
    value
  }
}`


export const PATH_FIELDS = gql`
query($pathID: String!){
  pathFields(pathID: $pathID){
    id
  }
}`


export const CHECK_ANSWER_ON_FIELD= gql`
  query ($myfield: String!) {
    checkForAnswersOnField(myfield: $myfield)
  }
`;
export const REFRESH_TOKEN= gql`
mutation exchange($refreshToken: String!) {
    accessToken: String
    expiresIn: Int
  }
`;


export const UPDATE_FIELD = gql`
  mutation updateField(
    $id: String!
    $pathID:String
    $myform: String!
    $relatedFieldID: String
    $value: String
    $label: String
    $section: String
    $imgsize: String
    $link: String
    $type: String
    $order: Int
    $required: Boolean
    $radiotype: String
    $startlegend: String
    $endlegend: String
    $framedevicestyle: String
    $shadowstyle: String
    $description: String
    $category: String
    $subfieldtype: String
    $caption: String
    $heading: String
    $answernote: String
    $descriptiontoggle: Boolean
    $prefix: String
    $imageOnFieldUrl: String
    $imageOnFieldType: String
    $imageOnFieldAuthorName: String
    $imageOnFieldAuthorUrl: String
    $imageOnFieldAuthorAltText: String
    $imageOnFieldLayoutPosition: String
    $logoFieldFilter:String
    $logoFieldScroller:String
    $mediaFieldType:String
    $valuetoggle:Boolean
    $autoplay:Boolean

  ) {
    updateField(
      id: $id
      relatedFieldID: $relatedFieldID
      pathID: $pathID
      myform: $myform
      value: $value
      label: $label
      section: $section
      imgsize: $imgsize
      link: $link
      type: $type
      order: $order
      required: $required
      radiotype: $radiotype
      startlegend: $startlegend
      endlegend: $endlegend
      framedevicestyle: $framedevicestyle
      shadowstyle: $shadowstyle
      description: $description
      category: $category
      subfieldtype: $subfieldtype
      caption: $caption
      heading: $heading
      answernote: $answernote
      descriptiontoggle: $descriptiontoggle
      prefix: $prefix
      imageOnFieldUrl: $imageOnFieldUrl
      imageOnFieldType: $imageOnFieldType
      imageOnFieldAuthorName: $imageOnFieldAuthorName
      imageOnFieldAuthorUrl: $imageOnFieldAuthorUrl
      imageOnFieldAuthorAltText: $imageOnFieldAuthorAltText
      imageOnFieldLayoutPosition: $imageOnFieldLayoutPosition
      logoFieldFilter: $logoFieldFilter
      logoFieldScroller: $logoFieldScroller
      mediaFieldType: $mediaFieldType
      valuetoggle: $valuetoggle
      autoplay: $autoplay

    ) {
      id
      label
      value
      order
      type
      radiotype
    }
  }
`;

export const REORDER_FIELDS = gql`
  mutation ($fieldsOrder: [OrderField]) {
    reorderFields(fieldsOrder: $fieldsOrder)
  }
`;

export const CREATE_NEW_FIELD = gql`
  mutation createField(
    $myform: String!
    $required: Boolean
    $pathID: String
    $section: String!
    $relatedFieldID: String
    $order: Int
    $type: String!
    $category: String
    $radiotype: String
    $subfieldtype: String
    $readwritepermission: String
    $descriptiontoggle: Boolean
    $headingtoggle: Boolean
    $linktoggle: Boolean
    $valuetoggle: Boolean
    $imgsize: String
    $framedevicestyle: String
    $oldfield: String
    $answernote: String
    $answernoteheading: String
    $startlegend: String
    $endlegend: String
    $value: String
    $label: String
    $description: String
    $prefix: String
    $heading: String
    $imageOnFieldUrl: String
    $imageOnFieldType: String
    $imageOnFieldAuthorName: String
    $imageOnFieldAuthorUrl: String
    $imageOnFieldAuthorAltText: String
    $imageOnFieldLayoutPosition: String
    $logoFieldFilter:String
    $logoFieldScroller:String
    $mediaFieldType:String
    $shadowstyle: String
  ) {
      createField(
      myform: $myform
      required: $required
      pathID: $pathID
      relatedFieldID: $relatedFieldID
      section: $section
      order: $order
      type: $type
      category: $category
      radiotype: $radiotype
      subfieldtype: $subfieldtype
      readwritepermission: $readwritepermission
      descriptiontoggle: $descriptiontoggle
      headingtoggle: $headingtoggle
      linktoggle: $linktoggle
      valuetoggle: $valuetoggle
      imgsize: $imgsize
      framedevicestyle: $framedevicestyle
      oldfield: $oldfield
      answernote: $answernote
      answernoteheading: $answernoteheading
      startlegend: $startlegend
      endlegend: $endlegend
      value: $value
      label: $label
      description: $description
      prefix: $prefix
      imageOnFieldUrl: $imageOnFieldUrl
      imageOnFieldType: $imageOnFieldType
      imageOnFieldAuthorName: $imageOnFieldAuthorName
      imageOnFieldAuthorUrl: $imageOnFieldAuthorUrl
      imageOnFieldAuthorAltText: $imageOnFieldAuthorAltText
      imageOnFieldLayoutPosition: $ imageOnFieldLayoutPosition
      logoFieldFilter: $logoFieldFilter
      logoFieldScroller: $logoFieldScroller
      mediaFieldType: $mediaFieldType
      heading: $heading
      shadowstyle: $shadowstyle

    ) {
    id
    order
    label
    type
    }
  }
  
`;

export const CREATE_SMART_FIELD = gql`
  mutation createFieldSmartField(
    $myform: String!
    $required: Boolean
    $pathID: String
    $section: String!
    $order: Int
    $relatedFieldID: String
    $type: String!
    $category: String
    $radiotype: String
    $subfieldtype: String
    $readwritepermission: String
    $descriptiontoggle: Boolean
    $headingtoggle: Boolean
    $linktoggle: Boolean
    $valuetoggle: Boolean
    $imgsize: String
    $framedevicestyle: String
    $answernote: String
    $answernoteheading: String
    $startlegend: String
    $endlegend: String
    $value: String
    $label: String
    $description: String
    $prefix: String
    $heading: String
    $imageOnFieldUrl: String
    $imageOnFieldType: String
    $imageOnFieldAuthorName: String
    $imageOnFieldAuthorUrl: String
    $imageOnFieldAuthorAltText: String
    $imageOnFieldLayoutPosition: String
    $logoFieldFilter:String
    $logoFieldScroller:String
    $mediaFieldType:String
    $shadowstyle: String
  ) {
    createFieldSmartField(
      myform: $myform
      required: $required
      relatedFieldID: $relatedFieldID
      pathID: $pathID
      section: $section
      order: $order
      type: $type
      category: $category
      radiotype: $radiotype
      subfieldtype: $subfieldtype
      readwritepermission: $readwritepermission
      descriptiontoggle: $descriptiontoggle
      headingtoggle: $headingtoggle
      linktoggle: $linktoggle
      valuetoggle: $valuetoggle
      imgsize: $imgsize
      framedevicestyle: $framedevicestyle
      answernote: $answernote
      answernoteheading: $answernoteheading
      startlegend: $startlegend
      endlegend: $endlegend
      value: $value
      label: $label
      description: $description
      prefix: $prefix
      imageOnFieldUrl: $imageOnFieldUrl
      imageOnFieldType: $imageOnFieldType
      imageOnFieldAuthorName: $imageOnFieldAuthorName
      imageOnFieldAuthorUrl: $imageOnFieldAuthorUrl
      imageOnFieldAuthorAltText: $imageOnFieldAuthorAltText
      imageOnFieldLayoutPosition: $ imageOnFieldLayoutPosition
      logoFieldFilter: $logoFieldFilter
      logoFieldScroller: $logoFieldScroller
      mediaFieldType: $mediaFieldType
      heading: $heading
      shadowstyle: $shadowstyle

    ){
      id
      order
      label
      type

      }
  }
  
`;


export const DUPLICATE_FIELD = gql`
  mutation duplicateField(
    $myform: String!
    $required: Boolean
    $section: String!
    $relatedFieldID: String
    $pathID: String
    $order: Int
    $type: String!
    $category: String
    $radiotype: String
    $subfieldtype: String
    $readwritepermission: String
    $descriptiontoggle: Boolean
    $headingtoggle: Boolean
    $linktoggle: Boolean
    $valuetoggle: Boolean
    $imgsize: String
    $framedevicestyle: String
    $oldfield: String
    $answernote: String
    $answernoteheading: String
    $startlegend: String
    $endlegend: String
    $value: String
    $label: String
    $description: String
    $prefix: String
    $imageOnFieldUrl: String
    $imageOnFieldType: String
    $imageOnFieldAuthorName: String
    $imageOnFieldAuthorUrl: String
    $imageOnFieldAuthorAltText: String
    $imageOnFieldLayoutPosition: String
    $logoFieldFilter:String
    $logoFieldScroller:String
    $mediaFieldType:String
    $heading: String
    $shadowstyle: String


  ) {
    duplicateField(
      myform: $myform
      required: $required
      section: $section
      pathID: $pathID
      order: $order
      relatedFieldID: $relatedFieldID
      type: $type
      category: $category
      radiotype: $radiotype
      subfieldtype: $subfieldtype
      readwritepermission: $readwritepermission
      descriptiontoggle: $descriptiontoggle
      headingtoggle: $headingtoggle
      linktoggle: $linktoggle
      valuetoggle: $valuetoggle
      imgsize: $imgsize
      framedevicestyle: $framedevicestyle
      oldfield: $oldfield
      answernote: $answernote
      answernoteheading: $answernoteheading
      startlegend: $startlegend
      endlegend: $endlegend
      value: $value
      label: $label
      description: $description
      prefix: $prefix
      imageOnFieldUrl: $imageOnFieldUrl
      imageOnFieldType: $imageOnFieldType
      imageOnFieldAuthorName: $imageOnFieldAuthorName
      imageOnFieldAuthorUrl: $imageOnFieldAuthorUrl
      imageOnFieldAuthorAltText: $imageOnFieldAuthorAltText
      imageOnFieldLayoutPosition: $ imageOnFieldLayoutPosition
      logoFieldFilter: $logoFieldFilter
      logoFieldScroller: $logoFieldScroller
      mediaFieldType: $mediaFieldType
      heading: $heading
      shadowstyle: $shadowstyle


    ) {
    id
    order
    label
    type
    }
  }
  
`;


export const REMOVE_FIELD= gql`
  mutation ($id: String!, $section: String!) {
    deleteField(id: $id, section: $section)
  }
`;


export const REMOVE_SMART_FIELD= gql`
  mutation ($myfield: String!) {
    deleteFieldsInAllPaths(myfield: $myfield)
  }
`;

export const REMOVE_SMART_FIELDS_IN_A_PATH= gql`
  mutation ($pathID: String!, $relatedFieldID: String!) {
    deleteFieldsInAPath(pathID: $pathID, relatedFieldID: $relatedFieldID)
  }
`;




