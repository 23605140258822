import React, { useState, useEffect } from 'react';
import styles from '../css/utils/404.module.scss'

const EmptyContent = () => {

    return (
      <div>
        No result found
      </div>
    );
};
export default EmptyContent;

