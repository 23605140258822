import React from 'react'
export const IconPoodle = (props) => {
    <svg width="107" height="85" viewBox="0 0 107 85" fill="none" xmlns="http://www.w3.org/2000/svg">
   </svg>
    }
export const IconCat = (props) => {
    return (
        <svg width="267" height="327" viewBox="0 0 267 327" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse opacity="0.2" cx="107.466" cy="309.417" rx="103" ry="17.4052" fill="#CCB6A3"/>
            <path d="M207.177 95.0857C206.725 92.4357 204.211 90.6599 201.566 91.107L189.518 93.1634C186.871 93.6154 185.09 96.1263 185.542 98.7739C185.944 101.146 188.003 102.82 190.328 102.82C190.601 102.82 190.874 102.797 191.152 102.75L203.2 100.696C205.848 100.244 207.629 97.7332 207.177 95.0857Z" fill="#BFB4AC"/>
            <path d="M201.489 117.808L189.891 111.507C187.529 110.226 184.576 111.1 183.294 113.459C182.013 115.819 182.887 118.772 185.247 120.056L196.848 126.354C197.585 126.757 198.38 126.945 199.165 126.945C200.891 126.945 202.56 126.027 203.442 124.402C204.726 122.043 203.851 119.09 201.489 117.808Z" fill="#BFB4AC"/>
            <path d="M24.2165 102.75C24.4921 102.797 24.7678 102.82 25.0385 102.82C27.3657 102.82 29.4246 101.146 29.8269 98.774C30.279 96.1265 28.4982 93.6155 25.8507 93.1635L13.8052 91.1071C11.1576 90.6576 8.64421 92.4358 8.19219 95.0858C7.74017 97.7334 9.52092 100.244 12.1685 100.696L24.2165 102.75Z" fill="#BFB4AC"/>
            <path d="M25.4783 111.507L13.8799 117.808C11.518 119.09 10.6462 122.043 11.9278 124.405C12.807 126.027 14.4784 126.945 16.2045 126.945C16.9894 126.945 17.7841 126.757 18.5217 126.357L30.1227 120.056C32.4821 118.772 33.3564 115.821 32.0748 113.459C30.7908 111.1 27.8403 110.226 25.4783 111.507Z" fill="#BFB4AC"/>
            <path d="M217.018 105.281C217.868 122.083 239.415 148.176 236.909 181.463C234.669 211.244 220.632 247.413 191.896 265.71C192.011 267.354 192.073 268.879 192.073 270.263C192.073 288.385 182.546 299.02 163.193 304.715C163.211 304.71 265.978 282.333 266.003 172.639C266.027 60.7449 215.898 83.1993 217.018 105.281Z" fill="#C6BBBE"/>
            <path d="M131.977 32.7423C131.977 32.7423 145.58 -2.11523 157.235 0.296359C169.055 2.74023 166.76 42.0633 166.76 42.0633C166.76 42.0633 191.174 79.536 190.384 101.091C189.594 122.644 175.738 137.022 162.026 139.644C162.026 139.644 192.073 239.391 192.073 270.263C192.073 301.131 164.445 310.281 107.685 310.281C50.9217 310.281 23.2939 301.131 23.2939 270.263C23.2939 239.391 53.3407 139.644 53.3407 139.644C39.6312 137.022 25.7726 122.644 24.9828 101.091C24.193 79.536 48.6069 42.0633 48.6069 42.0633C48.6069 42.0633 46.3121 2.74023 58.1316 0.296359C69.7847 -2.11523 83.3899 32.7423 83.3899 32.7423H131.977Z" fill="#FEFBF8"/>
            <path d="M58.1314 0.296359C46.3119 2.74023 48.6068 42.0633 48.6068 42.0633C48.6068 42.0633 24.1928 79.536 24.9826 101.091C25.3999 112.489 29.473 121.877 35.2449 128.585C103.887 113.234 97.8444 32.7423 97.8444 32.7423H83.3897C83.3897 32.7423 69.7845 -2.11523 58.1314 0.296359Z" fill="#C6BBBE"/>
            <path d="M157.235 0.296359C169.053 2.74023 166.76 42.0633 166.76 42.0633C166.76 42.0633 191.174 79.536 190.384 101.091C189.967 112.489 185.894 121.877 180.122 128.585C111.48 113.234 117.52 32.7423 117.52 32.7423H131.977C131.977 32.7423 145.58 -2.11523 157.235 0.296359Z" fill="#C6BBBE"/>
            <path d="M66.8763 70.9429C61.3403 70.9429 57.0039 76.3273 57.0039 83.202C57.0039 90.0766 61.3403 95.4611 66.8763 95.4611C72.4098 95.4611 76.7461 90.0766 76.7461 83.202C76.7461 76.3273 72.4098 70.9429 66.8763 70.9429Z" fill="#68616B"/>
            <path d="M148.491 70.9429C142.957 70.9429 138.621 76.3273 138.621 83.202C138.621 90.0766 142.957 95.4611 148.491 95.4611C154.027 95.4611 158.363 90.0766 158.363 83.202C158.363 76.3273 154.027 70.9429 148.491 70.9429Z" fill="#68616B"/>
            <path d="M122.624 94.326C122.624 92.6322 121.97 88.5367 115.93 87.1061C113.946 86.6367 111.48 86.4355 107.685 86.4355C103.885 86.4355 101.421 86.6367 99.439 87.1061C93.3964 88.5367 92.7432 92.6322 92.7432 94.326C92.7432 98.9505 97.1416 103.25 102.822 104.879V115.782C102.822 118.467 104.997 120.645 107.685 120.645C110.369 120.645 112.547 118.467 112.547 115.782V104.879C118.225 103.25 122.624 98.9505 122.624 94.326Z" fill="#68616B"/>
            <path d="M49.0859 159.613C49.0859 159.613 3.77659 215.776 17.277 228.242C30.7773 240.708 75.1324 193.316 75.1324 193.316" fill="#FEFBF8"/>
            <path opacity="0.1" d="M147.5 185.5C147.5 185.5 118.5 235 94.3791 217.461C80.3349 205.613 118.5 165 118.5 165" fill="#766354"/>
            <path d="M85.0508 177.68C88.2872 172.531 90.1996 166.573 90.1996 160.1C90.1996 141.785 75.4151 127 57.0998 127C38.7846 127 24 141.785 24 160.1C24 178.415 38.7846 193.2 57.0263 193.2C63.4991 193.2 69.5306 191.287 74.6059 188.051L97.5552 211L108 200.555L85.0508 177.68ZM57.0998 178.489C46.9492 178.489 38.711 170.25 38.711 160.1C38.711 149.949 46.9492 141.711 57.0998 141.711C67.2504 141.711 75.4886 149.949 75.4886 160.1C75.4886 170.25 67.2504 178.489 57.0998 178.489Z" fill="#68616B"/>
            <path d="M165.702 171.493C165.702 171.493 113.321 221.126 99.8214 208.661C86.3219 196.195 130.034 148.21 130.034 148.21" fill="#FEFBF8"/>
        </svg>

    )
}
export const IconRagdoll = (props) =>{
    <svg width="74" height="120" viewBox="0 0 74 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M72.8847 117.508C72.8847 117.508 75.7566 118.773 67.9199 119.163C60.0832 119.552 18.612 119.747 14.3286 119.163C10.0452 118.579 1.77047 118.92 12.771 117.508C23.7716 116.096 35.1129 116.924 46.2108 116.096C57.3087 115.269 71.2298 116.048 72.8847 117.508Z" fill="#F0EBE7"/>
        <path d="M15.4008 93.1214C15.4008 93.1214 11.02 91.1257 10.2899 80.904C9.55978 70.6822 14.0379 62.4561 14.476 49.5086C14.914 36.561 7.61277 29.2597 1.96647 33.4458C-3.67984 37.6318 4.78962 45.8093 3.23202 62.6995C1.67442 79.5411 1.23635 96.5773 14.622 100.958C14.5733 100.958 16.861 97.7942 15.4008 93.1214Z" fill="#413B39"/>
        <path d="M50.739 117.557C50.739 117.557 67.6292 117.557 68.6514 117.557C69.6736 117.557 70.9391 109.866 61.9829 109.72C53.0267 109.574 47.6238 109.72 47.6238 109.72L50.739 117.557Z" fill="#AFA7A0"/>
        <path d="M66.2671 117.556C66.2671 117.556 66.7538 115.707 65.3909 114.393" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M63.0527 117.558C63.0527 117.558 63.0527 116.049 62.3713 115.367" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M56.7262 7.98917C56.7262 7.98917 62.6645 -0.772347 66.5099 0.0551289C70.3552 0.882605 70.8906 18.941 67.532 20.0606C64.2221 21.2288 56.7262 7.98917 56.7262 7.98917Z" fill="#413B39"/>
        <path d="M39.3027 8.62118C39.3027 8.62118 31.466 2.97488 26.6959 3.31561C21.8771 3.60766 26.7932 17.8208 30.3465 21.4714C33.8998 25.122 39.3027 8.62118 39.3027 8.62118Z" fill="#413B39"/>
        <path d="M61.2036 38.6543C61.2036 38.6543 67.4827 34.809 68.5048 26.7776C69.527 18.7462 65.8764 6.04199 48.3534 6.04199C30.8303 6.042 27.5204 20.6932 28.786 28.2378C29.9542 35.3931 33.5561 37.6321 33.5561 37.6321C33.5561 37.6321 29.6621 46.4423 27.7151 51.6019C26.2062 55.5932 25.4761 59.6819 26.0602 64.4521C26.0602 64.4521 20.2192 73.1162 16.5686 80.3201C13.064 87.232 10.1434 104.56 13.3073 116.291C13.502 117.07 14.2322 117.605 15.011 117.605L61.1062 117.605C65.1949 117.605 69.9651 110.888 66.2171 84.2628C66.2171 84.2628 68.1641 79.6874 66.4605 70.1957C64.7569 60.7041 61.2036 38.6543 61.2036 38.6543Z" fill="#CDC8C4"/>
        <path d="M20.6589 117.557C20.6589 117.557 23.6281 117.557 24.5042 117.557L45.97 117.557C48.4524 111.083 40.9078 108.649 37.0624 108.649C34.0932 108.649 27.2787 108.649 27.2787 108.649C27.2787 108.649 23.6281 108.795 22.0705 111.327C20.2208 114.296 20.5616 117.411 20.6589 117.557Z" fill="#AFA7A0"/>
        <path d="M55.9958 85.8684C57.7968 87.1339 59.4517 87.8641 60.6199 88.3021C61.8855 88.7402 63.2971 88.4482 64.2219 87.426C65.4874 86.0631 66.2662 84.2134 66.2662 84.2134C66.2662 84.2134 67.5318 81.2442 67.1424 75.1599C67.1424 75.1599 66.9964 71.1685 61.4474 69.9516C57.31 69.0268 55.071 71.0225 55.071 71.0225C55.1683 71.8013 55.3143 72.5801 55.4604 73.4562C55.9471 76.3767 55.363 81.439 55.071 83.7754C54.9736 84.5542 55.3143 85.3816 55.9958 85.8684Z" fill="#AFA7A0"/>
        <path d="M66.2676 84.2632C66.2676 84.2632 65.4888 86.1128 64.2232 87.4757C63.2984 88.4492 61.8868 88.7899 60.6213 88.3032C59.4531 87.8651 57.7981 87.135 55.9971 85.8694C55.3157 85.3827 54.975 84.6039 55.121 83.7764C55.413 81.44 55.9485 76.3778 55.5104 73.4573C54.8776 69.5633 54.4882 66.3994 54.4882 66.3994" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M60.376 88.1576C60.376 88.1576 62.323 86.6487 62.5664 83.9229" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M57.3078 86.6972C57.3078 86.6972 58.768 85.3343 58.9141 82.9492" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M33.1175 73.7495C36.5248 75.8425 38.2771 77.0594 38.0824 79.3958C37.839 82.1216 39.932 82.365 40.8569 82.6083C42.2198 82.9491 44.7996 83.1924 46.7952 82.9491C47.6714 82.8517 49.375 81.2454 49.375 77.6435C49.375 74.3336 46.6979 68.6873 41.5383 65.8155C41.5383 65.8155 37.3036 64.988 34.4804 68.2492C32.6308 70.4396 33.1175 73.7495 33.1175 73.7495Z" fill="#AFA7A0"/>
        <path d="M26.0598 64.4531C26.0598 64.4531 26.6926 69.71 31.3167 72.6305C35.9408 75.551 38.3746 76.7192 38.1312 79.445C37.8878 82.1708 39.9809 82.4142 40.9057 82.6576C42.2686 82.9983 44.8484 83.2417 46.8441 82.9983C47.7202 82.901 49.4238 81.2947 49.4238 77.6927C49.4238 74.3828 46.7467 68.7365 41.5871 65.8647" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M43.8268 82.9986C43.8268 82.9986 46.2118 82.4631 46.0658 78.8125" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M40.8585 82.6082C40.8585 82.6082 42.4161 81.5373 42.2214 78.8115" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M21.1938 81.4879C21.1938 81.4879 25.5745 76.085 32.4377 79.979C38.7655 83.581 40.3231 100.179 34.9202 108.6" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M45.9192 117.558C48.4016 111.084 40.857 108.65 37.0116 108.65C34.0425 108.65 27.2279 108.65 27.2279 108.65" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M42.465 117.556C42.465 117.556 43.6819 115.658 42.465 114.393" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M39.6883 117.557C39.6883 117.557 39.9803 115.416 39.1528 114.686" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
        <path d="M42.9133 19.0437C43.3956 17.5149 42.8822 15.9902 41.7667 15.6383C40.6511 15.2863 39.3557 16.2403 38.8734 17.769C38.391 19.2978 38.9044 20.8224 40.02 21.1744C41.1355 21.5264 42.4309 20.5724 42.9133 19.0437Z" fill="#413B39"/>
        <path d="M58.0356 17.6709C57.4082 16.1958 56.0269 15.3711 54.9504 15.8289C53.874 16.2868 53.5099 17.8538 54.1374 19.329C54.7648 20.8041 56.1461 21.6288 57.2226 21.171C58.299 20.7131 58.6631 19.1461 58.0356 17.6709Z" fill="#413B39"/>
        <path d="M48.7986 21.4941C48.7986 21.4941 48.8802 23.2485 48.8394 23.3709C48.7986 23.4933 46.8811 24.6356 44.3516 24.3908" stroke="#413B39" stroke-width="1.3" stroke-miterlimit="10"/>
        <path d="M48.8379 23.4121C48.8379 23.4121 50.3066 24.6361 52.9993 24.7585" stroke="#413B39" stroke-width="1.3" stroke-miterlimit="10"/>
        <path d="M46.9621 18.9242C46.2685 18.965 45.8605 19.7402 46.3093 20.3114C46.7581 20.8825 47.4517 21.4129 48.5532 21.4945C49.7364 21.5761 50.5523 21.0049 51.0419 20.3521C51.4499 19.8218 51.0827 19.0466 50.4299 19.0058C49.4508 18.965 48.1452 18.8834 46.9621 18.9242Z" fill="#413B39"/>
        <path d="M33 38C34.1667 38.8333 37.9 40.6 43.5 41" stroke="#413B39" stroke-width="1.5" strokeLinecap="round"/>
    </svg>

}
export const IconDog = (props) => {
    return (
<svg width="367" height="247" viewBox="0 0 367 247" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M52.5452 54.6325C83.301 44.6641 120.775 55.681 144.598 81.9279C182.92 124.193 225.227 185.047 225.227 185.047L249.105 178.941C254.534 177.558 260.246 177.764 265.562 179.534C270.878 181.304 275.574 184.565 279.093 188.928L290.965 203.672C291.686 204.569 292.599 205.293 293.636 205.789C294.673 206.286 295.809 206.543 296.959 206.542H324.848C325.811 206.541 326.764 206.735 327.65 207.113C328.537 207.49 329.338 208.043 330.005 208.737C330.672 209.432 331.193 210.255 331.535 211.156C331.876 212.057 332.033 213.018 331.994 213.981C330.744 239.231 308.947 246 264.64 246H164.742C159.061 245.99 153.557 244.021 149.156 240.426C144.755 236.83 141.726 231.827 140.578 226.258L138.372 215.528C107.91 220.017 86.4085 193.792 86.4085 193.792C73.8666 209.037 53.267 224.496 53.267 224.496C57.2987 224.508 61.1613 226.12 64.0079 228.977C66.8546 231.835 68.4531 235.706 68.4531 239.741V246H27.2363L5.05567 145.5C0.781313 126.691 3.21796 106.978 11.943 89.7783C15.7579 82.3904 20.3978 76.1271 25.6571 70.9302C24.6814 58.6248 20.9794 34.6675 7.03919 16.3341C-12.5593 -9.44085 14.1465 1.26624 22.1423 8.357C23.4002 9.47248 24.6288 10.53 25.831 11.5649C36.0433 20.3554 44.3534 27.5085 52.5452 54.6325Z" fill="#423B39"/>
<path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M289.552 245C312.017 242.846 325.356 236.882 330.088 224H348.664C353.526 224 358.188 225.862 361.626 229.176C365.065 232.49 366.998 236.986 367 241.674V245H289.552Z" fill="#423B39"/>
<path fillRule="evenodd" clipRule="evenodd" d="M236.991 222C239.613 221.999 242.21 222.505 244.635 223.49C247.06 224.475 249.266 225.921 251.126 227.746C252.986 229.572 254.463 231.741 255.472 234.131C256.48 236.521 257 239.085 257 241.674V245H253V241.674C253 239.62 252.588 237.586 251.786 235.686C250.985 233.787 249.809 232.058 248.324 230.601C246.839 229.144 245.074 227.986 243.129 227.196C241.184 226.406 239.099 225.999 236.992 226L225 226V222H236.991C236.991 222 236.991 222 236.991 222Z" fill="#423B39"/>
<path fillRule="evenodd" clipRule="evenodd" d="M93.9699 87.7015C94.6871 86.8614 95.9495 86.7617 96.7895 87.4789L95.491 89C96.7895 87.4789 96.7903 87.4795 96.7911 87.4802L96.793 87.4818L96.798 87.4862L96.813 87.4991L96.8617 87.5416C96.9025 87.5774 96.9597 87.6282 97.0322 87.6937C97.1772 87.8249 97.3836 88.0155 97.6431 88.2652C98.1621 88.7644 98.8939 89.5003 99.7717 90.4696C101.527 92.4075 103.87 95.283 106.263 99.0702C111.051 106.647 116.048 117.888 116.916 132.556L114.919 132.674L116.916 132.556C117.737 146.439 112.419 160.317 106.223 171.527C100.009 182.772 92.7849 191.557 89.5072 195.315C88.7812 196.147 87.5178 196.233 86.6854 195.507C85.853 194.781 85.7668 193.518 86.4928 192.685C89.6729 189.04 96.6976 180.494 102.723 169.592C108.767 158.656 113.679 145.573 112.923 132.793C112.103 118.931 107.386 108.335 102.881 101.207C100.628 97.6417 98.4309 94.948 96.8068 93.1547C95.9951 92.2584 95.3278 91.5882 94.87 91.1478C94.6411 90.9276 94.4648 90.765 94.3491 90.6604C94.2912 90.6081 94.2486 90.5703 94.2221 90.547L94.1947 90.5231L94.1924 90.5211C94.1921 90.5208 94.1917 90.5205 94.1913 90.5202M93.9699 87.7015C93.253 88.5412 93.3523 89.8028 94.1913 90.5202Z" fill="#423B39"/>
<path fillRule="evenodd" clipRule="evenodd" d="M132.875 175.004C133.977 174.935 134.927 175.772 134.996 176.875L133 177L131.004 177.125C130.935 176.023 131.772 175.073 132.875 175.004ZM133 177C131.004 177.125 131.004 177.125 131.004 177.125L131.008 177.189L131.02 177.372C131.03 177.533 131.046 177.77 131.067 178.077C131.109 178.691 131.171 179.584 131.255 180.706C131.422 182.947 131.673 186.101 132.007 189.751C132.675 197.04 133.68 206.349 135.028 214.333C135.212 215.422 136.244 216.156 137.333 215.972C138.422 215.788 139.156 214.756 138.972 213.667C137.65 205.833 136.655 196.642 135.99 189.386C135.658 185.763 135.41 182.632 135.244 180.408C135.161 179.296 135.099 178.411 135.058 177.805C135.037 177.502 135.022 177.269 135.011 177.111L135 176.933L134.996 176.875L133 177Z" fill="#423B39"/>
<path fillRule="evenodd" clipRule="evenodd" d="M47 234C48.1046 234 49 234.895 49 236V245C49 246.105 48.1046 247 47 247C45.8954 247 45 246.105 45 245V236C45 234.895 45.8954 234 47 234Z" fill="#423B39"/>
<path fillRule="evenodd" clipRule="evenodd" d="M57 234C58.1046 234 59 234.895 59 236V245C59 246.105 58.1046 247 57 247C55.8954 247 55 246.105 55 245V236C55 234.895 55.8954 234 57 234Z" fill="#423B39"/>
<path fillRule="evenodd" clipRule="evenodd" d="M235 234C236.105 234 237 234.895 237 236V245C237 246.105 236.105 247 235 247C233.895 247 233 246.105 233 245V236C233 234.895 233.895 234 235 234Z" fill="#423B39"/>
<path fillRule="evenodd" clipRule="evenodd" d="M245 234C246.105 234 247 234.895 247 236V245C247 246.105 246.105 247 245 247C243.895 247 243 246.105 243 245V236C243 234.895 243.895 234 245 234Z" fill="#423B39"/>
<path d="M272 212C274.209 212 276 210.209 276 208C276 205.791 274.209 204 272 204C269.791 204 268 205.791 268 208C268 210.209 269.791 212 272 212Z" fill="#423B39"/>
<path d="M250 178.12V178L225 185L249.686 178.17L250 178.12Z" fill="#423B39"/>
<path d="M190.224 224.111C183.178 227.638 174.047 236.042 174.874 246.5H214L221.397 232.276C222.741 230.195 224.598 228.481 226.798 227.292C228.997 226.104 231.467 225.48 233.978 225.478H236.592L250 179.203L249.802 179L225.782 185.022L202.786 213.927C199.401 218.175 195.114 221.651 190.224 224.111Z" fill="#423B39"/>
<path d="M133.286 103.053C133.286 103.053 122.475 140.499 157.39 154.639C175.093 161.785 189.796 156.363 200 149.28C184.582 128.734 164.183 102.579 144.811 81.212C139.954 75.8643 134.496 71.0955 128.545 67C123.26 74.1998 117.475 92.0117 133.286 103.053Z" fill="#423B39"/>
<path d="M44 131C52.2842 131 59 124.284 59 116C59 107.716 52.2842 101 44 101C35.7157 101 29 107.716 29 116C29 124.284 35.7157 131 44 131Z" fill="#423B39"/>
<path d="M325.246 206H320.185C319.906 207.455 319.943 208.955 320.293 210.394C320.643 211.832 321.297 213.173 322.209 214.32C323.121 215.467 324.268 216.391 325.567 217.028C326.867 217.664 328.287 217.996 329.725 218C330.32 218 330.913 217.942 331.497 217.826C331.754 216.308 331.921 214.774 331.995 213.235C332.031 212.298 331.883 211.363 331.56 210.487C331.238 209.611 330.746 208.811 330.116 208.135C329.486 207.459 328.729 206.922 327.893 206.555C327.056 206.188 326.156 205.999 325.246 206Z" fill="#423B39"/>
<path fillRule="evenodd" clipRule="evenodd" d="M346 234C347.105 234 348 234.895 348 236V244C348 245.105 347.105 246 346 246C344.895 246 344 245.105 344 244V236C344 234.895 344.895 234 346 234Z" fill="#423B39"/>
<path fillRule="evenodd" clipRule="evenodd" d="M356 234C357.105 234 358 234.895 358 236V244C358 245.105 357.105 246 356 246C354.895 246 354 245.105 354 244V236C354 234.895 354.895 234 356 234Z" fill="#423B39"/>
<path fillRule="evenodd" clipRule="evenodd" d="M332.731 222.139C333.76 222.543 334.265 223.703 333.861 224.731C328.621 238.067 314.304 243.861 292.192 245.991C291.092 246.097 290.115 245.291 290.009 244.192C289.903 243.092 290.709 242.115 291.808 242.009C313.826 239.888 325.822 234.253 330.139 223.269C330.543 222.24 331.703 221.735 332.731 222.139Z" fill="#423B39"/>
</svg>

    )
}