import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

import styles from '../css/navigation/Navigation.module.scss';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Fade } from "react-awesome-reveal";
import UserNav from './UserNav';
import CreateForm from './CreateForm';

function Navigation() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [zIndexOverwrite, setZIndexOverwrite] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const location = useLocation();
  const currentlocation = location.pathname;
  
  const navigationRef = useRef(null)


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    {console.log('toggleMenu1 '+ menuOpen)}
  };

  const handleZIndexOverwrite = () => {
    if (!userMenuOpen && !notificationMenuOpen) {
      setZIndexOverwrite(false);
    } else {
      setZIndexOverwrite(true);

    }
  };
  
  const toggleUserMenu = () => {
    setUserMenuOpen(true);
    // console.log('there')
    setNotificationMenuOpen(false);

    handleZIndexOverwrite(); // Call toggleMenu function
  };
  
  const toggleNotificationMenu = () => {
    setNotificationMenuOpen(true);
    setUserMenuOpen(false);

    handleZIndexOverwrite(); // Call toggleMenu function
  
  };
  
  useEffect(() => {
    isMobileOnly && document.addEventListener('mousedown', handleOutsideContainer);
    return () => {
      isMobileOnly && document.removeEventListener('mousedown', handleOutsideContainer);
    };
  }, []);

  const handleOutsideContainer = (event) => {
    if (navigationRef.current && !navigationRef.current.contains(event.target)) {
      // setMenuOpen(false);
      setUserMenuOpen(false);
      setNotificationMenuOpen(false);
    }
  };

  return (
    <div className={styles.navigation_container} style={userMenuOpen || notificationMenuOpen ? { zIndex: "2" } : {}} id="navigation_container">
      <div className={styles.navigation} ref={navigationRef}>
        <div
          className={`${styles.hamburger} ${menuOpen ? styles.active : ''}`}
          onClick={toggleMenu}
        >
          {menuOpen ? <CloseIcon /> : <MenuIcon />}
        </div>

        <div className={styles.navigation__logo}>
          <a href="/hub"><img src="/logo.svg" alt="Logo" /></a>
        </div>

        <div className={styles.menu}>
          <div className={`${styles.menu__item} ${currentlocation === '/'? styles.menu__item__active:''}`}>
            <a href="/">Dashboard</a>
          </div>
          <div className={`${styles.menu__item} ${currentlocation === '/templates'? styles.menu__item__active:''}`}>
            <a href="/templates">Templates</a>
          </div>
        </div>
        {/* <div className={styles.menu__createform}><CreateForm></CreateForm></div> */}
        <UserNav 
        onToggleNotificationMenu={() => toggleNotificationMenu(!notificationMenuOpen)} 
        onToggleUserMenu={() => toggleUserMenu(!userMenuOpen)}
        setMenuOpen={setMenuOpen} />
      </div>
      {menuOpen && (
        <Fade up duration={300} distance="20px">
          <div className={styles.menu__mobi}>
            <div className={styles.menu__item}>
              <a href="/">Dashboard</a>
            </div>
            <div className={styles.menu__item}>
              <a href="/templates">Templates</a>
            </div>
            <div className={styles.menu__createform}><CreateForm></CreateForm></div>
          </div>
        </Fade>
      )}
    </div>
  );
}

export default Navigation;
