import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import poweredbystyle from '../css/PoweredByPepperform.module.scss'

const PoweredByPepperForm = ({ props, optionrefetch }) => {

    return (
      <div className={`${poweredbystyle.container}`}>
        <Link to="https://pepperform.net/" target="_blank" rel="noopener noreferrer"> 
        <svg width="18" height="16" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.7461 5.19141C11.1602 5.19141 10.5938 5.2793 10.0625 5.43945L9.81641 5.35352V3.77148C9.81641 3.18555 10.3047 2.70703 10.9062 2.70703H11.6328C12.2344 2.70703 12.1406 2.17578 12.1406 1.58789C12.1406 1.25977 12.168 0.984375 12.0898 0.804688C12.0273 0.662109 11.8984 0.578125 11.6328 0.578125H10.9062C9.10156 0.578125 7.63281 2.01172 7.63281 3.77148V5.35352L7.39062 5.43945C7.04297 5.33594 6.6875 5.26172 6.31641 5.22461C6.11328 5.20312 5.91016 5.19141 5.70312 5.19141C2.55469 5.19141 0 6.91602 0 9.99023C0 10.5059 0.0703125 11.0039 0.207031 11.4785L0.449219 12.375C0.652344 13.1289 0.9375 14.1855 1.26562 15.3203C1.67188 16.7305 2.14062 18.2637 2.60156 19.4922C3.01562 19.0547 3.50781 18.6367 4.09766 18.2461C5.86719 17.0762 8.42969 16.2012 12.1406 15.625C14.8633 15.2031 16.4492 13.3613 17.1328 11.4785C17.3359 10.9121 17.3984 10.4082 17.4492 9.91992C17.4102 6.88867 14.8711 5.19141 11.7461 5.19141ZM6.38281 23.2305C5.625 23.2305 4.92578 22.9863 4.35547 22.5391C4.67578 21.9082 5.14062 21.3125 5.86719 20.748C6.57812 20.1953 7.5625 19.6562 8.9375 19.1738C9.96484 18.8145 11.2109 18.4863 12.7266 18.209C13.7891 18.0156 14.7305 17.6543 15.5625 17.1738C15.0234 19.1406 14.4531 20.9062 13.9688 21.6562C13.3281 22.6562 12.3125 23.2305 11.1445 23.2305H8.76562H6.38281Z" fill="white"/>
        </svg>  Built with Pepperform
        </Link>

        </div>
    );
};
export default PoweredByPepperForm;

