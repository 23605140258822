import React from 'react';
import axios from 'axios';
import cryptoRandomString from 'crypto-random-string';
import CryptoJS from 'crypto-js';

const AirtableOAuth = () => {
  const clientId = '300a6c2c-9f86-48b2-af51-e41a57604434';
  const redirectUri = 'http://localhost:3000/connect-Airtable';

  // Generate a cryptographically secure random state value
  const state = cryptoRandomString({ length: 32 });
  console.log('State:', state);

  // Generate a cryptographically secure random code verifier
  const codeVerifier = cryptoRandomString({ length: 120 });
  console.log('Code Verifier:', codeVerifier);

  // Calculate the code challenge from the code verifier using crypto-js
  const codeChallenge = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Base64);
  console.log('Code Challenge:', codeChallenge);

  // Encode the code challenge in base64url format
  const base64UrlEncodedChallenge = codeChallenge
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  console.log('Base64URL Encoded Challenge:', base64UrlEncodedChallenge);

  // Store the code_challenge in local storage
  localStorage.setItem('code_challenge', codeVerifier);

  const authUrl = `https://airtable.com/oauth2/v1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=data.records:read%20schema.bases:read%20data.records:write&state=${state}&code_challenge=${base64UrlEncodedChallenge}&code_challenge_method=S256`;
  
  const handleOAuthClick = () => {
    // Redirect the user to Airtable for authorization
    window.location.href = authUrl;
  };

  return (
    <div>
      <h1>Airtable OAuth Example</h1>
      <button onClick={handleOAuthClick}>Authorize with Airtable</button>
    </div>
  );
};

export default AirtableOAuth;
