import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import createformstyle from '../css/navigation/CreateForm.module.scss'

const PepperModal = ({ isOpen, closeModal, children }) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyPress);
      
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isOpen, closeModal]);
  // The modal content can be passed as a child of PepperModal
  return (
    <div className={` ${createformstyle.modal} ${createformstyle.create} ${createformstyle.pepper_modal}`}>
      <div className={createformstyle.modal_overlay} onClick={closeModal}></div>
        <div className={createformstyle.close} onClick={closeModal}>
          <CloseIcon />
        </div>
        <div className={`${createformstyle.modal_content}`}>
        {children}
        </div>


    </div>
  );
};

export default PepperModal;
