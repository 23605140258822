import React, { useState, useEffect } from 'react';

import blockstyle from '../../css/Block.module.scss'
const EmptyStateNoField = ({ props, optionrefetch }) => {

    return (
      <div className={`${blockstyle.block_empty_state_container}`}>
    <div className={`${"wrapper__block"} ${blockstyle.block_edit_inner} ${blockstyle.block_empty_state} `}>
                <img src="https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/empty.gif" />
                <h2>Let's get started</h2>
                <p style={{maxWidth:"420px", margin:"auto"}}>To build your survey, click on 'Add blocks' in the navigator to start adding content to this form.</p>
              </div>

        </div>
    );
};
export default EmptyStateNoField;

