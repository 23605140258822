import React, {useEffect, useState} from "react";
import Masonry from "react-masonry-css";
import {Link} from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {createClient} from "pexels";

import inlinegallerystyle from "../../css/editor/InlineEditorGallery.module.scss";

const Pexels = ({handleUpdateCaption}) => {
  const [videosPerPage, setVideosPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [pexelSearchQuery, setPexelSearchQuery] = useState("");
  const [pexelSearchResults, setPexelSearchResults] = useState([]);
  const pexelclient = createClient(
    "lo2Y4RPVEumeYhETTvy6FbP1YzbEgg1boqpPUDgke2MYJuqiiQPdUdLG"
  );

  const nextPage = (page) => {
    setCurrentPage(page + 1);
  };

  const previousPage = (page) => {
    setCurrentPage(page - 1);
  };

  // Function to fetch the latest Pexels videos
  const fetchLatestVideos = () => {
    pexelclient.videos
      .search({query: "nature", per_page: 30, page: currentPage})
      .then((videos) => {
        setPexelSearchResults(videos);
      })
      .catch((error) => {
        console.error("Error fetching Pexels videos:", error);
      });
  };

  useEffect(() => {
    // Fetch the latest Pexels videos when the component mounts
    fetchLatestVideos();
  }, [currentPage]); // Include currentPage in the dependency array

  const handleSubmit = (e) => {
    pexelclient.videos
      .search({query: pexelSearchQuery, per_page: 30, page: currentPage})
      .then((videos) => {
        setPexelSearchResults(videos);
      })
      .catch((error) => {
        console.error("Error fetching Pexels videos:", error);
      });
  };
  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className={`${inlinegallerystyle.gallery_container}`}>
      <div className={`${inlinegallerystyle.gallery_search_wrapper}`}>
        <div
          className={`${inlinegallerystyle.gallery_search} ${inlinegallerystyle.add_url_input}`}>
          <input
            placeholder="Enter keywords (e.g. phone) then press enter"
            type="text"
            value={pexelSearchQuery}
            onChange={(e) => setPexelSearchQuery(e.target.value)}
            onKeyDown={handleInputKeyDown}
          />
          {pexelSearchQuery && (
            <span
              onClick={() => handleSubmit()}
              className={inlinegallerystyle.gallery_search_button}>
              <SearchIcon />
            </span>
          )}
        </div>
        {pexelSearchQuery && <p className={`${inlinegallerystyle.gallery_search_instruction}`}>
          Enter to search
        </p>}
      </div>
      <div className={`${inlinegallerystyle.gallery_items}`}>
        {pexelSearchResults.total_results > 0 ? (
          <Masonry
            breakpointCols={{
              default: 3,
              1100: 2,
              700: 4
            }}
            className={`${inlinegallerystyle.my_masonry_grid}`}
            columnClassName={`${inlinegallerystyle.my_masonry_grid_column}`}>
            {pexelSearchResults.videos.map((result, index) => (
              <div
                key={index}
                onClick={() =>
                  handleUpdateCaption(
                    result.video_files[0].link,
                    result.user.name,
                    result.user.url,
                    null,
                    "pexels"
                  )
                }
                className={`${inlinegallerystyle.gallery_item}`}>
                <img
                  src={result.image}
                  alt={"Pexel video"}
                  className={inlinegallerystyle.video_img}
                />
                <span className={inlinegallerystyle.link}>
                  Video by{" "}
                  <Link
                    target="_blank"
                    to={result.user.url}
                    alt={"Pexel video"}>
                    {result.user.name}
                  </Link>
                </span>
              </div>
            ))}
          </Masonry>
        ) : (
          <div>No results found</div>
        )}
        {/* {pexelSearchResults.total_results > 0 && (
          <div>
            {currentPage > 2 && (
              <span onClick={() => previousPage(currentPage)}>Previous</span>
            )}
            {currentPage > 0 && (
              <span onClick={() => nextPage(currentPage)}>Next</span>
            )}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Pexels;
