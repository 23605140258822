import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { isMobile } from 'react-device-detect';

import { UPDATE_FIELD } from '../../graphql/Field';
import UpdateFieldImageSize from './UpdateFieldImageSize';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';

import inlineeditorstyle from '../../css/editor/InlineEditor.module.scss'






const fieldMapping = {
  // Map the UI field names to the schema field names
  label: 'label',
  value: 'value',
  section: 'section',
  imgsize: 'imgsize',
  link: 'link',
  type: 'type',
  order: 'order',
  required: 'required',
  radiotype: 'radiotype',
  startlegend: 'startlegend',
  endlegend: 'endlegend',
  framedevicestyle: 'framedevicestyle',
  shadowstyle: 'shadowstyle',
  description: 'description',
  category: 'category',
  subfieldtype: 'subfieldtype',
  caption: 'caption',
  heading: 'heading',
  answernote: 'answernote',
  answernoteheading: 'answernoteheading',
};

const ImageField = ({ props }) => {
  const {field,myform, refetch} = props
  const { register, setValue } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenEditField, setIsOpenEditField] = useState(false);
  const [isOpenLink, setOpenLink] = useState(false);


  const imageSizeRef = useRef(null);
  const linkRef = useRef(null);


  const [updateField] = useMutation(UPDATE_FIELD);


const openModal = () => {
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
};

const handleUpdateCaption = async (id,valuetoggle) => {

  const newvalue = !valuetoggle
  try {
      const result = await updateField({
          variables: {
              id: id,
              myform: props.myform,
              valuetoggle: newvalue
          }
      });
      console.log(result)
      props.refetch();
  } catch (error) {
      console.error('Error updating field:', error);
  }
};


useEffect(() => {
  const handleClickOutsideEditField = (event) => {
    // Check if the ref element exists and if the event target is outside the ref element
    if (imageSizeRef.current && !imageSizeRef.current.contains(event.target)) {
      setIsOpenEditField(false);
    }

  };
  const handleClickOutsideLinkField= (event) => {
    // Check if the ref element exists and if the event target is outside the ref element

    if (linkRef.current && !linkRef.current.contains(event.target)) {
      setOpenLink(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutsideEditField);
  document.addEventListener('mousedown', handleClickOutsideLinkField);

  return () => {
    document.removeEventListener('mousedown', handleClickOutsideEditField);
    document.removeEventListener('mousedown', handleClickOutsideLinkField);
  };
}, []);



const fieldID = props.field ? props.field.id : '';
const required = props.field ? props.field.required : false;
const valuetoggle = props.field ? props.field.valuetoggle : false;
const isFullScreen = props.field ? props.field.imgsize === 'full-screen' ? true : false : false;
  return (
    <React.Fragment>
        <li ref={imageSizeRef}>
          <header onClick={() => setIsOpenEditField(!isOpenEditField)}> Size </header>
          {isOpenEditField && <UpdateFieldImageSize props={props} />}
        </li>
        {/* <li ref={linkRef}>
          <header onClick={() => setOpenLink(!isOpenLink)}> Link </header>
          {isOpenLink && <div>link</div>}
        </li> */}
        {!isFullScreen && <li className={`${valuetoggle ? inlineeditorstyle.selected : ''}`} onClick={()=>handleUpdateCaption(fieldID,valuetoggle)}>Caption</li>}


    </React.Fragment>
  );
};

export default ImageField;
