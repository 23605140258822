import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { S3_SIGN_MUTATION, CREATE_FILE, DELETE_FILE } from '../graphql/Images';
import ImageIcon from '@mui/icons-material/Image';
import mediaUploaderStyle from '../css/editor/MediaUploader.module.scss';

const UploadComponent = (props) => {
  const { setFileURL, type, mediatype,imageurl } = props;

  const [uploadedFile, setUploadedFile] = useState(null);
  const [imageURL, setImgURL] = useState('');
  const [errorType, setErrorType] = useState('');
  const [s3Sign] = useMutation(S3_SIGN_MUTATION);
  const [deleteFile] = useMutation(DELETE_FILE);
  const [createChampion] = useMutation(CREATE_FILE);

  const formatFilename = (filename) => {
    const randomString = uuidv4();
    const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, '-');
    const newFilename = `fieldimageupload/${randomString}-${cleanFileName}`;
    return newFilename.substring(0, 60);
  };

  const uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    await axios.put(signedRequest, file, options);
  };

  const onDrop = async (currentfile) => {
    const file = currentfile[0];

    // Check if the file type is allowed
    const allowedFileTypes = [
      'text/csv',
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/heic',
      'image/svg+xml',
      'image/*',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'text/plain',
    ];
    const allowedFileTypesPDF = [
      'text/csv',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'text/plain',
    ];

    if (errorType !== '') {
      setErrorType('');
    }

    const allowedTypes = props.type === 'PDF' ? allowedFileTypesPDF : allowedFileTypes;
    if (!allowedTypes.includes(file.type)) {
      setErrorType('invalid-type');
      console.log('Invalid file type. Please upload a CSV, image (JPEG/PNG/SVG), or TXT file.');
      return;
    }

    // Check if the file size is within the limit (1MB)
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
    if (file.size > maxFileSize) {
      setErrorType('file-size');
      console.log('File size exceeds the limit. Please upload a file smaller than 5MB.');
      return;
    }

    const filename = formatFilename(file.name); // Generate the formatted filename
    
    if (imageURL) {
      // Delete the existing file from S3
      try {
        await deleteFile({
          variables: {
            pictureUrl: imageURL,
          },
        });
      } catch (error) {
        console.error('Error deleting existing file:', error);
      }
    }
    
    const response = await s3Sign({
      variables: {
        filename: filename,
        filetype: file.type,
      },
    });

    const { signedRequest, url } = response.data.signS3;

    try {
      await uploadToS3(file, signedRequest);
      console.log(file.name);
      const { data } = await createChampion({
        variables: {
          pictureUrl: url,
          name: filename,
        },
      });

      console.log(url);

      // There's an image, set the image URL
      setImgURL(url);
      setFileURL(url, null, null, null, 'upload-media');

      // Set the uploaded file
      setUploadedFile({ name: file.name, url });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const removeFile = async () => {
    console.log('File deleted');

    try {
      await deleteFile({
        variables: {
          pictureUrl: imageURL,
        },
      });
      setImgURL('');
      setUploadedFile(null);
      setErrorType('');
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const fieldtype = props.mediatype ? props.mediatype : 'Image';

  return (
    <div className={`${mediaUploaderStyle.main_container} ${mediatype === 'PDF' ? mediaUploaderStyle.pdf : ''}`}>
      <div className={`${mediaUploaderStyle.dropzone}`} {...getRootProps()}>
        <input {...getInputProps()} multiple={false} />

        {uploadedFile ? (
          <div className={`${mediaUploaderStyle.content}`}>
            {mediatype === 'PDF' ? (
              <div className={`${mediaUploaderStyle.pdf_icon}`}>
                <span>
                  <PictureAsPdfIcon />
                </span>
                <span>{uploadedFile.name}</span>
              </div>
            ) : (
              <img src={imageURL} alt={uploadedFile.name} />
            )}
          </div>
        ) : isDragActive ? (
          <div className={`${mediaUploaderStyle.content}`}>
            <div className={`${mediaUploaderStyle.image}`}>
              <ImageIcon />
            </div>
            <div className={`${mediaUploaderStyle.title}`}>Drop {fieldtype} here</div>
            <div className={`${mediaUploaderStyle.instruction}`}>Browse an {fieldtype}</div>
          </div>
        ) : (
          <div className={`${mediaUploaderStyle.content}`} style={{ cursor: 'pointer' }}>
            <React.Fragment>
              <div className={`${mediaUploaderStyle.image}`}>
                <ImageIcon />
              </div>
              <div className={`${mediaUploaderStyle.title}`}>Upload {fieldtype}</div>
              <div className={`${mediaUploaderStyle.instruction}`}>Click here to start uploading your {fieldtype}</div>
            </React.Fragment>
          </div>
        )}
      </div>
      {errorType === 'invalid-type' && (
        <div className={`${mediaUploaderStyle.error}`}>Invalid file type. Please upload a CSV, image (JPEG/PNG/SVG), or TXT file.</div>
      )}
      {errorType === 'file-size' && (
        <div className={`${mediaUploaderStyle.error}`}>File size exceeds the limit. Please upload a file smaller than 5MB.</div>
      )}
      {imageURL && (
        <div className={`${mediaUploaderStyle.error}`} onClick={removeFile}>
          Remove
        </div>
      )}
    </div>
  );
};

export default UploadComponent;
