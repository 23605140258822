import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { isMobileOnly, isDesktop, isTablet } from "react-device-detect";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Logo from "./InlineEditor/Logo";
import GenericField from "./InlineEditor/GenericField";
import ReadOnlyField from "./InlineEditor/ReadOnlyField";
import UpdateField from "./InlineEditor/UpdateField";
import SubmitButton from "./InlineEditor/SubmitButton";
import ImageField from "./InlineEditor/ImageField";
import VideoField from "./InlineEditor/VideoField";
import Option from "./InlineEditor/Option";
import RemoveField from "./RemoveField";

import inlineeditorstyle from "../css/editor/InlineEditor.module.scss";
import blockstyle from "../css/Block.module.scss";
import LogoStack from "./InlineEditor/Logostack";

const fieldMapping = {
  // Map the UI field names to the schema field names
  label: "label",
  value: "value",
  section: "section",
  imgsize: "imgsize",
  link: "link",
  type: "type",
  order: "order",
  required: "required",
  radiotype: "radiotype",
  startlegend: "startlegend",
  endlegend: "endlegend",
  framedevicestyle: "framedevicestyle",
  description: "description",
  category: "category",
  subfieldtype: "subfieldtype",
  caption: "caption",
  heading: "heading",
  answernote: "answernote",
  answernoteheading: "answernoteheading",
  relatedFieldID: "relatedFieldID"
};

const InlineEditor = ({ props, sourceFile }) => {
  const { register, setValue } = useForm();

  const [isOpenEditField, setIsOpenEditField] = useState(false);
  const [isAllowOveflow, setIsAllowOverflow] = useState(isMobileOnly || isTablet ? true : false);
  

  const fieldType = props.field ? props.field.type : sourceFile;
  
  const fieldRadioType = props.field ? props.field.radiotype : sourceFile;
  const fieldCategory = props.field ? props.field.category : sourceFile;
  const readwritepermission = props.field
    ? props.field.readwritepermission
    : sourceFile;

  const containerDivRef = useRef(null);
  const editFieldDivRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const {
    source,
    field,
    handleDuplicateField,
    refetch,
    formFields,
    activeSection,
    setCurrentIndex,
    setInlineOpenHOC,
    fieldId,
    setFieldStatus,
    setIsSmartPathOpen,
    setCurrentSubParentField
  } = props;

  

  // const handleScrollDown = () => {
  //   if (isOpenEditField) {
  //     containerDivRef.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //       inline: 'nearest',
  //       offsetTop: -40,
  //     });
  //   } else {
  //     containerDivRef.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //     });
  //   }
  // };

  const openUpdateField = (instruction) => {
    setIsOpenEditField(instruction);
    if (instruction) {
      // handleScrollDown();
    }
  };

  const scrollToRight = () => {
    if (containerDivRef.current) {
      containerDivRef.current.scrollLeft = containerDivRef.current.scrollWidth;
    }
  };

  const RenameFieldRadioType = (name) => {

    switch (name) {
      case "optionBox":
        return "Multichoice";
      case "slideroption":
        return "Slider Multichoice";
      case "smartpath":
          return "Smart Path";
      default:
        return "";
    }
  };

  const RenameField = (name) => {
    switch (name) {
      case "input":
        return "Short Answer";
      case "longAnswer":
        return "Long Answer";
      case "name":
        return "Full Name";
      case "email":
        return "Email";
      case "tel":
        return "Phone";
      case "phone":
        return "Telephone";
      case "url":
        return "Website Address";
      case "number":
        return "Number";
      case "date":
        return "Date";
      case "signature":
        return "Signature";
      case "image-input":
        return "Image uploader";
      case "file-input":
        return "Document uploader";
      case "scale":
        return "Rate";
      case "nps":
        return "NPS";
      case "radio":
        return "Multiple choice";
      case "multichoice":
        return "Multiple choice";
      case "image":
        return "Image";
      case "video":
        return "Video";
      case "pdf":
        return "PDF";
      case "herotext":
        return "Hero Text";
      case "textblock":
        return "Paragraph";
      case "bulletpoint":
        return "Bullet Points";
      case "voucher":
        return "Voucher";
      case "contact":
        return "Contact";
      case "legal":
        return "Terms and Conditions";
      case "voucher":
        return "Voucher";
      case "logostack":
        return "Logo Stack";

      default:
        return "";
    }
  };

  useEffect(() => {
    const handleClickOutsideContainer = (event) => {
      if (
        containerDivRef.current &&
        !containerDivRef.current.contains(event.target)
      ) {
        openUpdateField(false);
      }
    };

    const handleClickOutsideEditField = (event) => {
      if (
        editFieldDivRef.current &&
        !editFieldDivRef.current.contains(event.target)
      ) {
        openUpdateField(false);
      }
    };
    // handleScrollDown();
    document.addEventListener("mousedown", handleClickOutsideContainer);
    document.addEventListener("mousedown", handleClickOutsideEditField);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideContainer);
      document.removeEventListener("mousedown", handleClickOutsideEditField);
    };
  }, []);

  return (
    <React.Fragment>
      <aside
        className={` ${blockstyle.inline_editor_container} 
        ${inlineeditorstyle.container}  
        ${sourceFile === "Logo" ? inlineeditorstyle.add_logo : ""} 
        ${sourceFile === "Logo" ? blockstyle.block_logo_inline : ""}
        ${isOpenEditField ? inlineeditorstyle.is_open_edit : ""}
        ${isAllowOveflow ? inlineeditorstyle.inline_is_allow_overflow : inlineeditorstyle.inline_not_overflow}

        `}
        ref={containerDivRef}
        id={'is_allowoverflow_'+ fieldId}
      >
        <ul>
          {fieldType === "Logo" || fieldType === "smartpath" || fieldType === "Button" || fieldCategory === 'media' ? (
            <li className={`${inlineeditorstyle.header}`}>
            
              <span>{fieldCategory === "media" ? RenameField(fieldType) : fieldType === 'smartpath' ? 'Smart Path' : fieldType}</span>
            </li>
          ) : (
            <li
              className={`${inlineeditorstyle.header}`}
              onClick={() => openUpdateField(true)}
            >
              <span>
                {fieldCategory !== "option"
                  ? RenameField(fieldType)
                  : RenameFieldRadioType(fieldRadioType)}
              </span>
              {fieldType !== "Logo" && (
                <span>
                  <ArrowDropDownIcon />
                </span>
              )}
            </li>
          )}
          {/* <span onClick={scrollToRight} className={inlineeditorstyle.scroll_to_right}><ChevronRightIcon/></span> */}
          {fieldType === "Logo" && <Logo props={props}  />}
          {fieldType === "Button" && <SubmitButton props={props} setIsAllowOverflow={setIsAllowOverflow} />}

          {readwritepermission === "readwrite" && (<GenericField props={props} setIsAllowOverflow={setIsAllowOverflow} />)}
          {readwritepermission === "read" && <ReadOnlyField props={props} setIsAllowOverflow={setIsAllowOverflow} />}
          {fieldType === "multichoice" && <Option props={props} />}
          {fieldType === "image" && <ImageField props={props} />}
          {fieldType === "video" || (fieldType === "pdf" && <VideoField props={props} />)}
          {fieldType === "logostack" && <LogoStack props={props}  setIsAllowOverflow={setIsAllowOverflow} />}

         {fieldType == "Button" || fieldType == "Logo" ? ''  : <React.Fragment>
         {fieldType !== 'smartpath' && <li className={blockstyle.duplicate_icon} onClick={() => handleDuplicateField(field)}>
            <ContentCopyIcon />
          </li>}
          <RemoveField
            fieldId={fieldId}
            activeSection={activeSection}
            refetch={refetch}
            field={field}
            setCurrentIndex={setCurrentIndex}
            setInlineOpenHOC={setInlineOpenHOC}
            formFields={formFields}
            setIsAllowOverflow={setIsAllowOverflow}
            setFieldStatus={setFieldStatus}
            setIsSmartPathOpen={setIsSmartPathOpen}
            setCurrentSubParentField={setCurrentSubParentField}
        
          >
            <div className={blockstyle.remove_icon} >
              <DeleteIcon />
            </div>
          </RemoveField>
          </React.Fragment>}

         {!isDesktop && <React.Fragment>
       
          <li className={`${inlineeditorstyle.li_sticky} ${inlineeditorstyle.li_sticky_right}`}  onClick={()=> props.handleManualUp(props.field)}><ArrowDropUpIcon/></li>
          <li className={`${inlineeditorstyle.li_sticky}`}  onClick={()=> props.handleManualDown(props.field)}><ArrowDropDownIcon/></li>
          

          </React.Fragment>}
        </ul>

        {isOpenEditField && (
          <div
            className={`${inlineeditorstyle.edit_fields}`}
            ref={editFieldDivRef}
          >
            <header>Update Field Type</header>
            <UpdateField
              props={props}
              setIsOpenEditField={setIsOpenEditField}
            />
          </div>
        )}
      </aside>
    </React.Fragment>
  );
};

export default InlineEditor;
