import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { isDesktop } from 'react-device-detect';

import { UPDATE_STYLE } from '../../graphql/Style';
import InlineEditor from '../InlineEditor';
import blockstyle from '../../css/Block.module.scss';

import SubmitButtonPrefixes from '../../reusablefields/SubmitButtonPrefixes';


const EditButton = ({ currentfield,currentIndex, formFields, myform, isSlideshow, formStyle, stylerefetch }) => {
    const { register, setValue } = useForm();
    const [updateStyle] = useMutation(UPDATE_STYLE);
    const [isError, setIsError] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const divRef = useRef(null);

    const handleFieldChange = (async (name, value) => {
        const id = myform;
        await setValue(name, value);

        const result = await updateStyle({
            variables: {
                myform: myform,
                buttonsubmittext: value,

            },
        });
    }); // Set the debounce delay (e.g., 300 milliseconds)

    const handleFieldChangeCaption = (async (name, value) => {
        const id = myform;
        await setValue(name, value);

        const result = await updateStyle({
            variables: {
                myform: myform,
                buttoncaption: value
            },
        });
    }); // Set the debounce delay (e.g., 300 milliseconds)



    const onInputChange = (e) => {
        const { name, value } = e.target;
        // Check if the input value exceeds the character limit (less than 25)
        handleFieldChange(name, value);

    };

    const onInputChangeCaption = (e) => {
        const { name, value } = e.target;
        // Check if the input value exceeds the character limit (less than 25)
        handleFieldChangeCaption(name, value);
        // if (value.length <= 25) {
        //     handleFieldChangeCaption(name, value);
        //     setIsError(false);
        // } else {
        //     // Show a warning to the user when the character limit is exceeded
        //     setIsError(true);
        // }
    };

    const openInlineEditor = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setIsOpen(false);

            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const buttonprefix = formStyle?.buttonprefix 
    
    const buttonsubmittext = formStyle?.buttonsubmittext 
    const buttoncaption = formStyle?.buttoncaption 
    const lastfield = (currentfield.order + 1) === formFields.length ? true : false
    return (
        <div className={`${blockstyle.block_footer_container} 
        ${isSlideshow ? blockstyle.block_footer_container_slideshow : ''}
        ${lastfield ? blockstyle.block_footer_container_last_field : ''}
        `} ref={divRef} >


            {/* isOpen && */}
            {isOpen && <InlineEditor props={{ formStyle, stylerefetch, myform }} sourceFile={'Button'} />}
            {isError && <div className={`${blockstyle.block_error}`}>Character limit is 25.</div>}
            <div className={`${blockstyle.block_footer_inner_container} ${isSlideshow ? blockstyle.block_item_content_slideshow_inner :''} ${'button__block--footer'}`} onClick={openInlineEditor}>

                {/* //Slideshow footer */}
                {isSlideshow ?
                    lastfield ? <div className={blockstyle.block_footer_button_group}>
                        {isSlideshow && <div className={`${blockstyle.block_footer_button} ${'previous'}`}>{<ArrowBackIcon />}</div>}
                        <div className={`button--submit ${blockstyle.block_footer_button} ${blockstyle.block_submit_button} ${'button__submit'}`}
                            style={{
                                color: formStyle?.backgroundColor,
                                // borderColor:formStyle?.primaryColor,
                                // backgroundColor:hexToRgba(formStyle?.primaryColor, 0.2)
                                backgroundColor: formStyle?.primaryColor,
                            }}>
                            
                            {buttonprefix && <span><SubmitButtonPrefixes prefix={buttonprefix} /></span>}

                            <input
                                type="text"
                                name="buttonsubmittext"
                                defaultValue={buttonsubmittext}
                                {...register('buttonsubmittext')}
                                onChange={onInputChange}
                                tabIndex={-1}
                                placeholder="Submit"
                                
                            />
                        </div>
                    </div> : <div className={blockstyle.block_footer_button_group}>
                        {isSlideshow && <div className={`${blockstyle.block_footer_button} ${'previous'}`}>{isDesktop && currentIndex > 1 ? 'Previous' : !isDesktop  && currentIndex > 1 ? <ArrowBackIcon />:''}</div>}
                        <div className={`button--submit ${blockstyle.block_footer_button} ${blockstyle.block_submit_button} ${'button__submit'}`}
                            style={{
                                color: formStyle?.backgroundColor,
                                // borderColor:formStyle?.primaryColor,
                                // backgroundColor:hexToRgba(formStyle?.primaryColor, 0.2)
                                backgroundColor: formStyle?.primaryColor,
                            }}>

                            <div>Next</div>
                        </div>
                    </div> :
                    // A classic form
                    <div className={`${blockstyle.block_footer_inner_container} ${'button__block--footer'}`} onClick={openInlineEditor}>
                        <div className={blockstyle.block_footer_button_group}>
                            {isSlideshow && <div className={`button--submit ${blockstyle.block_footer_button} ${'button__previous previous'}`}><ArrowBackIcon /></div>}
                            <div className={`button--submit ${blockstyle.block_footer_button} ${blockstyle.block_submit_button} ${'button__submit'}`}
                                style={{
                                    color: formStyle?.backgroundColor,
                                    // borderColor:formStyle?.primaryColor,
                                    // backgroundColor:hexToRgba(formStyle?.primaryColor, 0.2)
                                    backgroundColor: formStyle?.primaryColor,
                                }}>

                                {buttonprefix && <span><SubmitButtonPrefixes prefix={buttonprefix} /></span>}

                                <input
                                    type="text"
                                    name="buttonsubmittext"
                                    defaultValue={buttonsubmittext}
                                    {...register('buttonsubmittext')}
                                    onChange={onInputChange}
                                    tabIndex={-1}
                                    placeholder="Submit"

                                />
                            </div>
                        </div>
                    </div>}
                {formStyle?.buttoncaptiontoggle  && <div className={`${blockstyle.block_footer_caption}`}>
                    <input
                        type="text"
                        name="buttoncaption"
                        defaultValue={buttoncaption}
                        {...register('buttoncaption')}
                        onChange={onInputChangeCaption}
                        tabIndex={-1}
                        placeholder="Button caption"

                    />
                </div>}
            </div>


        </div>
    );
};

export default EditButton;
