import gql from "graphql-tag";

export const REGISTER = gql`
  mutation register($captchaValue: String, $firstname: String, $lastname: String, $email: String!, $password: String!) {
    register(captchaValue:$captchaValue, firstname: $firstname, lastname: $lastname, email: $email, password: $password) {
      success
      token
      refreshToken
    }
  }
`;

export const CHECK_STAFF = gql`
  query checkStaff {
    checkStaff
  }
`;
export const GENERATE_OTP_TOKEN = gql`
  mutation generateOTPToken($email: String!) {
    generateOTPToken(email: $email)
  }
`;

export const LOGIN_WITH_OTP = gql`
  mutation loginWithOTP($email: String!, $loginToken: String) {
    loginWithOTP(email: $email, loginToken: $loginToken){
      success
      token
      refreshToken
      errors{
        path 
        message
      }
    }
  }
`;
export const GET_CURRENTUSER = gql `
  query currentUser{
    currentUser{
      id
      firstname
      lastname
      email
      avatar
      type
      airtable
      googleId
    }
  }
`;
export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      token
      refreshToken
      errors{
        path 
        message
      }
    }
  }
`;

export const GOOGLE_LOGIN = gql`
  mutation googleLogin($googleToken: String!) {
    googleLogin(googleToken: $googleToken) {
      token
      refreshToken
      user {
        id
        email
        firstname
        lastname
        avatar
        googleId
      }
    }
  }
`;
export const EDIT_SELF = gql`
  mutation EditSelf($airtable:String, $firstname: String, $lastname: String, $email: String, $avatar: String) {
    editSelf(airtable: $airtable, firstname: $firstname, lastname: $lastname, email: $email, avatar: $avatar) {
      id
      firstname
      lastname
      email
      avatar
      airtable
    }
  }
`;
export const VALIDATE_RESET_TOKEN = gql`
  mutation validateResetToken($token: String!){
    validateResetToken(token: $token){
      id
      firstname
      lastname
      email
      avatar
      type
      googleId
    }
}
`;

export const PASSWORD_RESET = gql`
  mutation passwordreset($email: String!) {
    passwordreset(email: $email) {
      id
      firstname
      lastname
      email
      avatar
      type
      googleId
    }
  }
`;

export const PASSWORD_UPDATE = gql`
  mutation passwordupdate($email: String!, $password: String!, $resetToken: String!) {
    passwordupdate(email: $email, password: $password, resetToken: $resetToken) {
      id
      firstname
      lastname
      email
      avatar
      type
    }
  }
`;
export const CREATE_STRIPE_SUBSCRIPTION = gql`
  mutation CreateStripeSubscription($customer_id: String!, $default_payment_method: String!) {
    createStripeSubscription(
      customer_id: $customer_id
      default_payment_method: $default_payment_method
    )
  }
`;

export const CREATE_PAY_INTENT = gql`
  mutation CreatePayIntent(
    $source: String
    $id: String
    $ccName: String
    $ccNumber: Int
    $ccExp_month: Int
    $ccExp_year: Int
    $ccCvc: Int
  ) {
    createPayIntent(
      source: $source
      id: $id
      ccName: $ccName
      ccNumber: $ccNumber
      ccExp_month: $ccExp_month
      ccExp_year: $ccExp_year
      ccCvc: $ccCvc
    ) {
      client_secret
      customer_ID
    }
  }
`;

export const MY_NOTIFICATIONS = gql `
  query mynotifications($offset: Int){
    mynotifications(offset: $offset) {
      type
      id
      seen
      myuser
      myform
      context
      created_at
      updated_at
      totalEventCount
    }
  }
`;

export const UNREAD_NOTIFICATION_COUNT = gql`
  query unreadNotificationCount{
    unreadNotificationCount
  }
`;


export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification(
    $type: String
    $id: String
    $seen: Boolean
    $myuser: String
    $myform: String
    $contest: String
    $created_at: String
    $updated_at: String
  ) {
    updateNotification(
      type: $type
      id: $id
      seen: $seen
      myuser: $myuser
      myform: $myform
      contest: $contest
      created_at: $created_at
      updated_at: $updated_at
    ) {
      type
      id
      seen
      myuser
      myform
      totalEventCount
      context
      created_at
      updated_at
    }
  }
`;



export const NEW_RECORD_NOTIFICATION = gql`
  mutation newRecordNotificationAllFormMembers(
    $myform: String,
    $context: String
  ) {
    newRecordNotificationAllFormMembers(
      myform: $myform,
      context: $context
    )
  }
`;


