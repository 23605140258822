import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { CHECK_ANSWER_ON_FIELD, REMOVE_FIELD, REMOVE_SMART_FIELD } from '../graphql/Field';
import PepperModal from '../utils/PepperModal';
import fieldstyle from '../css/FieldDelete.module.scss'


const RemoveField = ({ fieldId, children, activeSection, refetch, field, setCurrentIndex, setInlineOpenHOC, isInlineOpenHOC, formFields, setIsAllowOverflow, setFieldStatus, setIsSmartPathOpen, setCurrentSubParentField }) => {
    const [data, setData] = useState(null);
    const [removeField] = useMutation(REMOVE_FIELD);
    const [removeSmartField] = useMutation(REMOVE_SMART_FIELD);
    const [queryHasData, setQueryHasData] = useState(false);
    const [newFieldZero, setNewFieldZero] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isButtonClicked, setButtonClick] = useState(false);
    const [isConfirmDeleteSmartPath, setIsConfirmDeleteSmartPath] = useState(false);


    // const handleRemoveField = async (fieldid) => {
    //     try {
    //       await removeField({
    //         variables: {
    //           id: fieldid,
    //           section: activeSection
    //         },
    //       });
    //       setCurrentIndex(field.order-1);
    //       //I want this to be replace with the uuid of the field before the one that has been deleted
    //       //formFields is all the fields that is present in the form before getting refetch
    //       setInlineOpenHOC('ec62e750-3f12-11ee-ac63-4d98a6b100b4')
    //       console.log(field.order-1)
    //       refetch();
    //     } catch (error) {
    //       console.error('Error removing field:', error);
    //     }
    //   };

    const handleRemoveField = async (fieldid) => {
        
        console.log('handle remove field')
     
        try {
            // Find the index of the current field being deleted
            const currentIndex = formFields.findIndex((f) => f.id === fieldid);

            // Get the UUID of the field before the current one
            const previousFieldUUID = currentIndex > 0 ? formFields[currentIndex - 1].id : null;
            setFieldStatus('Remove field', true)
            await removeField({
                variables: {
                    id: fieldid,
                    section: activeSection,
                },
            });

            setFieldStatus('Remove field', false)

            if (field.order === 0) {
                // If the deleted field had order 0, refetch the data and populate the new field with order 0
                const newData = await refetch();
                if (newData.data && newData.data.formFields && newData.data, formFields.length > 0) {
                    const newOrderZeroField = newData.data.formFields.find((f) => f.order === 0);
                    const newid = newOrderZeroField.id
                    setNewFieldZero(newOrderZeroField.id)
                    setInlineOpenHOC(newid);
                }

            }else{
                setCurrentIndex(field.order - 1);
         
                // Set the inlineOpenHOC with the UUID of the previous field
                setInlineOpenHOC(previousFieldUUID);
               
                refetch();
            }



        } catch (error) {
            console.error('Error removing field:', error);
        }
    };
    const removingSmartField = async(fieldid) => {
        try {
            // Find the index of the current field being deleted
            const currentIndex = formFields.findIndex((f) => f.id === fieldid);

            // Get the UUID of the field before the current one
            const previousFieldUUID = currentIndex > 0 ? formFields[currentIndex - 1].id : null;
            setFieldStatus('Remove field', true)
            await removeSmartField({
                variables: {
                    myfield: fieldid
                },
            });

            setFieldStatus('Remove field', false)

            if (field.order === 0) {
                // If the deleted field had order 0, refetch the data and populate the new field with order 0
                const newData = await refetch();
                if (newData.data && newData.data.formFields && newData.data, formFields.length > 0) {
                    const newOrderZeroField = newData.data.formFields.find((f) => f.order === 0);
                    const newid = newOrderZeroField.id
                    setNewFieldZero(newOrderZeroField.id)
                    setInlineOpenHOC(newid);
                }

            }else{
                setCurrentIndex(field.order - 1);
                
                // Set the inlineOpenHOC with the UUID of the previous field
                setInlineOpenHOC(previousFieldUUID);
               
                refetch();
            }
            refetch();
            setCurrentSubParentField(null);
            setIsSmartPathOpen(false);
      
            console.log('smartfield removed')



        } catch (error) {
            console.error('Error removing field:', error);
        }
        
        
    }
    
    const handleRemoveSmartField = (fieldid) =>{
        console.log('smart field delete')
        setIsConfirmDeleteSmartPath(true)
        isConfirmDeleteSmartPath && removingSmartField(fieldid)
    }
    
    const changeOverflow = () => {
        const targetDiv = document.getElementById('is_allowoverflow_' + field.id);
        if (targetDiv) {
          targetDiv.style.overflow = 'visible';
        }
    }

    const [getAnswersAndRemoveField, { loading, error, data: queryData }] = useLazyQuery(CHECK_ANSWER_ON_FIELD, {
        variables: {
            myfield: field.id,
        },
        onCompleted: (data) => {
            if (data && data.checkForAnswersOnField > 0) {
                changeOverflow();
                setData(data);
                setButtonClick(true);
                setIsModalOpen(true);
            } else {
                if(field.type === 'smartpath'){
         
                    handleRemoveSmartField(fieldId)
                }else{
                    handleRemoveField(fieldId);
                   
                }
            }
        },
    });



    // When the button is clicked, trigger the query and handle the data
    const handleButtonClick = () => {
 
            getAnswersAndRemoveField();

    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Handle the data received from the query
    useEffect(() => {
        if (queryData && queryData.someDataProperty?.length > 0) {
            setData(queryData);
            setQueryHasData(true);
        }
    }, [queryData]);

    // Call the handleRemoveField function when data is available
    useEffect(() => {
        if (queryHasData) {
            handleRemoveField(data);
        }
    }, [queryHasData, data, handleRemoveField]);

    return (
        <React.Fragment>
    
            {/* Trigger the handleButtonClick function when the button is clicked */}
            {isButtonClicked ? (
                <React.Fragment>
                    {queryData && <li onClick={openModal}>{children}</li>}
                    {isModalOpen && (
                        <div className={fieldstyle.field_delete__wrapper}>
                            <div className={fieldstyle.field_delete}>
                                <header>Hold on! You've got {queryData.checkForAnswersOnField} answers linked to this question.</header>
                                <p>Would you like to delete it?</p>
                                <span onClick={() => closeModal()}>Cancel </span>
                                <span className={fieldstyle.delete} onClick={() =>  handleRemoveField(fieldId)}>Delete</span>
                            </div>
                        </div>

                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {/* This appears when the button isn't clicked yet */}

                    <li onClick={handleButtonClick}>{children}</li>
                </React.Fragment>
            )}
            {field?.type === 'smartpath' &&  isConfirmDeleteSmartPath && (
                        <div className={fieldstyle.field_delete__wrapper}>
                            <div className={fieldstyle.field_delete}>
                                <header>You're about to delete all fields related to this block</header>
                                <p>Would you like to delete it?</p>
                                <span onClick={() => setIsConfirmDeleteSmartPath(false)}>Cancel </span>
                                <span className={fieldstyle.delete} onClick={() => handleRemoveSmartField(fieldId) }>Delete</span>
                            </div>
                        </div>

                    )}
        </React.Fragment>
    );
};

export default RemoveField;
