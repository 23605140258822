export function formatDateTime(dateTime) {
    const yesterday = new Date();
    const today = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
  
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const options2= {
      hour: 'numeric',
      minute: 'numeric',
      // weekday: 'short', 
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour12: true,
    };
  
    if (dateTime instanceof Date && !isNaN(dateTime)) {
      if (dateTime > yesterday && dateTime < today) {
        return 'Today ' + dateTime.toLocaleTimeString(undefined, options);
      } else if (dateTime < yesterday && dateTime < today) {
        return  dateTime.toLocaleTimeString(undefined, options2);
      }
      else {
        return 'Yesterday ' + dateTime.toLocaleTimeString(undefined, options);
      }
    } else {
      return 'Invalid date';
    }
  }
  
  export function formatDateTimeLong(dateTime) {
    const options = {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
  
    if (dateTime instanceof Date && !isNaN(dateTime)) {
      return dateTime.toLocaleDateString(undefined, options);
    } else {
      return 'Invalid date';
    }
  }
