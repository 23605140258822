import React from 'react';


export const FieldTypeGallery = [
    { name: 'unsplash', title: 'Unsplash', logo: 'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/unsplash.png' },
    { name: 'upload-media', title: 'Upload Image', logo: 'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/upload_img.png' }
]


export const UploadMediaOnly = [
    { name: 'upload-media', title: 'Upload Media', logo: 'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/upload_img.png' }
]


export const FieldVideoGallery = [
    { name: 'pexels', title: 'Pexels', logo: 'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/unsplash.png' },
    { name: 'youtube', title: 'Youtube', logo: 'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/Youtube.png' },
    { name: 'twitch', title: 'Twitch', logo: 'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/Twitch.png' },
    { name: 'vimeo', title: 'Vimeo', logo: 'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/Vimeo.png' }

]

export const StandardMediaGallery = [
    { name: 'unsplash', title: 'Unsplash', logo: 'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/unsplash.png' },
    { name: 'pexels', title: 'Pexel', logo: 'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/unsplash.png' },
    { name: 'upload-media', title: 'Upload Image', logo: 'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/upload_img.png' }
]
