import gql from "graphql-tag";

export const MEMBERS_BY_FORM = gql`
  query membersByForm($myform: String!) {
    membersByForm(myform: $myform) {
        role
        id
        created_at
        myform
        myuser
        referee
        user{
          id
          avatar
          firstname
          lastname
          email
          type
        }
    }
  }
`;

// checkMembership(myform:String): Boolean

export const CHECK_MEMBERSHIP = gql`
  query checkMember($myform: String!) {
    checkMember(myform: $myform)
  }
`;
export const MEMBERS_I_REFER = gql`
  query membersByForm($myform: String!) {
    membersByForm(myform: $myform) {
      role
      id
      created_at
      myform
      myuser
      referee
      user{
        id
        avatar
        firstname
        lastname
        email

      }
    }
  }
`;
export const INVITE_MEMBERS = gql`
  mutation inviteMember(       
    $role: String,
    $myuser: String,
    $myform: String,
    $email: String,
    $referee: String,
    $creator: String,
    $firstname: String,
    $lastname: String
  ) {
    inviteMember(
      role: $role,
      myuser: $myuser,
      myform: $myform,
      email: $email,
      firstname: $firstname,
      lastname: $lastname
      referee: $referee,
      creator: $creator
    ) {
      created
      message
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation deleteMember($id: String) {
    deleteMember(id: $id)
  }
`;
