import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { isDesktop } from 'react-device-detect';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { UPDATE_FIELD } from '../../graphql/Field';


import inlineeditorstyle from '../../css/editor/InlineEditor.module.scss'

const lists = [{ name: 'original', label: 'original' }, { name: 'monochrome', label: 'monochrome' }]

const LogoStackFilter = ({ props}) => {
    const {field, refetch} = props;
    const [isShowFilter, setShowFilter] = useState(false);
    const [value, setValue] = useState(field ? field.logoFieldFilter : 'original');
    const [updateField] = useMutation(UPDATE_FIELD);


    const handleUpdate = async (event) => {
        const style = event.target.value;
        setValue(style)
    
        try {
          const result = await updateField({
            variables: {
                id: field.id,
                myform: props.myform,
                logoFieldFilter: style,
            }
        });
           refetch();
           setShowFilter(false)

        } catch (error) {
            console.error('Error updating field:', error);
        }
      };

  
    

    return (
        <React.Fragment>
        {isDesktop && <div className={`${inlineeditorstyle.inline_sub_section_item} ${inlineeditorstyle.logo_filter}`}>
            <header>Select position</header>
            <div className={`${inlineeditorstyle.inline_sub_section_item_choice}`}>
                <header>Filter</header>
                <select value={value} onChange={handleUpdate}>
                    {lists.map((list, index) => (
                        <option key={index} value={list.name}>
                            {list.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>}
        {!isDesktop && <li className={`${inlineeditorstyle.inline_sub_section_item_mobile}`}>
        <span onClick={() => setShowFilter(!isShowFilter)}>Filter</span>
          {isShowFilter && (
            <div className={`${inlineeditorstyle.inline_sub_section_item_mobile_dropdown}`}>
            <select value={value} onChange={handleUpdate}>
              {lists.map((list, index) => (
                <option key={index} value={list.name}>
                  {list.label}
                </option>
              ))}
            </select>
            <ArrowDropDownIcon />
            </div>
          )}
          </li>}
        </React.Fragment>
    );
};
export default LogoStackFilter;

