import React, { useState, useEffect } from 'react';

import { IconTextAnswer, IconMultichoice, IconScale, IconDate, IconTypeImage, IconMedia, IconMultichoiceEmoji, IconMultichoiceImage, IconMultichoiceSession, 
    IconDropdown, IconScaleRate, IconScaleNPS, IconTypeAgenda, IconTypeBulletPoints, IconTypeContact, IconTypeHeroTextBlock, IconTypeLogoStack, IconTypeProfile, 
    IconTypeSocialProof, IconTermsAndConditions, IconTypeTextBlock, IconTypeTestimonials, IconTypeVouchers, IconTypeMockup, IconTypeVideo, IconTypePDF, IconSmartPath } from '../SVG/Fields'

import{IconShortAnswer,IconLongAnswer, IconSignature, IconNumber, IconFullName, IconTelephone, IconEmail, IconAddress, IconWebAddress, IconQuestionTypeDropdown, IconMultichoiceGeneric, IconQuestionTypeDate} from '../SVG/Fields'

import { BlockName, BlockEmail, BlockNumber, BlockLogo, BlockPhone, BlockWebsite, BlockShortAnswer, BlockLongAnswer, BlockDate, BlockInlineMC, BlockDropdownMC, BlockSliderMC, BlockScale, BlockRating, BlockSignature, BlockUploadFile, BlockHeroText, BlockParagraphText, BlockBulletPonts, BlockCards, BlockContactDetails, BlockTOC, BlockVoucher, BlockImage, BlockVideo, BlockPDF } from '../Navigation/ItemSVG'


import itemstyle from '../../css/editor/AddNewBlock.module.scss'


const RenderFieldIcon = ({ icon, question }) => {
    // Mapping between icon types and SVG components
    const iconMap = {
        input: BlockShortAnswer,
        longAnswer: BlockLongAnswer,
        number: BlockNumber,
        tel: BlockPhone,
        email: BlockEmail,
        url: BlockWebsite,
        name: BlockName,
        signature: BlockSignature,
        'image-input': BlockUploadFile,
        'file-input': BlockUploadFile,
        'optionBox': BlockInlineMC,
        'blockOuterLabel': IconMultichoiceEmoji,
        'pictureBox': IconMultichoiceImage,
        'session': IconMultichoiceSession,
        slideroption: BlockSliderMC,
        dropdown: BlockDropdownMC,
        nps: BlockScale,
        rate: BlockRating,
        date: BlockDate,
        agenda: IconTypeAgenda,
        bulletpoint: BlockBulletPonts,
        contact: BlockContactDetails,
        herotext: BlockHeroText,
        logostack: BlockLogo,
        profile: IconTypeProfile,
        socialproof: IconTypeSocialProof,
        'social-media-share': IconTypeSocialProof,
        legal: BlockTOC,
        textblock: BlockParagraphText,
        testimonials: IconTypeTestimonials,
        voucher: BlockVoucher,
        image: BlockImage,
        mockup: IconTypeMockup,
        video: BlockVideo,
        pdf: BlockPDF,
        'smartpath': IconSmartPath,

    };


    const IconComponent = iconMap[icon]; // Get the corresponding SVG component based on the icon type

    return (
        <span  >
            {IconComponent && <IconComponent />} {/* Render the SVG component */}
        </span>
    );
};
export default RenderFieldIcon;
