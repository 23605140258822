import React from 'react'; // Import React if not already imported


export const isAuthenticated = async () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    return true;
  }
  return false;
};

export const handleLogout = () => {
  localStorage.removeItem('accessToken');

  // Redirect to the login page
  window.location.href = '/login';
};

// // Create a functional component that fetches the membership status
// export const isMember = ({ myform }) => {
//   const { loading, error, data } = useQuery(CHECK_MEMBERSHIP, {
//     variables: { myform },
//   });

//   if (loading || error) {
//     // Handle loading and error states here
//     return null;
//   }

//   const isUserMember = data.checkMembership;

//   // Render components based on the membership status
//   return (
//     // Your UI components based on isUserMember status

//     <div>
//       {console.log (data)}
//       {isUserMember ? <p>User is a member</p> : <p>User is not a member</p>}
//     </div>
//   );
// };
