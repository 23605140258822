import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { isDesktop } from 'react-device-detect';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { UPDATE_STYLE } from '../../graphql/Style';

import inlineeditorstyle from '../../css/editor/InlineEditor.module.scss';

const lists = [{ name: 'left', label: 'Left' }, { name: 'center', label: 'Center' }];

const UpdateLogoPosition = ({ props }) => {
  const [value, setValue] = useState(props.formStyle.logoposition);
  const [updateStyle] = useMutation(UPDATE_STYLE);
  const [isShowFilter, setShowFilter] = useState(false);
  const handleUpdate = async (event) => {
    const style = event.target.value;
    setValue(style);

    try {
      const result = await updateStyle({
        variables: {
          myform: props.myform,
          logoposition: style
        }
      });

      props.stylerefetch();
      setShowFilter(false);
    } catch (error) {
      console.error('Error updating field:', error);
    }
  };

  return (
    <React.Fragment>
     { isDesktop && <div className={`${inlineeditorstyle.inline_sub_section_item} ${inlineeditorstyle.inline_sub_section_logo}`}>
        <header>Select position</header>
        <div className={`${inlineeditorstyle.inline_sub_section_item_choice} `}>
          <header>Position</header>
          <select value={value} onChange={handleUpdate}>
            {lists.map((list, index) => (
              <option key={index} value={list.name}>
                {list.label}
              </option>
            ))}
          </select>
        </div>
      </div>}
      {!isDesktop && (
        <li className={`${inlineeditorstyle.inline_sub_section_item_mobile}`}>
          <span onClick={() => setShowFilter(!isShowFilter)}>Position</span>
          {isShowFilter && (
            <div className={`${inlineeditorstyle.inline_sub_section_item_mobile_dropdown}`}>
              <select value={value} onChange={handleUpdate}>
                {lists.map((list, index) => (
                  <option key={index} value={list.name}>
                    {list.label}
                  </option>
                ))}
              </select>
              <ArrowDropDownIcon />
            </div>
          )}
        </li>
      )}
    </React.Fragment>
  );
};
export default UpdateLogoPosition;
