import React, { useEffect, useState, useRef } from 'react';

import { useForm, watch } from 'react-hook-form';

import styles from '../css/navigation/Navigation.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Fade } from 'react-awesome-reveal';
import { handleLogout } from '../utils/Auth';
import Notification from './Notification';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AvatarPicker from './AvatarPicker';
import { useQuery, useMutation, gql } from '@apollo/client';
import { GET_CURRENTUSER, EDIT_SELF } from '../graphql/User';

function UserNav({onToggleUserMenu, onToggleNotificationMenu, setMenuOpen}) {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAvatarPicker, setshowAvatarPicker] = useState(false);
  const { loading, error, data, refetch } = useQuery(GET_CURRENTUSER);
  const [editSelf] = useMutation(EDIT_SELF);
  const { register, setValue, handleSubmit, watch } = useForm();
  const watchedFields = watch(); // Initialize the watch function

  // Initialize state with data.firstname and data.email as initial values
  const [firstName, setFirstName] = useState(data?.currentUser?.firstname || '');
  const [lastName, setLastName] = useState(data?.currentUser?.lastname || '');
  const [email, setEmail] = useState(data?.currentUser?.email || '');
  const [selectedAvatar, setSelectedAvatar] = useState(null); // Added selectedAvatar state

  const currentUser = data?.currentUser;
  const containerDivRef = useRef(null);

  const toggleMenu = () => {
    
    setUserMenuOpen(!userMenuOpen);
    onToggleUserMenu(userMenuOpen);
    
    setMenuOpen(false);
    setNotificationsOpen(false);
    const targetDiv = document.getElementById('navigation_container');
    if (targetDiv) {
      targetDiv.style.zIndex = '2';
    }

  };


  const handleNotificationOpen = () => {
    setUserMenuOpen(false);
    setNotificationsOpen(!notificationsOpen);
    onToggleNotificationMenu(notificationsOpen);
    setMenuOpen(false);
  };


  const handleEditProfile = () => {
    setShowModal(true);
  };

  const handleCloseModal = (e) => {
    if (e.target.classList.contains(styles.modaloverlay)) {
      setShowModal(false);
      // Reset the form fields
      setFirstName(data?.currentUser?.firstname || '');
      setLastName(data?.currentUser?.lastname || '');
      setEmail(data?.currentUser?.email || '');
      setSelectedAvatar(null); // Reset selectedAvatar state
    }
  };

  const handleFormSubmit = (data) => {
    const { firstname, lastname, email } = watchedFields; // Get the latest form values

    const payload = {
      firstname,
      lastname,
      email,
    };

    editSelf({ variables: payload })
      .then(() => {
        console.log('Form submitted successfully!');
        // Refetch the user data to get the updated avatar
        refetch();
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
      setShowModal(!showModal);

  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  const handleAvatarSelect = (selectedAvatar) => {
    setSelectedAvatar(selectedAvatar); // Set the selected avatar
  
    const payload = {
      avatar: selectedAvatar,
    };
  
    editSelf({ variables: payload })
      .then(() => {
        // Refetch the user data to get the updated avatar
        refetch();
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
      
  };

  useEffect(() => {
    const handleClickOutsideContainer = (event) => {
      if (containerDivRef.current && !containerDivRef.current.contains(event.target)) {
        setUserMenuOpen(false);
        onToggleUserMenu(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutsideContainer);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideContainer);
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error occurred: {error.message}</div>;
  }

  return (
    <div className={styles.navigation__user} ref={containerDivRef}>
      <Notification notificationOpen={handleNotificationOpen} setUserMenuOpen={setUserMenuOpen} />
      {currentUser?.avatar ? (
        <span className={styles.avatar} onClick={toggleMenu}>
          <img src={currentUser.avatar} alt="User Avatar" />
        </span>
      ) : (
        <span className={`${styles.avatar} ${styles.empty}`} onClick={toggleMenu}>
          {`${data?.currentUser?.firstname.charAt(0)}${data?.currentUser?.lastname.charAt(0)}`}
        </span>
      )}
      {userMenuOpen ? (
        <Fade up duration={300} distance="20px">
          <ul className={styles.user_menu} >
            <li>
              <a href="#" onClick={handleEditProfile}>
                Edit profile
              </a>
            </li>
            <li>
              <a href="/premium" className={styles.premium}>
                Get Premium <AutoAwesomeIcon />
              </a>
            </li>
            <li>
              <a href="#" onClick={handleLogout}>
                Logout
              </a>
            </li>
          </ul>
        </Fade>
      ) : null}
      {showModal && (
        <div className={styles.modaloverlay} onClick={handleCloseModal}>
          {/* <div className={styles.modal_close} onClick={handleCloseModal}>
            <CloseIcon ></CloseIcon>
          </div> */}
          <div className={styles.modal}>
            <div className={styles.modal_content}>
              <div className={styles.modal_header}>
                <h2>Edit Profile</h2>
              </div>
              <div className={styles.avatar_picker}>
                  <div className={styles.avatar_picker__header}>
                      {currentUser.avatar ? (
                        <div className={styles.avatar} onClick={()=>setshowAvatarPicker(!showAvatarPicker)} >
                          <img src={currentUser.avatar} alt="User Avatar" />
                          {/* <p>Show Current Avatar</p> */}
                        </div>
                      ) : (
                        <span className={styles.avatar} onClick={()=>setshowAvatarPicker(!showAvatarPicker)}>
                          {`${data.currentUser.firstname.charAt(0)}${data.currentUser.lastname.charAt(0)}`}
                        </span>
                      )}
                      <a onClick={()=>setshowAvatarPicker(!showAvatarPicker)}>{!showAvatarPicker?'Change avatar':'Close Avatar picker'}</a>
                  </div>

                      {showAvatarPicker && 
                      <AvatarPicker onSelect={handleAvatarSelect} selectedAvatar={selectedAvatar} />
                      }
                    </div>
              <div className={styles.modal_body}>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <div className={styles.userEditForm}>
                    <article>
                      First Name
                      <input
                        type="text"
                        name="name"
                        defaultValue={data.currentUser.firstname}
                        {...register('firstname')}
                        onChange={handleFieldChange}
                      />
                    </article>
                    <article>
                      Last Name
                      <input
                        type="text"
                        name="lastname"
                        defaultValue={data.currentUser.lastname}
                        {...register('lastname')}
                        onChange={handleFieldChange}
                      />
                    </article>
                    <article>
                      Email
                      <input
                        type="text"
                        name="email"
                        defaultValue={data.currentUser.email}
                        {...register('email')}
                        onChange={handleFieldChange}
                      />
                    </article>

                  </div>
                  <button type="submit">Save</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserNav;
