import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { isMobile, isDesktop } from "react-device-detect";

import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";

import { UPDATE_FIELD } from "../../graphql/Field";

import inlineeditorstyle from "../../css/editor/InlineEditor.module.scss";
import UpdateFieldImagePosition from "./UpdateFieldImagePosition";
import PepperModal from "../../utils/PepperModal";
import MediaGallery from "./MediaGallery";

import { IconGeneric } from "./SvgInline";

const fieldMapping = {
  // Map the UI field names to the schema field names
  label: "label",
  value: "value",
  section: "section",
  imgsize: "imgsize",
  link: "link",
  type: "type",
  order: "order",
  required: "required",
  radiotype: "radiotype",
  startlegend: "startlegend",
  endlegend: "endlegend",
  framedevicestyle: "framedevicestyle",
  shadowstyle: "shadowstyle",
  description: "description",
  category: "category",
  subfieldtype: "subfieldtype",
  caption: "caption",
  heading: "heading",
  answernote: "answernote",
  answernoteheading: "answernoteheading",
};

const ReadOnlyField = ({ props }) => {
  const { field, myform, refetch } = props;
  const { register, setValue } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenEditField, setIsOpenEditField] = useState(false);
  const editFieldDivRef = useRef(null);

  const [updateField] = useMutation(UPDATE_FIELD);

  const handleUpdateRequired = async (id, required) => {
    try {
      const result = await updateField({
        variables: {
          id: id,
          myform: props.myform,
          required: !required,
        },
      });
      props.refetch();
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };
  const openModal = () => {
    setIsModalOpen(true);

    const targetDiv = document.getElementById('is_allowoverflow_' + field.id);
    if (targetDiv) {
      targetDiv.style.zIndex = 'unset';
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateDescriptionToggle = async (id, descriptiontoggle) => {
    try {
      const result = await updateField({
        variables: {
          id: id,
          myform: props.myform,
          descriptiontoggle: !descriptiontoggle,
        },
      });
      props.refetch();
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  useEffect(() => {
    const handleClickOutsideEditField = (event) => {
      // Check if the ref element exists and if the event target is outside the ref element
      if (
        editFieldDivRef.current &&
        !editFieldDivRef.current.contains(event.target)
      ) {
        setIsOpenEditField(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideEditField);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideEditField);
    };
  }, []);

  const fieldID = props.field ? props.field.id : "false";
  const required = props.field ? props.field.required : false;
  const descriptiontoggle = props.field ? props.field.descriptiontoggle : false;
  return (
    <React.Fragment>
      <li
        className={`${descriptiontoggle ? inlineeditorstyle.selected : ""}`}
        onClick={() =>
          handleUpdateDescriptionToggle(fieldID, props.field.descriptiontoggle)
        }
      >
        Description
      </li>

      <li onClick={openModal}>Image</li>
      {isModalOpen && (
        <PepperModal isOpen={isModalOpen} closeModal={closeModal}>
          <MediaGallery props={props} closeModal={closeModal} />
        </PepperModal>
      )}
            { props.field.prefix && isDesktop && (
        <li
          className={`${inlineeditorstyle.inline_sub_section}`}
          ref={editFieldDivRef}
        >
          <header onClick={() => setIsOpenEditField(!isOpenEditField)}>
            Image Position
          </header>
          {isOpenEditField && <UpdateFieldImagePosition props={props} />}
        </li>
      )}
    </React.Fragment>
  );
};

export default ReadOnlyField;
