import React from 'react';
import { Route, Navigate, useParams } from 'react-router-dom';
import { CHECK_STAFF } from "../graphql/User";
import { useQuery } from '@apollo/client';

const ProtectedRouteStaff = ({ element: Component, ...rest }) => {
//   const { myform } = useParams(); 


  const { loading, error, data: isStaffData } = useQuery(CHECK_STAFF, {
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error('not a staff:', error);
    return <Navigate to="/login" replace={true} />;
  }

  const isUserStaff = isStaffData?.checkStaff || false;
  return isUserStaff ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/404" replace={true} />
  );
};

export default ProtectedRouteStaff;
