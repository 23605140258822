import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { isMobile } from 'react-device-detect';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { UPDATE_FIELD } from '../../graphql/Field';

import inlineeditorstyle from '../../css/editor/InlineEditor.module.scss'
import PepperModal from '../../utils/PepperModal';
import MediaGallery from './MediaGallery';



const fieldMapping = {
  // Map the UI field names to the schema field names
  label: 'label',
  value: 'value',
  section: 'section',
  imgsize: 'imgsize',
  link: 'link',
  type: 'type',
  order: 'order',
  required: 'required',
  radiotype: 'radiotype',
  startlegend: 'startlegend',
  endlegend: 'endlegend',
  framedevicestyle: 'framedevicestyle',
  shadowstyle: 'shadowstyle',
  description: 'description',
  category: 'category',
  subfieldtype: 'subfieldtype',
  caption: 'caption',
  heading: 'heading',
  answernote: 'answernote',
  answernoteheading: 'answernoteheading',
};

const Option = ({ props }) => {
  const {field,myform, refetch} = props
  const { register, setValue } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);



  const [updateField] = useMutation(UPDATE_FIELD);

  const handleUpdateRequired = async (id,type) => {

    try {
        const result = await updateField({
            variables: {
                id: id,
                myform: props.myform,
                subfieldtype: type
            }
        });
        props.refetch();
    } catch (error) {
        console.error('Error updating field:', error);
    }
};





const fieldID = props.field ? props.field.id : 'false';
const type = props.field ? props.field.subfieldtype : false;

  return (
    <React.Fragment>

        <li className={`${type === 'radio' ? inlineeditorstyle.selected : ''}`} onClick={()=>handleUpdateRequired(fieldID, 'radio')}>{isMobile? <RadioButtonCheckedIcon /> : 'Single'}</li>
        <li className={`${type === 'options' ? inlineeditorstyle.selected : ''}`} onClick={()=>handleUpdateRequired(fieldID, 'options')}>{isMobile ? <CheckBoxIcon /> : 'Multi'}</li>

    </React.Fragment>
  );
};

export default Option;
