import React, { Component, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import styles from '../css/staff/Staff.module.scss'; // Import the SCSS file
import { useMutation } from '@apollo/client';
import { CREATE_TEMPLATE } from '../graphql/Template';
import AddCategory from './AddCategory';
import CreateTemplate from './CreateTemplate';

const ManageTemplate = () => {
  const { shorthand } = useParams();

  return (
    <div className={`${styles.container}`}>
      Editing template ({shorthand})
      <CreateTemplate shorthand={shorthand} />
    </div>
  );
};

export default ManageTemplate;
