import React, { useEffect } from 'react';
import axios from 'axios';
import { GET_CURRENTUSER, EDIT_SELF } from '../graphql/User';
import { useQuery, useMutation, gql } from '@apollo/client';

const AirtableCallback = () => {
  const clientId = '300a6c2c-9f86-48b2-af51-e41a57604434';
  const redirectUri = 'http://localhost:3000/connect-Airtable';
  const [editSelf] = useMutation(EDIT_SELF);
  const { loading, error, data, refetch } = useQuery(GET_CURRENTUSER);

  useEffect(() => {
    const authorizationCode = new URLSearchParams(window.location.search).get('code');

    if (authorizationCode) {
      console.log('Authorization Code:', authorizationCode);

      // Retrieve the code_challenge from local storage
      const codeChallenge = localStorage.getItem('code_challenge');

      // Make a POST request to exchange the authorization code for an access token
      const tokenExchangeData = {
        grant_type: 'authorization_code',
        code: authorizationCode,
        client_id: clientId,
        redirect_uri: redirectUri,
        code_verifier: codeChallenge, // Use the stored code_challenge
      };

      axios
        .post('https://airtable.com/oauth2/v1/token', tokenExchangeData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((response) => {
            const accessToken = response.data.access_token;
            console.log('Access Token:', accessToken);
            const payload = {
                airtable: accessToken,
              };
          

          editSelf({ variables: payload }).then(() => {
            console.log('Form submitted successfully!');
            // Refetch the user data to get the updated avatar
            refetch();
          })
          .catch((error) => {
            console.error('Error submitting form:', error);
          });


        })
        .catch((error) => {
          console.error('Token Exchange Error:', error);
          // Handle the error as needed
        });
    }
  }, []);

  return (
    <div>
      <h1>Handling Airtable OAuth Callback</h1>
      <h2>and: {data?.currentUser.firstname}</h2>
      <h2>airtable_ID: {data?.currentUser.airtable}</h2>
    </div>
  );
};

export default AirtableCallback;
