import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_ALL_FORM, GET_USER_FORMS } from './graphql/Form';
import { GET_CURRENTUSER } from './graphql/User';


// import FormDetail from './hub/FormDetail';
// import FormList from './hub/FormList';
// import Navigation from './nav/Navigation';
// import CreateFormSteps from './nav/CreateFormSteps'
// import NoFormSelected from './hub/NoFormSelected'
// import Spinner from './utils/Spinner'

// import CreateForm from './nav/CreateForm';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import dashboard from './css/dashboard/dashboard.module.scss';


const FormDetail = React.lazy(() => import('./hub/FormDetail'));
const FormList = React.lazy(() => import('./hub/FormList'));
const Navigation = React.lazy(() => import('./nav/Navigation'));
const CreateFormSteps = React.lazy(() => import('./nav/CreateFormSteps'));
const NoFormSelected = React.lazy(() => import('./hub/NoFormSelected'));
const Spinner = React.lazy(() => import('./utils/Spinner'));
const CreateForm = React.lazy(() => import('./nav/CreateForm'));


const FORMS_PER_PAGE = 10; // Number of forms to load per page

function Homepage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [forms, setForms] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [isDeleteSuccesful, setIsDeleteSuccesful] = useState(null);
  const [totalForms, setTotalForms] = useState(0);
  const [order, setOrder] = useState('created'); // Selected order
  const { myformId } = useParams();
  const navigate = useNavigate();

  const { data, loading, error, refetch: userFormRefetch } = useQuery(GET_USER_FORMS, {
    variables: { page: currentPage, order },
  });
  const { data: currentUserData, loading: currentUserLoading, error: currentUserError } = useQuery(GET_CURRENTUSER);

  const currentUser = currentUserData?.currentUser;
  // useEffect(() => {
  //   // ... (other useEffect logic)
  //   if (isDeleteSuccesful) {
  //     setIsCreateFormStepsVisible(true);
  //   }
  // }, [isDeleteSuccesful]);

  useEffect(() => {
    if (!loading && !error && data && data.userForms && data.userForms.forms) {
      setForms((prevForms) => [...prevForms, ...data.userForms.forms]);
      setTotalForms(data.userForms.totalCount);
      if (!myformId){
        if(data?.userForms.forms.length > 0 && !myformId){
          // setSelectedFormId(data.userForms.forms[0].id);
          // console.log(data.userForms.forms[0].id)
          // console.log(selectedFormId)
          setSelectedFormId(data.userForms.forms[0].id);
          navigate(`/hub/${data.userForms.forms[0].id}`);
        }
        } else if (myformId){
          setSelectedFormId(null);
      }
    }
  }, [data, loading, error]);
  // useEffect(() => {
  //   setForms([]);
  //   setCurrentPage(1);
  //   userFormRefetch(); 
  //   console.log(forms)

  // }, [order]);

  const handleFormClick = (formId) => {
    setSelectedFormId(formId);
    navigate(`/hub/${formId}`);
    setIsDeleteSuccesful(null);
    console.log(forms)
  };

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    setIsDeleteSuccesful(null)
  };

  const handleFormDelete  = () => {
    setForms([]);
    userFormRefetch(); 
    setCurrentPage(1);
    setSelectedFormId(null);
    setIsDeleteSuccesful(true)
    // refetch(); // Manually trigger a refetch when the order changes
    // window.location.href = '/hub'
  }

  const handleOrderChange = (selectedOrder) => {
    setOrder(selectedOrder);
    setIsDeleteSuccesful(null);
    userFormRefetch(); 
    setCurrentPage(1); // Reset the current page when the order changes
    setForms([]); // Clear the forms when the order changes
  };

  const getGreeting = () => {
    const currentHour = new Date().getHours();
  
    if (currentHour >= 5 && currentHour < 12) {
      return 'Good morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  }

  const isLoadMoreVisible = currentPage * FORMS_PER_PAGE < totalForms;
  if (currentUserLoading) {
    return <div>
        <Suspense fallback={<div></div>}><Spinner /></Suspense>
      </div>;
  }

  if (currentUserError) {
    return <div>Error occurred: {error.message}</div>;
  }

  const greeting = getGreeting(); 

  return (
    <div className={dashboard.page}>
      <Suspense fallback={<div></div>}><Navigation/></Suspense>
      <div className={dashboard.container}>
        <section className={dashboard.form_select}>
          <h1>
           {greeting}
            <br />
            {currentUserData?.currentUser?.firstname}.
          </h1>
          <div className={dashboard.subheader}>
          {!loading && forms.length > 0 ?
          <div>
          <div className={dashboard.menu__createform}><Suspense fallback={<div></div>}><CreateForm></CreateForm></Suspense></div>

          <div className={dashboard.dropdown}>
            <label>Order by</label>
            <select  value={order} onChange={(e) => handleOrderChange(e.target.value)}>
              <option value="created_at">Created Date</option>
              <option value="name">Name</option>
              {/* <option value="updated_at">Updated Date</option> */}
            </select>
            <div className={dashboard.dropdown_arrow}><ArrowDropDownIcon className="dropdown-icon" /></div>
          </div></div>:''}
          </div>
          <Suspense fallback={<div></div>}><FormList forms={forms} handleFormClick={handleFormClick} /></Suspense>
          {!loading && forms.length > 0 && isLoadMoreVisible && (
            <a className={dashboard.load_more} onClick={handleLoadMore}>Load More</a>
          )}
          {!loading && forms.length === 0 && ''}
          {!loading && forms.length > 0 && !isLoadMoreVisible && (
            // <p>No more forms to load.</p>
            ''
          )}
        </section>
        <section className={dashboard.hub}>
          {isDeleteSuccesful ?
            <Suspense fallback={<div></div>}><NoFormSelected /></Suspense>
            : 
            <>
              {selectedFormId ? 
              <Suspense fallback={<div></div>}><FormDetail 
              handleFormDelete={handleFormDelete} 
              formId={selectedFormId}
              /></Suspense> : myformId ?
              <Suspense fallback={<div></div>}><FormDetail 
              handleFormDelete={handleFormDelete} 
              formId={myformId}
                /> </Suspense>:
                <Suspense fallback={<div></div>}><CreateFormSteps /></Suspense>
              }
          </>
        }
        </section>
      </div>
    </div>
  );
}

export default Homepage;
