import React, { useRef, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { isDesktop } from 'react-device-detect';

import inlineeditorstyle from '../../css/editor/InlineEditor.module.scss'
import { UPDATE_STYLE } from '../../graphql/Style';
import {  DELETE_FILE } from '../../graphql/Images';

import UpdateLogoPosition from './UpdateLogoPosition';




const Logo = ({ props }) => {

  const [updateStyle] = useMutation(UPDATE_STYLE);
  const [deleteFile] = useMutation(DELETE_FILE);

  const [isOpenEditField, setIsOpenEditField] = useState(false);
  const editFieldDivRef = useRef(null);

  const removeLogo = async (event) => {
    console.log(props.formStyle.logo)
    try {
        const deleteImage = await deleteFile({
          variables:{
            pictureUrl: props.formStyle.logo
          }
        }).then(async()=>{
          const result = await updateStyle({
            variables: {
                myform: props.myform,
                logo: null,
            },
        });

        props.stylerefetch();
        console.log('logo removed')
        })

    } catch (error) {
        console.error('Error updating field:', error);
    }
  };


  useEffect(() => {
    const handleClickOutsideEditField = (event) => {
      // Check if the ref element exists and if the event target is outside the ref element
      if (editFieldDivRef.current && !editFieldDivRef.current.contains(event.target)) {
        setIsOpenEditField(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideEditField);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEditField);
    };
  }, []);


 


  return (
    <React.Fragment>
      {isDesktop ? <li className={`${inlineeditorstyle.inline_sub_section}`} ref={editFieldDivRef}>
        <header onClick={() => setIsOpenEditField(!isOpenEditField)}>Position</header>
        {isOpenEditField && <UpdateLogoPosition props={props}/>}
      </li> : <UpdateLogoPosition props={props}/>}
      
      {props.formStyle.logo && <li onClick={removeLogo}>Remove</li>}
    </React.Fragment>
  );
};

export default Logo;
