import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { UPDATE_STYLE } from '../../graphql/Style';
import { UPDATE_FIELD } from '../../graphql/Field';


import inlineeditorstyle from '../../css/editor/InlineEditor.module.scss'

const lists = [{ name: 'left' }, { name: 'right' }]

const UpdateFieldImagePosition = ({ props}) => {

    const [value, setValue] = useState(props.field.heading ? props.field.heading : 'left');
    const [updateField] = useMutation(UPDATE_FIELD);

    const handleUpdate = async (event) => {
        const style = event.target.value;
        setValue(style)
    
        try {

            const result = await updateField({
                variables: {
                    id: props.field.id,
                    myform: props.myform,
                    heading: style
                }
            });
            console.log(result)
            props.refetch();

    

        } catch (error) {
            console.error('Error updating field:', error);
        }
      };

  
    

    return (
        <div className={`${inlineeditorstyle.inline_sub_section_item} ${inlineeditorstyle.image_position}`}>
            <header>Select position</header>
            <div className={`${inlineeditorstyle.inline_sub_section_item_choice}`}>
                <header>Position</header>
                <select value={value} onChange={handleUpdate}>
                    {lists.map((list, index) => (
                        <option key={index} value={list.name}>
                            {list.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};
export default UpdateFieldImagePosition;

