import React, { useState, useEffect } from 'react';


const HTMLVideoRender = ({ url, key }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const handleLoadedData = () => {
        // Video data has been loaded (enough data to start playing)
        setIsLoading(false);
      };
    
      const handleLoadedMetadata = () => {
        // Video's metadata (e.g., duration, dimensions) has been loaded
        // This can also be considered as an indication of the video being loaded
        setIsLoading(false);
      };
      const handleError = () => {
        // Error occurred while loading the video
        setIsLoading(false);
        setIsError(true);
      };
    
  
    return (
        <React.Fragment>
               {isLoading && <p>Loading...</p>}
          {isError && <p>Error loading the video.</p>}
        <video
           key={key? key : url}
            autoPlay
            muted
            loop
            playsInline
            onLoadedData={handleLoadedData}
            onLoadedMetadata={handleLoadedMetadata}
            onError={handleError}
          >
            <source src={url} type="video/mp4" />
          </video>
          </React.Fragment>
    );
};
export default HTMLVideoRender;

