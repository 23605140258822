import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import debounce from 'lodash.debounce';
import { isDesktop } from 'react-device-detect';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import LockIcon from '@mui/icons-material/Lock';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


import SubmitButtonPrefixes from '../../reusablefields/SubmitButtonPrefixes';
import { UPDATE_STYLE } from '../../graphql/Style';


import inlineeditorstyle from '../../css/editor/InlineEditor.module.scss'

import blockstyle from '../../css/Block.module.scss'

const radioOptions = [{ name: 'none' }, { name: 'lock' }, { name: 'clock' }, { name: 'video' }, { name: 'pdf' }]

const SubmitButtonPrefix = ({ props, setIsOpenEditField }) => {
    const { register, setValue } = useForm();
    
    const [updateStyle] = useMutation(UPDATE_STYLE);
    const [isShowFilter, setShowFilter] = useState(false);
    const myform = props.myform;
    const stylerefetch = props.stylerefetch;
    const [value, setStateValue] = useState();


    const handleFieldChangePrefix = debounce(async (name, value) => {
        const id = myform;
        await setValue(name, value);
        console.log(value)

        const result = await updateStyle({
            variables: {
                myform: myform,
                buttonprefix: value
            },
        });
        stylerefetch();
        setIsOpenEditField(false)
    }, 300); // Set the debounce delay (e.g., 300 milliseconds)

    
    const handleFieldUpdateMobile = async (event) => {
        const style = event.target.value;
        setStateValue(style);
    
        try {
          const result = await updateStyle({
            variables: {
              myform: props.myform,
              buttonprefix: style
            }
          });
    
          stylerefetch();
          setShowFilter(false);
        } catch (error) {
          console.error('Error updating field:', error);
        }
      };

    const onInputChangePrefix = (e, index) => {
        const { name, value } = e.target;
        // Check if the input value exceeds the character limit (less than 25)
        handleFieldChangePrefix(name, value);
        console.log(value);
    };


    return (
        <React.Fragment>
        {isDesktop && <div className={`${inlineeditorstyle.inline_sub_section_item} ${inlineeditorstyle.submit_btn_prefixes} ${blockstyle.submit_btn_prefix}`}>
            <header>Select prefix</header>
            <div className={`${inlineeditorstyle.inline_sub_section_item_choice} `}>
                {radioOptions.map((option, index) => (
                    <label key={index} className={inlineeditorstyle.custom_radio}>
                        <input
                            type="radio"
                            name={`buttonprefix-${index}`}
                            value={option.name}
        
                            {...register(`buttonprefix-${index}`)}
                            checked={props.formStyle.buttonprefix === option.name}
                            onChange={(e) => onInputChangePrefix(e, index)}
                        />
                        <div className={inlineeditorstyle.radio_fake_input} >
                            <SubmitButtonPrefixes prefix={option.name}/>

                            {/* <span>{option.name}</span> */}
                        </div>
                    </label>
                ))}
            </div>
        </div>}
        {!isDesktop &&  <li className={`${inlineeditorstyle.inline_sub_section_item_mobile}`}>
          <span onClick={() => setShowFilter(!isShowFilter)}>Prefix</span>
          {isShowFilter && (
            <div className={`${inlineeditorstyle.inline_sub_section_item_mobile_dropdown}`}>
              <select value={value} onChange={handleFieldUpdateMobile}>
                {radioOptions.map((list, index) => (
                  <option key={index} value={list.name}>
                    {list.name}
                  </option>
                ))}
              </select>
              <ArrowDropDownIcon />
            </div>
          )}
        </li>}
        </React.Fragment>
    );
};
export default SubmitButtonPrefix;

