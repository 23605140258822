import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import QuestionType from '../Definitions/QuestionType';
import {
    IconTextAnswer, IconMultichoice, IconScale, IconDate, IconTypeImage, IconMedia, IconMultichoiceEmoji, IconMultichoiceImage, IconMultichoiceSession,
    IconDropdown, IconScaleRate, IconScaleNPS, IconTypeAgenda, IconTypeBulletPoints, IconTypeContact, IconTypeHeroTextBlock, IconTypeLogoStack, IconTypeProfile,
    IconTypeSocialProof, IconTermsAndConditions, IconTypeTextBlock, IconTypeTestimonials, IconTypeVouchers, IconTypeMockup, IconTypeVideo, IconTypePDF
} from '../SVG/Fields'
import { IconShortAnswer, IconLongAnswer, IconSignature, IconNumber, IconFullName, IconTelephone, IconEmail, IconAddress, IconWebAddress, IconQuestionTypeDropdown, IconMultichoiceGeneric, IconQuestionTypeDate } from '../SVG/Fields'
import SearchIcon from '@mui/icons-material/Search';

import { BlockName, BlockEmail, BlockNumber, BlockPhone, BlockWebsite, BlockShortAnswer, BlockLongAnswer, BlockDate, BlockInlineMC, BlockDropdownMC, BlockSliderMC, BlockScale, BlockRating, BlockSignature, BlockUploadFile, BlockHeroText, BlockParagraphText, BlockBulletPonts, BlockCards, BlockContactDetails, BlockTOC, BlockVoucher, BlockImage, BlockVideo, BlockPDF } from '../Navigation/ItemSVG'


import inlineeditorstyle from '../../css/editor/InlineEditor.module.scss';
import { UPDATE_FIELD } from '../../graphql/Field';


const categoryNames = {
    input: 'Text Answer',
    option: 'Multiple Choice',
    scale: 'Rating Scale',
    uploads: 'Uploads',
    textblock: 'Decorative Text',
    media: 'Media & Images',
    interactive: 'Interactive'
    // Add more categories and their renamed names as needed
};

const RenderFieldIcon = ({ icon, question }) => {
    // Mapping between icon types and SVG components
    const iconMap = {
        input: BlockShortAnswer,
        longAnswer: BlockLongAnswer,
        number: BlockNumber,
        tel: BlockPhone,
        email: BlockEmail,
        url: BlockWebsite,
        name: BlockName,
        signature: BlockSignature,
        'image-input': BlockUploadFile,
        'optionBox': BlockInlineMC,
        'blockOuterLabel': IconMultichoiceEmoji,
        'pictureBox': IconMultichoiceImage,
        'session': IconMultichoiceSession,
        slideroption: BlockSliderMC,
        dropdown: BlockDropdownMC,
        nps: BlockScale,
        rate: BlockRating,
        date: BlockDate,
        agenda: IconTypeAgenda,
        bulletpoint: BlockBulletPonts,
        contact: BlockContactDetails,
        herotext: BlockHeroText,
        logostack: BlockCards,
        profile: IconTypeProfile,
        socialproof: IconTypeSocialProof,
        'social-media-share': IconTypeSocialProof,
        legal: BlockTOC,
        textblock: BlockParagraphText,
        testimonials: IconTypeTestimonials,
        voucher: BlockVoucher,
        image: BlockImage,
        mockup: IconTypeMockup,
        video: BlockVideo,
        pdf: BlockPDF

    };


    const IconComponent = iconMap[icon]; // Get the corresponding SVG component based on the icon type

    return (
        <span >
            {IconComponent && <IconComponent />} {/* Render the SVG component */}
        </span>
    );
};

const fieldMapping = {
    // Map the UI field names to the schema field names
    label: 'label',
    value: 'value',
    section: 'section',
    imgsize: 'imgsize',
    link: 'link',
    type: 'type',
    order: 'order',
    required: 'required',
    radiotype: 'radiotype',
    startlegend: 'startlegend',
    endlegend: 'endlegend',
    framedevicestyle: 'framedevicestyle',
    shadowstyle: 'shadowstyle',
    description: 'description',
    category: 'category',
    subfieldtype: 'subfieldtype',
    caption: 'caption',
    heading: 'heading',
    answernote: 'answernote',
    answernoteheading: 'answernoteheading',
};

const UpdateField = ({ props, setIsOpenEditField, fieldcategory }) => {

    const { field, myform, refetch } = props;
    const { register, setValue } = useForm();
    const [updateField] = useMutation(UPDATE_FIELD);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectCategory] = useState('');
    const [selectedcategorygroup, setSelectedCategoryGroup] = useState('questions')


    const handleUpdate = async (selectedField, refetch, selectedmyform, fieldDefinition, section) => {

        try {
            const result = await updateField({
                variables: {
                    id: selectedField.id,
                    myform: selectedmyform,
                    type: fieldDefinition.type,
                    category: fieldDefinition.category,
                    radiotype: fieldDefinition.radiotype,
                    subfieldtype: fieldDefinition.subfieldtype,
                    readwritepermission: fieldDefinition.readwritepermission,
                    descriptiontoggle: fieldDefinition.descriptiontoggle ? fieldDefinition.descriptiontoggle : false,


                },
            });
            refetch();
            setIsOpenEditField(false);
        } catch (error) {
            console.error('Error updating field:', error);
        }
    };

    const getDisplayedCategoryName = (category) => {
        return categoryNames[category.category] || category.categoryName;
    };
    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };


    const filteredQuestionTypes = QuestionType.filter((category) =>
        category.selection.some((question) =>
            question.label.toLowerCase().includes(searchTerm) &&
            (selectedCategory ? question.category === selectedCategory : question.categorygroup === selectedcategorygroup)
        )
    );




    useEffect(() => {

        if (field) {
            switch (field.category) {
                case 'input':
                    return setSelectedCategoryGroup('questions');
                case 'option':
                    return setSelectedCategoryGroup('questions');
                case 'scale':
                    return setSelectedCategoryGroup('questions');
                case 'media':
                    return setSelectedCategoryGroup('media');
                case 'textblock':
                    return setSelectedCategoryGroup('text');
                default:
                    return setSelectedCategoryGroup('questions');
            }
        }

    }, []);
    // const section = "body"

    return (
        <React.Fragment>
            <div className={inlineeditorstyle.edit_field_inner_container} >
                <div className={inlineeditorstyle.update_field_search}>
                    <SearchIcon />
                    <input type="text" onChange={handleSearch} placeholder="Search Question Type" />
                </div>

                {filteredQuestionTypes.map((category) => (
                    <React.Fragment key={category.category}>

                        <ul className={inlineeditorstyle.update_field_items} >
                            {category.selection
                                .filter(
                                    (question) =>
                                        question.label.toLowerCase().includes(searchTerm) &&
                                        (question.categorygroup === selectedcategorygroup)
                                ).length > 0 &&
                                <header >
                                    <h1>{getDisplayedCategoryName(category)}</h1>
                                </header>}
                            {category.selection
                                .filter(
                                    (question) =>
                                        question.label.toLowerCase().includes(searchTerm) &&
                                        (question.categorygroup === selectedcategorygroup)
                                )
                                .map((question) => (
                                    <li key={question.label}
                                        onClick={() => handleUpdate(field, refetch, myform, question, 'section')}
                                    >
                                        <RenderFieldIcon icon={question.category === 'option' || question.category === 'scale' ? question.radiotype : question.type} question={question} />
                                        <span>{question.label}</span>

                                    </li>
                                ))}
                        </ul>
                    </React.Fragment>
                ))}
            </div>
        </React.Fragment>
    );
};

export default UpdateField;
