import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import template from '../css/template/Template.module.scss';
import Navigation from '../nav/Navigation';
import TemplateSearchAdmin from './TemplateSearchAdmin';
import CreateTemplate from './CreateTemplate';
import descriptionData from '../template/descriptionData.json'; // Import the JSON file
import staffstyle from '../css/staff/Staff.module.scss';

function Templates() {
  // State to control the visibility of the CreateTemplate component
  const [isCreateTemplateVisible, setCreateTemplateVisible] = useState(false);
  const [targetTemplate, setTargetTemplate] = useState('');
  const handleCreateClick = async (template) =>{
    setCreateTemplateVisible(!isCreateTemplateVisible)
    setTargetTemplate(null)
  }
  return (
    <div className={`${template.page} ${staffstyle.pageAdmin}`}>
      <Helmet>
        <title>Survey Form Templates - Pepperform</title>
        <link rel="canonical" href="https://app.pepperform.net/templates" />
        <meta name="description" content="Discover a collection of free survey form templates for anything you need. Elevate your data collection with Pepperform's user-friendly online survey builder tool." />
        <meta property="og:title" content="Explore Free Survey Form Templates with Pepperform" />
        <meta property="og:description" content="Access a variety of free survey form templates powered by Pepperform. Create and customize surveys for different purposes using our free online survey builder tool." />
        <meta name="keywords" content="survey forms, form templates, online surveys, data collection, event registration" />
        <meta name="author" content="The Pepperform Product Team" />
        <meta name="language" content="en" />
      </Helmet>
      {/* <Navigation /> */}
      <div className={template.template_title}>
        <p>STAFF ONLY</p>
        <h1>Template Admin</h1>
        <span className={staffstyle.button} onClick={()=>handleCreateClick()}>Create new template</span>
      </div>
      <div className={template.search_area}>
        <h2 className={template.template_subtitle}>Search for a Template</h2>
        <TemplateSearchAdmin setTargetTemplate={setTargetTemplate} setCreateTemplateVisible={setCreateTemplateVisible}></TemplateSearchAdmin>
      </div>
      
      {/* Conditional rendering for the CreateTemplate component */}
      {isCreateTemplateVisible && (
        <div className={staffstyle.sidebar}>
        <div className={staffstyle.sidebarclosebutton} onClick={()=>setCreateTemplateVisible(!isCreateTemplateVisible)}>
            CLOSE
        </div>
        <CreateTemplate shorthand={targetTemplate} />
        </div>
      )}
    </div>
  );
}

export default Templates;
