const QuestionType = [
    {
        category: 'input',section:'questions', selection: [
            { label:'Email',icon:'<IconEmail />',type: 'email', category: 'input', subfieldtype:'input', categorygroup: 'questions', tag:'', readwritepermission:"readwrite" },
            { label:'date',icon:'IconQuestionTypeDate',type: 'date', category: 'input', radiotype: 'date', subfieldtype: 'date', categorygroup: 'questions', tag:'' , readwritepermission:"readwrite"},
            { label:'Full Name',icon:'<IconShortAnswer />',type: 'name', category: 'input', subfieldtype:'input', categorygroup: 'questions', tag:'full name, name, identifier' , readwritepermission:"readwrite"},
            { label:'Long Answer',icon:'<IconLongAnswer />',type: 'longAnswer', category: 'input', subfieldtype:'input', categorygroup: 'questions', tag:'textarea', readwritepermission:"readwrite" },
            { label:'Number',icon:'<IconNumber />',type: 'number', category: 'input', subfieldtype:'input', categorygroup: 'questions', tag:'', readwritepermission:"readwrite" },
            { label:'Short Answer',icon:'<IconShortAnswer />',type: 'input', category: 'input', subfieldtype:'input', categorygroup: 'questions', tag:'input' , readwritepermission:"readwrite"},
            { label:'Telephone',icon:'<IconTelephone />',type: 'tel', category: 'input', subfieldtype:'input', categorygroup: 'questions', tag:'mobile cell' , readwritepermission:"readwrite"},
            { label:'Website Address',icon:'<IconWebAddress />',type: 'url', category: 'input', subfieldtype:'input', categorygroup: 'questions' , tag:'', readwritepermission:"readwrite" },
        ]
    },
    {
        category: 'option', section:'questions', selection: [
            { label:'Multiple choice ',icon:'<IconMultiQuestion />', type: 'multichoice', category: 'option', radiotype: 'optionBox', subfieldtype: 'radio', categorygroup: 'questions' , tag:'', readwritepermission:"readwrite" },
            { label:'Slider multiple choice',icon:'<IconSessions />',type: 'multichoice', category: 'option', radiotype: 'slideroption', subfieldtype: 'radio', categorygroup: 'questions', tag:'slider side by side questions' , readwritepermission:"readwrite"},

        ]
    },
    {
        category: 'scale', section:'questions', selection: [
            { label:'Scale (NPS)',icon:'<IconScaleNPS />',type: 'nps', category: 'scale', radiotype: 'nps', subfieldtype: 'nps', categorygroup: 'questions', tag:'' , readwritepermission:"readwrite"},
            { label:'Rate',icon:'<IconScaleRate />',type: 'scale', category: 'scale', radiotype: 'rate', subfieldtype: 'rate', categorygroup: 'questions', tag:'', readwritepermission:"readwrite" }
        ]
    },
    {
        category: 'uploads', section:'questions', selection: [
            { label:'Signature',icon:'<IconSignature />',type: 'signature', category: 'upload', subfieldtype:'input', categorygroup: 'questions', tag:'signature sign' , readwritepermission:"readwrite"},
            { label:'Image uploader',icon:'<IconImageInput />',type: 'image-input', category: 'upload', subfieldtype:'input', categorygroup: 'questions', tag:'image img input' , readwritepermission:"readwrite"},
            { label:'Document uploader',icon:'<IconImageInput />',type: 'file-input', category: 'upload', subfieldtype:'input', categorygroup: 'questions', tag:'document img input' , readwritepermission:"readwrite"},

        ]
    },



    {
        category: 'media', section:'media', selection: [
            { label:'Image',title:'Image',icon:'',type: 'image', category: 'media', subfieldtype:'', categorygroup: 'media', readwritepermission:'read', imgsize:'original', valuetoggle: false },
            { label:'Video',title:'Video',icon:'',type: 'video', category: 'media', subfieldtype:'', categorygroup: 'media', readwritepermission:'read', valuetoggle: false },
            { label:'PDF' ,icon:'',type: 'pdf', category: 'media', subfieldtype:'', categorygroup: 'media', readwritepermission:'read', valuetoggle: false },
        ]
    },
    {
        category: 'textblock', section:'text',  selection: [

            { label:'Hero Text',icon:'',type: 'herotext', category: 'textblock', radiotype:'', categorygroup: 'text' , readwritepermission:"read", descriptiontoggle: false, tag:'title header heading '},
            { label:'Paragraph Text',icon:'',type: 'textblock', category: 'textblock', radiotype:'', categorygroup: 'text' , readwritepermission:"read" , descriptiontoggle: true, tag:'link description title text input'},
            { label:'Bullet Points',icon:'',type: 'bulletpoint', category: 'textblock', radiotype:'bulletpoint', subfieldtype:'dots', categorygroup: 'text', readwritepermission:"read"  , tag:''},
            { label:'Logo Stack',icon:'',type: 'logostack', category: 'textblock', radiotype:'', categorygroup: 'text' , readwritepermission:"read" , tag:''},

        ]
    },
    {
        category: 'interactive', section:'text',  selection: [
            { label:'Contact',icon:'',type: 'contact', category: 'textblock', radiotype:'', categorygroup: 'text', readwritepermission:"read" , tag:'' },
            { label:'Terms & Conditions',icon:'',type: 'legal', category: 'textblock', radiotype:'', categorygroup: 'text', readwritepermission:"read" , tag:'', descriptiontoggle: true, linktoggle: false},
            { label:'Voucher',icon:'',type: 'voucher', category: 'textblock', radiotype:'', categorygroup: 'text', readwritepermission:"read"  , tag:''},

        ]
    },
    {
        category: 'smarts', section:'logic',  selection: [
            { label:'Multichoice Smart skip ',icon:'<IconMultiQuestion />', type: 'smartpath', category: 'logic', radiotype: 'optionBox', subfieldtype: 'radio', categorygroup: 'logic' , tag:'', readwritepermission:"readwrite" },

        ]
    }
]

export default QuestionType;