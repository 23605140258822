import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_GOOGLE_CALENDARS, GET_GOOGLE_CALENDAR_EVENTS } from '../graphql/Field'; 
import { UPDATE_FORM } from '../graphql/Form';
import AvailableTimeSlots from './AvailableTimeSlots';

function CalendarOAuth() {
  const [availableEvents, setAvailableEvents] = useState(null);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [authorizationCode, setAuthorizationCode] = useState(null);
  const [selectedFormID, setSelectedFormID] = useState('f59ad710-6bcc-11ee-b414-07860c07e67f');
  const [updateForm] = useMutation(UPDATE_FORM);
  const { data: calendarEventsData, loading: calendarEventsLoading, error: calendarEventsError, refetch: refetchEvents } = useQuery(GET_GOOGLE_CALENDAR_EVENTS, {
    variables: { calendar: selectedCalendar, myform: selectedFormID, 
      startTime:'2023-10-17T06:16:23+0000',
      endTime: '2023-10-18T06:16:23+0000', }, // Need Sally's help to generate date in this value
      skip: !selectedCalendar, 
  });
  const { data: calendarData, loading: calendarLoading, error: calendarError, refetch } = useQuery(GET_GOOGLE_CALENDARS, {
    variables: { code: authorizationCode }, // Use the authorization code as a variable
    // skip: !authorizationCode, 
  });


  // 1. Handle the OAuth flow for Google Calendar
  const handleGoogleOAuth = () => {
    // Redirect the user to the Google OAuth authorization page
    // Replace these placeholders with your actual OAuth settings
    const googleClientId = '1074200960005-qnk94qcn8ssrlmk8dj6ndhq5s56j1c2g.apps.googleusercontent.com';
    const googleRedirectUri = 'http://localhost:3000/calendarOAuth';
    const googleScope = 'https://www.googleapis.com/auth/calendar';
    const authUrl = `https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=${googleClientId}&redirect_uri=${googleRedirectUri}&scope=${googleScope}&response_type=code`;
    window.location.href = authUrl;
  };

  // Function to extract and set the authorization code from the URL
  const getAuthorizationCodeFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      setAuthorizationCode(code);
    }
  };

//   Call the function to get the authorization code when the component mounts
  useEffect(() => {
    getAuthorizationCodeFromURL();
  }, []);

  // Trigger the query when the "Fetch Calendars" button is clicked
  const handleFetchCalendars = () => {
    refetch({ code: authorizationCode });
  };
  
  // Select and perserve Calendar on DB
  const handleSelectCalendar = (calendar) => {
    console.log(calendar)
    updateForm({
        variables: {
            calendarID: calendar.id,
            id:selectedFormID,
        },
    })
    setSelectedCalendar(calendar.id)

  };
  // find the events for calendar
  const fetchEvents = (selectedCalendar) => {
    setAvailableEvents(calendarEventsData)
    if (availableEvents){
        refetchEvents()
        setAvailableEvents(calendarEventsData)
    }
  }
  return (
    <div>
      <button onClick={handleGoogleOAuth}>Connect Google Calendar</button>
      <button onClick={handleFetchCalendars}>Fetch Calendars</button>
      <p>Select a Google Calendar to integrate:</p>
      <ul>
        {calendarData?.getGoogleCalendars.map((calendar) => (
          <li key={calendar.id}>
            {/* <button onClick={() => setSelectedCalendar(calendar)}>Integrate {calendar.summary}</button> */}
            <button onClick={()=>handleSelectCalendar(calendar)}>Integrate {calendar.summary}</button>
          </li>
        ))}
      </ul>
      {selectedCalendar && (
        <div>
          <p>Selected Calendar: {selectedCalendar.summary}</p>
          <button onClick={()=>fetchEvents(selectedCalendar)}>Fetch Events for {selectedCalendar}</button>
          {calendarEventsData && <>
          <AvailableTimeSlots calendarEvents={calendarEventsData} />
          {/* {console.log(calendarEventsData)} */}
          </>
          }
          </div>
      )}
    </div>
  );
}

export default CalendarOAuth;
