import React, { useState, useEffect, useRef, useCallback, lazy, Suspense } from 'react';
import { useForm } from 'react-hook-form';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
// import { arrayMove } from 'react-sortable-hoc';
import { arrayMoveImmutable as arrayMove } from 'array-move';

import debounce from 'lodash.debounce';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Element, scroller } from 'react-scroll';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { isDesktop } from 'react-device-detect';

import { GET_FIELDS, UPDATE_FIELD, REORDER_FIELDS, CREATE_NEW_FIELD, REMOVE_FIELD, DUPLICATE_FIELD, REMOVE_SMART_FIELDS_IN_A_PATH } from '../graphql/Field';
import { GET_STYLE } from '../graphql/Style';
import { GET_FORM } from '../graphql/Form';
import { CHECK_MEMBERSHIP } from '../graphql/Member';
import { GET_OPTION } from '../graphql/Option';

// import Field from './Field';
// import Block from './Navigation/Block'
// import ShareModal from './Navigation/ShareModal'
// import AddLogo from './Field/AddLogo';
// import FieldCaption from './FieldCaption';

import pagestyle from '../css/editor/Page.module.scss';
import navstyle from '../css/editor/Navigation.module.scss';
import blockstyle from '../css/Block.module.scss';
import fieldnavstyle from '../css/editor/FieldNavigation.module.scss';
import RenderFieldIcon from './ReusableComponents/RenderFieldIcon';

import EditSubmitButton from './Field/EditSubmitButton';
import RemoveField from './RemoveField';

import PoweredByPepperForm from '../reusablefields/PoweredByPepperForm';
import MetaHelmet from '../utils/MetaHelmet';
import EmptyStateNoField from './ReusableComponents/EmptyStateNoField';
// import SmartPathNav from './Inside/SmartPathNav';

const BackgroundMediaImage = lazy(() => import('../reusablefields/BackgroundMediaImage'));
const BackgroundMedia = lazy(() => import('../reusablefields/BackgroundMedia'));

const Field = lazy(() => import('./Field'));
const Block = lazy(() => import('./Navigation/Block'));
const ShareModal = lazy(() => import('./Navigation/ShareModal'));
const AddLogo = lazy(() => import('./Field/AddLogo'));
const FieldCaption = lazy(() => import('./FieldCaption'));
const SmartPathNav = lazy(() => import('./Inside/SmartPathNav'));

var contrast = require('contrast');
const editorsections = [
  { name: 'body', title: 'Survey Section' },
  { name: 'thankyou', title: 'Thank you section' }
];

const Editor = () => {
  const dragTimeoutRef = useRef(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [openCreateField, setOpenCreateField] = useState(null);
  const [formOrder, setFormOrder] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [activeSection, setActiveSection] = useState('body');
  const [showContent, setShowContent] = useState(false); // Track visibility of content
  const { control, handleSubmit } = useForm();
  const [isInlineOpenHOC, setInlineOpenHOC] = useState(null);
  const [hasSmartPath, setHasSmartPath] = useState(false);
  const [isSmartPathOpen, setIsSmartPathOpen] = useState(false);
  const [currentParentField, setCurrentParentField] = useState(null);
  const [currentSubParentField, setCurrentSubParentField] = useState(null);
  const [currentSelectedPath, setCurrentSelectedPath] = useState(null);
  const [isPathModeOpen, setIsPathModeOpen] = useState(false);





  const [fieldInteractionStatus, setFieldInteractionStatus] = useState(null);
  const [isFieldInteractionStatusOpen, setisFieldInteractionStatusOpen] = useState(false);

  const boundingContainerRef = useRef();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );
  const { myform } = useParams();
  const {
    data: form,
    loading: formloading,
    error: formerror,
    refetch: formrefetch
  } = useQuery(GET_FORM, {
    variables: {
      id: myform // Replace with the actual form ID
    }
  });

  const { data, loading, error, refetch } = useQuery(GET_FIELDS, {
    variables: {
      myform: myform,
      section: activeSection
    }
  });

  

  const {
    data: style,
    loading: styleloading,
    error: styleerror,
    refetch: stylerefetch
  } = useQuery(GET_STYLE, {
    variables: {
      myform: myform // Replace with the actual form ID
    }
  });

  const {
    loading: membershipLoading,
    error: membershipError,
    data: membershipData
  } = useQuery(CHECK_MEMBERSHIP, {
    variables: { myform: myform }
  });

  const [fieldOptions, { data: optionsdata, loading: optionsloading, error: optionserror, refetch: optionlazyqueryrefetch}] = useLazyQuery(GET_OPTION);


  
  const [reorderField] = useMutation(REORDER_FIELDS);
  const [updateField] = useMutation(UPDATE_FIELD);
  const [createField] = useMutation(CREATE_NEW_FIELD);
  const [duplicateField] = useMutation(DUPLICATE_FIELD);
  const [removeField] = useMutation(REMOVE_FIELD);
  const [removeFieldsInAPath] = useMutation(REMOVE_SMART_FIELDS_IN_A_PATH);

  const onSubmit = (data, formName) => {
    console.log(`Form ${formName} submitted:`, data);
  };

  const debouncedSubmit = debounce((data, formName) => {
    onSubmit(data, formName);
  }, 300); // Set the debounce delay to 300 milliseconds (adjust as needed)

  const handleFormSubmit = (data, formName) => {
    onSubmit(data, formName);
  };

  const fieldRefs = useRef({});

  const handleChange = (order, id, location, optionid) => {
    setCurrentIndex(order);
    setShowContent(true); // Show the content
    const fieldID = formOrder[order];
    const fieldRef = fieldRefs.current[fieldID];
    
    optionid && setCurrentSubParentField(optionid)
    setInlineOpenHOC(id);
    isDesktop &&
      scroller.scrollTo(fieldID, {
        // Use the field ID as the target element
        top: 0,
        duration: 500,
        smooth: 'easeInOutQuart',
        offset: -100 // Adjust the offset as needed to properly position the content
      });

    // isInlineOpenHOC !== id ?  setInlineOpenHOC(id) : setInlineOpenHOC(null)
  };

  const openingBlockFromEditor = (sectionName) => {
    const newBlock = openCreateField === 'blocks' && activeSection === sectionName ? null : 'blocks';
    setOpenCreateField(newBlock);
    setActiveSection(sectionName);
  };
  

  const handleDuplicateField = async (field) => {
    console.log(field)
    try {
      setFieldStatus('Duplicate Field', true);
      const result = await duplicateField({
        variables: {
          myform: myform,
          section: activeSection,
          type: field.type,
          order: field.order + 1,
          label: field.label,
          required: field.required,
          category: field.category,
          radiotype: field.radiotype,
          subfieldtype: field.subfieldtype,
          readwritepermission: field.readwritepermission,
          descriptiontoggle: field.descriptiontoggle ? field.descriptiontoggle : false,
          framedevicestyle: field.framedevicestyle ? field.framedevicestyle : null,
          value: field.value ? field.value : null,
          headingtoggle: field.headingtoggle,
          linktoggle: field.type === 'textblock' ? true : false,
          valuetoggle: field.valuetoggle,
          imgsize: field.imgsize ? field.imgsize : 'original',
          startlegend: field.startlegend,
          endlegend: field.endlegend,
          oldfield: field.id,
          description: field.description,
          prefix: field.prefix,
          heading: field.heading,
          relatedFieldID: field.relatedFieldID,
          pathID: field.pathID
        }
      });

      await refetch();
      result && setFieldStatus('Duplicate Field', false);
      // setInlineOpenHOC(result ? result.data.createField.id : '')
      result && setCurrentIndex(result.data.duplicateField.order);
      result && setInlineOpenHOC(result.data.duplicateField.id);
    } catch (error) {
      console.error('Error creating new field:', error);
    }
  };
  
  
  const handleRemoveFielsInAPath = async (pathID, relatedFieldID) => {
    try {
      setFieldStatus('Remove Fields in a path', true);
      const result = await removeFieldsInAPath({
        variables: {
          relatedFieldID: relatedFieldID,
          pathID: pathID
        }
      });


      await refetch();
      
      const optionssmartpath = fieldOptions({
        variables: {
          myfield: relatedFieldID
        }
      }).then((data) => {
    
        console.log(data)
      });
      
      result && setFieldStatus('Remove Fields in a path', false);
      setCurrentSubParentField(null);
      setCurrentParentField(null);
      setIsSmartPathOpen(false);
      setCurrentSelectedPath(null)
      


      // setInlineOpenHOC(result ? result.data.createField.id : '')
      // result && setCurrentIndex(result.data.duplicateField.order);
      // result && setInlineOpenHOC(result.data.duplicateField.id);
    } catch (error) {
      console.error('Error creating new field:', error);
    }
  };
  
  

  const debouncedUpdateField = debounce(async (id, variables) => {
    try {
      await updateField({ variables });
      console.log('Field updated successfully');
      await refetch();
    } catch (error) {
      console.error('Error updating field:', error);
    }
  }, 500);

  const handleFieldChange = useCallback(
    (id, updatedFields) => {
      debouncedUpdateField(id, {
        id: id,
        ...updatedFields
      });
    },
    [debouncedUpdateField]
  );

  const handleDragStart = () => {
    setIsDragging(true);
    clearTimeout(dragTimeoutRef.current);
    dragTimeoutRef.current = setTimeout(() => {
      setIsDragging(false);
    }, 1500);
  };

  const handleDragMove = () => {
    setIsDragging(true);
    clearTimeout(dragTimeoutRef.current);
  };

  const setFieldStatus = (fieldtstatus, isFieldStatusOpen) => {
    setFieldInteractionStatus(fieldtstatus);
    setisFieldInteractionStatusOpen(isFieldStatusOpen);
  };

  const openSmartPath = (field, pathID) => {
    console.log('current selected path',currentSelectedPath)
    console.log('current field',field)
    !isSlideshow && handleChange(field.order, field.id, '', pathID.id )
    setIsPathModeOpen(true)
    const optionssmartpath = fieldOptions({
      variables: {
        myfield: field.id
      }
    }).then((data) => {
  
      if (data.data.fieldOptions.length > 0) {
        field.type === 'smartpath' && setIsSmartPathOpen(true);
        setCurrentParentField(field);
        setCurrentSelectedPath(pathID)
        console.log('smart path field', field);
      }
    });
  
  };
  
  
  
  const sortParentsById = (parentChildArray, activeId, overId) => {
    // Find the indices of the active and over parents

    console.log(parentChildArray);
    console.log(activeId);
    console.log(overId);

    const activeIndex = parentChildArray.findIndex((item) => item.parent.id === activeId);
    const overIndex = parentChildArray.findIndex((item) => item.parent.id === overId);

    // If both indices are valid
    if (activeIndex !== -1 && overIndex !== -1) {
      // Remove the active parent and its children
      const [removed] = parentChildArray.splice(activeIndex, 1);

      // Insert the removed parent and children at the new position
      parentChildArray.splice(overIndex, 0, removed);
    }

    return parentChildArray;
  };

  const flattenParentChildArray = (sortedParentChildArray) => {
    const flattenedArray = [];

    sortedParentChildArray.forEach(({ parent, children }) => {
      // Remove __typename from parent
      const { __typename, ...parentWithoutTypename } = parent;
      flattenedArray.push(parentWithoutTypename);

      // Remove __typename from children
      const childrenWithoutTypename = children.map(({ __typename, ...child }) => child);
      flattenedArray.push(...childrenWithoutTypename);
    });

    return flattenedArray;
  };

  useEffect(() => {
    //!!!! Dont delete this
    console.log('Field interaction status:', data);
  }, [fieldInteractionStatus]);

  const handleManualUpParentfield = (field) => {
  
    if (field.order > 0) {
      const rawparentfields = formFields.filter((formField) => !formField.pathID);
      const activeField = rawparentfields.find((rawField) => rawField.id === field.id);
      const activeFieldIndex = rawparentfields.findIndex((rawField) => rawField.id === activeField.id);

      if (!activeField) {
        console.error('Could not find the active field in rawparentfields.');
        return;
      }
      const targetField = rawparentfields[activeFieldIndex - 1]; // The field before the active field
      console.log('Target Field:', targetField);

      const parentChildArray = rawparentfields.map((parent) => {
        const children = formFields.filter((formField) => formField.relatedFieldID === parent.id);
        return {
          parent: parent,
          children: children
        };
      });

      const sortedParentChildArray = sortParentsById(parentChildArray, activeField?.id, targetField?.id);
      const flattenedArray = flattenParentChildArray(sortedParentChildArray);

      reorderField({
        variables: {
          fieldsOrder: flattenedArray
        }
      })
        .then(async (data) => {
          setInlineOpenHOC(activeField.id);
          setFieldStatus('Sorting field', true);

          setTimeout(() => {
            refetch({ fetchPolicy: 'network-only' }).then((refetchData) => {
              setFieldStatus('Sorting field', false);
              return refetchData;
            });
          }, 700); // Delay of 700ms
        })
        .catch(async (error) => {
          console.error('Mutation error:', error);
        });

      // Additional logic if needed
    } else {
      console.log('its first field');
    }
  };
  const handleManualDownParentfield = (field) => {

    // Check if activeFieldIndex is valid and not at the beginning of the array
    if (field.order < formFields.length) {
      const rawparentfields = formFields.filter((formField) => !formField.pathID);
      const activeField = rawparentfields.find((rawField) => rawField.id === field.id);
      const activeFieldIndex = rawparentfields.findIndex((rawField) => rawField.id === activeField.id);

      if (!activeField) {
        console.error('Could not find the active field in rawparentfields.');
        return;
      }
      const targetField = rawparentfields[activeFieldIndex + 1]; 
      const parentChildArray = rawparentfields.map((parent) => {
        const children = formFields.filter((formField) => formField.relatedFieldID === parent.id);
        return {
          parent: parent,
          children: children
        };
      });

      const sortedParentChildArray = sortParentsById(parentChildArray, activeField?.id, targetField?.id);
      const flattenedArray = flattenParentChildArray(sortedParentChildArray);

      reorderField({
        variables: {
          fieldsOrder: flattenedArray
        }
      })
        .then(async (data) => {
          setInlineOpenHOC(activeField.id);
          setFieldStatus('Sorting field', true);

          setTimeout(() => {
            refetch({ fetchPolicy: 'network-only' }).then((refetchData) => {
              setFieldStatus('Sorting field', false);
              return refetchData;
            });
          }, 700); // Delay of 700ms
        })
        .catch(async (error) => {
          console.error('Mutation error:', error);
        });

      // Additional logic if needed
    } else {
      console.log('its last field');
    }
  };
  
  
  

  const handleDragEndWithParent = ({ active, over }) => {
    const activeId = active?.id;
    const overId = over?.id;

    // Get all the parent fields
    const rawparentfields = formFields.filter((field) => !field.pathID);
    const sortedField = formFields.find((field) => field.relatedFieldID === activeId && !field.pathID);

    // Initialize an empty array to store the 3D structure
    const parentChildArray = [];

    // Iterate through each parent and construct the 3D structure
    rawparentfields.forEach((parent) => {
      // Find the children associated with this parent
      const children = formFields.filter((field) => field.relatedFieldID === parent.id);

      // Push the parent and its children into the array
      parentChildArray.push({
        parent: parent,
        children: children
      });
    });

    const sortedParentChildArray = sortParentsById(parentChildArray, activeId, overId);
    //now i have to pop the fields and make it array of ids so i cant
    const flattenedArray = flattenParentChildArray(sortedParentChildArray);

    reorderField({
      variables: {
        fieldsOrder: flattenedArray
      }
    })
      .then(async (data) => {
        console.log('sorted id', sortedField);
        handleChange(over.data.current.sortable.index, activeId);
        setInlineOpenHOC(activeId);
        setFieldStatus('Sorting field', true);

        setTimeout(() => {
          refetch({ fetchPolicy: 'network-only' }).then((refetchData) => {
            setFieldStatus('Sorting field', false);
            return refetchData;
          });
        }, 700); // Delay of 700ms
      })
      .catch(async (error) => {
        console.error('Mutation error:', error);
      });
  };

  const handleDragEnd = ({ active, over }) => {
    const activeId = active?.id;
    const overId = over?.id;

    if (activeId && overId && activeId !== overId) {
      const updatedFormOrder = arrayMove(formOrder, formOrder.indexOf(active.id), formOrder.indexOf(over.id));

      setFormOrder(updatedFormOrder);

      // Get the updated form fields with new values
      const updatedFormFields = updatedFormOrder.map((formId, index) => {
        const formField = formFields.find((field) => field.id === formId);
        return { ...formField, order: index + 1 };
      });

      // console.log('Updated Form Fields:', updatedFormFields);
      const reorderedFields = updatedFormFields.map(({ __typename, ...field }) => field);

      // Perform the mutation to update the form field order
      const promises = reorderField({
        variables: {
          fieldsOrder: reorderedFields
        }
      });

      promises
        .then(({ data }) => {
          console.log(data);
          console.log(data.reorderFields);
          // Handle the response from the mutation if needed
          refetch();
          setIsDragging(false);
          handleChange(over.data.current.sortable.index);
          const droppedFieldId = updatedFormFields.find((field) => field.order === over.data.current.sortable.index + 1)?.id;
          setInlineOpenHOC(droppedFieldId);
        })
        .catch((error) => {
          // Handle any errors from the mutation if needed
          setIsDragging(false);
          refetch();
        });
    } else {
      console.log('not the first field');
    }
  };

  // Manual sorting functions
  const handleManualUp = (field) => {
    const currentIndex = formOrder.indexOf(field.id);
    if (currentIndex > 0) {
      const updatedFormOrder = arrayMove(formOrder, currentIndex, currentIndex - 1);
      setFormOrder(updatedFormOrder);

      const updatedFormFields = updatedFormOrder.map((formId, index) => {
        const formField = formFields.find((field) => field.id === formId);
        return { ...formField, order: index + 1 };
      });

      const reorderedFields = updatedFormFields.map(({ __typename, ...field }) => field);

      const promises = reorderField({
        variables: {
          fieldsOrder: reorderedFields
        }
      });

      promises
        .then(({ data }) => {
          refetch();
          // You may perform additional actions if needed after the mutation is successful
          setCurrentIndex(currentIndex - 1);
          setInlineOpenHOC(field.id);
        })
        .catch((error) => {
          refetch();
          // Handle any errors from the mutation if needed
        });
    }
  };

  const handleManualDown = (field) => {
    const currentIndex = formOrder.indexOf(field.id);
    if (currentIndex < formOrder.length - 1) {
      const updatedFormOrder = arrayMove(formOrder, currentIndex, currentIndex + 1);
      setFormOrder(updatedFormOrder);

      const updatedFormFields = updatedFormOrder.map((formId, index) => {
        const formField = formFields.find((field) => field.id === formId);
        return { ...formField, order: index + 1 };
      });

      const reorderedFields = updatedFormFields.map(({ __typename, ...field }) => field);

      const promises = reorderField({
        variables: {
          fieldsOrder: reorderedFields
        }
      });

      promises
        .then(({ data }) => {
          refetch();
          // You may perform additional actions if needed after the mutation is successful
          setCurrentIndex(currentIndex + 1);
          setInlineOpenHOC(field.id);
        })
        .catch((error) => {
          refetch();
          // Handle any errors from the mutation if needed
        });
    }
  };
  
  
  

  //Form item just for nav
  const FormItemNav = ({ id, children, fieldID }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const [isDragging, setIsDragging] = useState(false);

    const handleDragStart = () => {
      setIsDragging(true);
    };

    const handleDragEnd = () => {
      setIsDragging(false);
    };
    return (
      <div
        key={id}
        ref={setNodeRef}
        className={`${fieldnavstyle.item_field} ${isDragging ? fieldnavstyle.dragging_item : ''} ${isDragging || id === isInlineOpenHOC ? fieldnavstyle.item_drag_active : ''} `}
        style={{
          transform: transform && isDragging ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
          transition
        }}
        {...attributes}
        tabIndex={-1}>
        <React.Fragment>
          <div className={`${fieldnavstyle.item_drag_icon} ${'item_drag_icon'}`} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} {...listeners}>
            <DragIndicatorIcon />
          </div>

          <React.Fragment>{children}</React.Fragment>
        </React.Fragment>
      </div>
    );
  };
  //Form item just for block - need to seperate it since it has different html structure
  const FormItemDragHandle = ({ id, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    return (
      <div
        className={`${blockstyle.block_item_drag}`}
        key={id}
        ref={setNodeRef}
        style={{
          transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
          transition
        }}
        {...attributes}
        tabIndex={-1}
        {...listeners}>
        <React.Fragment>
          <div className={`${blockstyle.item_drag_icon} ${'item_drag_icon'}`}>
            <DragIndicatorIcon />
          </div>
        </React.Fragment>
      </div>
    );
  };

  const FormItemBlock = ({ id, children, field }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    return (
      <section
        ref={setNodeRef}
        key={id}
        className={`${'container__block'}  ${'block_nav_item'} ${blockstyle.block_edit_with_nav} ${blockstyle.block_item_drag} ${field.imgsize === 'full-screen' ? blockstyle.full_screen_image : blockstyle.original_size_img} ${field.type}`}
        style={{
          transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
          transition,
          backgroundImage: `url(${field.value && field.imgsize === 'full-screen' ? field.value : ''})`
        }}
        {...attributes}>
        <div className={`${blockstyle.block_edit_inner}`}>
          <div {...listeners} className={`${blockstyle.item_drag_icon} ${'item_drag_icon'}`}>
            <DragIndicatorIcon />
          </div>
          <div>{children}</div>
        </div>
      </section>
    );
  };
  


  useEffect(() => {
    if (data && data.formFields) {
      const initialFormOrder = data.formFields.map((field) => field.id);
      setFormOrder(initialFormOrder);
      formFields.some((field) => field.pathID !== null) && setHasSmartPath(true);
    }

    if (data && data.formFields && data.formFields.length > 0 && isInlineOpenHOC === null) {
      // Get the ID of the first field on the form
      const firstFieldID = data.formFields[0].id;
      // Set the isInlineOpenHOC state with the ID of the first field
      setInlineOpenHOC(firstFieldID);
    }
  }, [data]);

  useEffect(() => {
    // Replace 'YOUR_API_KEY' with your actual Google Fonts API key
    const apiKey = 'AIzaSyCZRaPkJXXy9Z5IRpQjhYaesi3-ur8AASw';

    // Array of specific fonts you want to load
    const specificFonts = ['Roboto', 'Open Sans', 'Montserrat', 'Vollkorn', 'Noto Serif JP', 'Exo', 'Muli', 'Arvo', 'PlayfairDisplay', 'Poppins', 'Ubuntu', 'Inter', 'Sen', 'Space Mono', 'Roboto Mono'];

    // Fetch the list of available fonts from Google Fonts API
    axios
      .get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}`)
      .then((response) => {
        const fontFamilies = response.data.items.filter((font) => specificFonts.includes(font.family)).map((font) => font.family);

        // Add a <link> tag for each font to the document head
        fontFamilies.forEach((fontFamily) => {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(fontFamily)}&display=swap`;
          document.head.appendChild(link);
        });
      })
      .catch((error) => {
        console.error('Error fetching fonts:', error);
      });
  }, []);

  if (loading) {
    return (
      <p className="loader__full-page">
        <img src="/logo.svg" alt="Logo" />
        Loading...
      </p>
    );
  }

  if (error && styleerror) {
    return <p>Error fetching fields: {error.message}</p>;
  }

  // if (styleerror) {
  //   return <Navigate to="/404" />;
  // }

  const formFields = (data && data.formFields) || [];
  const formStyleData = style && style.formStyle;
  const formDetails = form?.form;
  const isSlideshow = formDetails?.iswizard || false; // Default value is false if formDetails is undefined
  const formType = formDetails?.type || 'classic';

  const currentItemID = formOrder[currentIndex];

  const backgroundColor = formStyleData ? formStyleData.backgroundColor : '#FFF';
  const font = formStyleData ? formStyleData.font : 'Poppins';
  const labelColor = formStyleData ? formStyleData.labelColor : '#DDD';
  const videobackgroundurl = formStyleData ? formStyleData.videobackgroundurl : '';
  const bgimgurl = formStyleData ? formStyleData.bgimgurl : '';
  const videobackgroundtype = formStyleData ? formStyleData.videobackgroundtype : '';
  const backgroundformcolor = formStyleData ? formStyleData.backgroundformcolor : 0;

  const formstyle = formStyleData ? formStyleData.formStyle : 'line';
  const formtype = isSlideshow ? 'slideshow' : 'classic';
  const logoposition = formStyleData ? formStyleData.logoposition : 'left';
  const relevantlogoposition = logoposition === 'center' ? blockstyle.block_logo_center : blockstyle.block_logo_left;
  const relevantlayoutskeleton = isSlideshow ? blockstyle.block_slideshow_skeleton : !isDesktop ? blockstyle.block_classic_mobile_skeleton : blockstyle.block_classic_skeleton;
  const relevantlayoutlogo = isSlideshow ? blockstyle.block_logo_slideshow : blockstyle.block_logo_classic;
  const thankyoupageonslideshow = isSlideshow && activeSection === 'thankyou' ? blockstyle.thankyou_pg_on_slideshow : '';

  const relevantField = formFields.find((field) => field.id === currentItemID);

  const displayFormInnerContainer = !isSlideshow || activeSection === 'thankyou';
  const bgcontrast = contrast(backgroundColor);
  return (
    <>
      {membershipData?.checkMember ? (
        <div className={`${'page__editor'} ${thankyoupageonslideshow} ${pagestyle.container}  ${'formstyle__' + formstyle} ${'formtype__' + formtype} ${isDesktop ? blockstyle.is_desktop : blockstyle.is_not_desktop} ${blockstyle.editor_page_skeleton} ${isSmartPathOpen ? blockstyle.smartpath_open : ''} ${bgcontrast === 'dark' ? 'dark_mode' : 'light_mode'}`}>
          {isShareModalOpen && (
            <Suspense fallback={<div>Loading...</div>}>
              <ShareModal form={form?.form} setIsShareModalOpen={setIsShareModalOpen} />
            </Suspense>
          )}

          {formDetails && <MetaHelmet name={formDetails?.name} description={formDetails?.description} link={'/editor/' + myform} keywords={formDetails?.description} author={formDetails?.name} />}
          <div className={`${navstyle.container} ${pagestyle.scrollable}`}>
            <Suspense fallback={<div>Loading...</div>}>
              <Block fields={formFields} refetch={refetch} myform={myform} setIsShareModalOpen={setIsShareModalOpen} stylerefetch={stylerefetch} formrefetch={formrefetch} formStyle={formStyleData} formDetails={formDetails} section={activeSection} setInlineOpenHOC={setInlineOpenHOC} setCurrentIndex={setCurrentIndex} openCreateField={openCreateField} setFieldStatus={setFieldStatus} isSmartPathOpen={isSmartPathOpen}
              />
            </Suspense>

            <div className={navstyle.section_seperator}></div>
            {/* Implemeting editor sections */}
            {editorsections.map((section, index) => (
              <React.Fragment key={index}>
                <ul key={index} ref={boundingContainerRef} className={`${fieldnavstyle.container}`}>
                  {!isSmartPathOpen && <li className={`${fieldnavstyle.header} ${activeSection === section.name ? fieldnavstyle.header_active : ''}`} onClick={() => setActiveSection(section.name)}>
                    <span>{section.title}</span>
                  </li>}
                  {/* Side Section */}
                  {activeSection === section.name && !isSmartPathOpen && (
                    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={hasSmartPath ? handleDragEndWithParent : handleDragEnd} modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}>
                      <SortableContext items={formOrder}>
                        <React.Fragment>
                          {formOrder.map((fieldID, index) => (
                            <React.Fragment key={index}>
                              {formFields.map(
                                (field, index) =>
                                  field.id === fieldID &&
                                  !field.pathID && (
                                    <li key={index} >
                                      <FormItemNav key={fieldID} id={fieldID}>
                                        {formFields.map(
                                          (field, index) =>
                                            field.id === fieldID && (
                                              <div key={index} className={`${fieldnavstyle.item_navigator}`} ref={(ref) => (fieldRefs.current[field.id] = ref)} onClick={() => handleChange(field.order, field.id, 'side-nav')}>
                                                <RenderFieldIcon icon={field.category === 'option' || field.category === 'scale' ? field.radiotype : field.type} />
                                                <span>{field.label}</span>
                                              </div>
                                            )
                                        )}
                                      </FormItemNav>
                      
                                    </li>
                                  )
                              )}
                            </React.Fragment>
                          ))}
       
                        </React.Fragment>
                      </SortableContext>
                    </DndContext>
                  )}
                  
                  {isSmartPathOpen && activeSection === section.name && 
                   <Suspense fallback={<div>Loading...</div>}>
                    <SmartPathNav setIsSmartPathOpen={setIsSmartPathOpen} currentParentField={currentParentField} formFields={formFields} setCurrentSubParentField={setCurrentSubParentField}
                    currentSubParentField={currentSubParentField} handleChange={handleChange} fieldOptions={fieldOptions} optionsdata={optionsdata}
                    //DND
                    sensors={sensors}  handleDragEnd={handleDragEnd} formOrder={formOrder} openSmartPath={openSmartPath} FormItemNav={FormItemNav} fieldRefs={fieldRefs} isInlineOpenHOC={isInlineOpenHOC}
                    //Create New field
                    refetch={refetch} activeSection={activeSection} setInlineOpenHOC={setInlineOpenHOC} setCurrentIndex={setCurrentIndex} myform={myform} setFieldStatus={setFieldStatus}
                    handleRemoveFielsInAPath={handleRemoveFielsInAPath} setCurrentParentField={setCurrentParentField}
                    currentSelectedPath={currentSelectedPath} setCurrentSelectedPath={setCurrentSelectedPath}
                    isPathModeOpen={isPathModeOpen} setIsPathModeOpen={setIsPathModeOpen} isSlideshow={isSlideshow}/>
                 </Suspense>
                  }
                  {activeSection === section.name && !isSmartPathOpen && (
                    <li onClick={() => openingBlockFromEditor(section.name)} className={`${fieldnavstyle.add_new_question}`}>
                      Add New question{' '}
                    </li>
                  )}
                </ul>
                {section.name !== 'thankyou' && <div className={`${navstyle.section_seperator} ${navstyle.with_line}`} />}
              </React.Fragment>
            ))}
          </div>
          <div className={`${pagestyle.editor_edit_form_section} ${'page__editor'} ${isSmartPathOpen ? blockstyle.smartpath_open: ''} ${isSmartPathOpen ? 'smartpath_open': ''} `}>
            <main
              className={`${relevantlayoutskeleton} ${'formstyle__' + formstyle} ${'formtype__' + formtype}`}
              style={{
                fontFamily: font,
                color: labelColor,
                backgroundColor: backgroundColor
              }}>
              {videobackgroundtype === 'pexel' && (
                <Suspense fallback={<div>Loading...</div>}>
                  <BackgroundMedia style={formStyleData} />
                </Suspense>
              )}
              {videobackgroundtype === 'image' && (
                <Suspense fallback={<div>Loading...</div>}>
                  <BackgroundMediaImage style={formStyleData} />
                </Suspense>
              )}

              {isFieldInteractionStatusOpen && <div className={`${blockstyle.field_interaction_status_prompt}`}>{fieldInteractionStatus ? fieldInteractionStatus : 'Nothing'}</div>}

              <header className={`${'container__logo'} ${blockstyle.block_logo} ${blockstyle.block_edit_inner} ${relevantlayoutlogo} ${relevantlogoposition}`}>
                <Suspense fallback={<div>Loading...</div>}>
                  <AddLogo formStyle={formStyleData} stylerefetch={stylerefetch} myform={myform} />
                </Suspense>
              </header>
              {formFields?.length === 0 && <EmptyStateNoField />}
              {/* Classic form */}
              {isDesktop ? (
                displayFormInnerContainer ? (
                  <React.Fragment>
                    <input
                      style={{
                        position: 'absolute',
                        top: '-40px',
                        height: '1px',
                        opacity: '0'
                      }}
                    />
                    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={hasSmartPath ? handleDragEndWithParent : handleDragEnd} onDragStart={handleDragStart} onDragMove={handleDragMove}>
                      <SortableContext items={formOrder}>
                        {formOrder.map((fieldID, index) => (
                          <React.Fragment key={index}>
                        
                              {/* When this is on drag(DND) */}

                                   {/* currentParentField && field.pathID === currentSubParentField && fieldID === field.id */}


                              {formFields.map(
                                (field, index) =>
                                  (fieldID === field.id && !field.pathID && !field.relevantField) || 
                                  field?.relatedFieldID === currentParentField?.id && field?.pathID === currentSubParentField && fieldID === field.id  && isSmartPathOpen && field?.type !== 'smartpath'
                                   ? (
                                    <Element name={fieldID}>
                                    <React.Fragment key={index}>
                                      {!isDragging ? (
                                        <React.Fragment>
                                          {/* {console.log('parent field', currentParentField)}
                                          {console.log('current sub parent',currentSubParentField )} */}
                                          <section
                                            className={` ${'container__block'} ${blockstyle.block_edit_with_nav} ${field.imgsize === 'full-screen' ? blockstyle.full_screen_image : ''} ${'block_nav_item'} ${field.type} ${field.type === 'image' ? blockstyle.field_type_media : ''}`}
                                            style={
                                              field.imgsize === 'full-screen'
                                                ? {
                                                    backgroundImage: `url(${field.value ? field.value : ''})`
                                                  }
                                                : null // If imgsize is not 'full-screen', no backgroundImage style is applied
                                            }>
                                            <div className={`${'wrapper__block'} ${blockstyle.block_edit_inner} `}>
                                              { !hasSmartPath && <FormItemDragHandle id={fieldID} />}
                                              <React.Fragment>
                                                <Suspense fallback={<div>Loading...</div>}>
                                                  <Field
                                                    isSlideshow={isSlideshow}
                                                    field={field}
                                                    formStyle={formStyleData}
                                                    purestyle={style}
                                                    formFields={formFields}
                                                    updateField={handleFieldChange}
                                                    removeField={removeField}
                                                    handleDuplicateField={handleDuplicateField}
                                                    refetch={refetch}
                                                    myform={myform}
                                                    isInlineOpenHOC={isInlineOpenHOC}
                                                    setInlineOpenHOC={setInlineOpenHOC}
                                                    setCurrentIndex={setCurrentIndex}
                                                    handleManualUp={hasSmartPath ? handleManualUpParentfield : handleManualUp}
                                                    handleManualDown={hasSmartPath ? handleManualDownParentfield : handleManualDown}
                                                    activeSection={activeSection}
                                                    fieldId={field.id}
                                                    setFieldStatus={setFieldStatus}
                                                    setCurrentSubParentField={setCurrentSubParentField}
                                                    currentSubParentField={currentSubParentField}
                                                    openSmartPath={openSmartPath}
                                                    handleChange={handleChange}
                                                    currentParentField={currentParentField}
                                                    setIsSmartPathOpen={setIsSmartPathOpen}
                                                    fieldOptions={fieldOptions}
                                             
                                                  />
                                                </Suspense>

                                                <ul className={`${blockstyle.block_item_nav} ${'block_nav'} ${'nav__block'}`}>
                                                  {/* <li onClick={() => handleRemoveField(field.id)}><DeleteIcon /></li> */}
                                                  <RemoveField fieldId={field.id} activeSection={activeSection} refetch={refetch} field={field} setCurrentIndex={setCurrentIndex} setInlineOpenHOC={setInlineOpenHOC} formFields={formFields}>
                                                    <DeleteIcon />
                                                  </RemoveField>
                                                  <li onClick={() => handleDuplicateField(field)}>
                                                    <ContentCopyIcon />
                                                  </li>
                                                </ul>
                                              </React.Fragment>
                                            </div>
                                          </section>
                                          {/* //Caption for image field not button */}
                                          {field.readwritepermission === 'read' && field.valuetoggle && (
                                            <Suspense fallback={<div>Loading...</div>}>
                                              <FieldCaption myform={myform} field={field} />
                                            </Suspense>
                                          )}
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          {/* //When its being sorted */}
                                          <FormItemBlock id={fieldID} field={field}>
                                            {fieldID === field.id && (
                                              <React.Fragment>
                                                <Suspense fallback={<div>Loading...</div>}>
                                                  <Field
                                                    isSlideshow={isSlideshow}
                                                    field={field}
                                                    formStyle={formStyleData}
                                                    purestyle={style}
                                                    formFields={formFields}
                                                    updateField={handleFieldChange}
                                                    removeField={removeField}
                                                    handleDuplicateField={handleDuplicateField}
                                                    refetch={refetch}
                                                    myform={myform}
                                                    isInlineOpenHOC={isInlineOpenHOC}
                                                    setInlineOpenHOC={setInlineOpenHOC}
                                                    setCurrentIndex={setCurrentIndex}
                                                    activeSection={activeSection}
                                                    fieldId={field.id}
                                                    handleManualUp={hasSmartPath ? handleManualUpParentfield : handleManualUp}
                                                    handleManualDown={hasSmartPath ? handleManualDownParentfield : handleManualDown}
                                                    setFieldStatus={setFieldStatus}
                                                    setCurrentSubParentField={setCurrentSubParentField}
                                                    currentSubParentField={currentSubParentField}
                                                    openSmartPath={openSmartPath}
                                                    handleChange={handleChange}
                                                    currentParentField={currentParentField}
                                                    setIsSmartPathOpen={setIsSmartPathOpen}
                                                    fieldOptions={fieldOptions}
                                      
                                                  />
                                                </Suspense>
                                              </React.Fragment>
                                            )}
                                          </FormItemBlock>
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                    </Element>
                                  ) :''
                              )}
     
                          </React.Fragment>
                        ))}
                      </SortableContext>
                    </DndContext>
                  </React.Fragment>
                ) : (
                  ''
                )
              ) : (
                ''
              )}

              {/* //Mobile classic form */}
              {displayFormInnerContainer && !isDesktop ? (
                <div className={pagestyle.form_inner_container}>
                  {formOrder.map((fieldID, index) => (
                    <React.Fragment key={index}>
                      <Element name={fieldID} className={` content ${showContent ? 'show' : ''} }`}>
                        <form onSubmit={handleSubmit((data) => handleFormSubmit(data, `Form ${fieldID}`))}>
                          {formFields.map(
                            (field, index) =>
                              fieldID === field.id && (
                                <React.Fragment>
                                  <section
                                    key={index}
                                    className={`${'container__block'} ${field.imgsize}  ${field.type === 'image' ? blockstyle.field_type_media : ''}`}
                                    style={{
                                      backgroundImage: `url(${field.value && field.imgsize === 'full-screen' ? field.value : ''})`
                                    }}>
                                    <div className={`${'wrapper__block'} ${blockstyle.block_edit_inner}`}>
                                      {
                                        <ul className={`${blockstyle.block_item_nav} ${'block_nav'}`}>
                                          <li onClick={() => hasSmartPath ? handleManualDownParentfield(field) : handleManualUp(field)}>
                                            <ArrowDropUpIcon />
                                          </li>
                                          {/* <li onClick={() => handleRemoveField(field.id)}><DeleteIcon /></li> */}
                                          <RemoveField fieldId={field.id} activeSection={activeSection} refetch={refetch} field={field} setCurrentIndex={setCurrentIndex} setInlineOpenHOC={setInlineOpenHOC} formFields={formFields}>
                                            <DeleteIcon />
                                          </RemoveField>
                                          <li onClick={() => handleDuplicateField(field)}>
                                            <ContentCopyIcon />
                                          </li>
                                          <li onClick={() => hasSmartPath ? handleManualDownParentfield(field) : handleManualDown(field)}>
                                            <ArrowDropDownIcon />
                                          </li>
                                        </ul>
                                      }
                                      <Suspense fallback={<div>Loading...</div>}>
                                        <Field
                                          isSlideshow={isSlideshow}
                                          field={field}
                                          formStyle={formStyleData}
                                          purestyle={style}
                                          formFields={formFields}
                                          updateField={handleFieldChange}
                                          removeField={removeField}
                                          handleDuplicateField={handleDuplicateField}
                                          refetch={refetch}
                                          myform={myform}
                                          isInlineOpenHOC={isInlineOpenHOC}
                                          setInlineOpenHOC={setInlineOpenHOC}
                                          setCurrentIndex={setCurrentIndex}
                                          activeSection={activeSection}
                                          fieldId={field.id}
                                          handleManualUp={hasSmartPath ? handleManualUpParentfield : handleManualUp}
                                          handleManualDown={hasSmartPath ? handleManualDownParentfield : handleManualDown}
                                          setFieldStatus={setFieldStatus}
                                          setCurrentSubParentField={setCurrentSubParentField}
                                          currentSubParentField={currentSubParentField}
                                          currentParentField={currentParentField}
                                          setIsSmartPathOpen={setIsSmartPathOpen}
                                          fieldOptions={fieldOptions}
                                          openSmartPath={openSmartPath}
                                       
                                        />
                                      </Suspense>
                                    </div>
                                  </section>
                                  {field.readwritepermission === 'read' && field.valuetoggle && (
                                    <Suspense fallback={<div>Loading...</div>}>
                                      <FieldCaption myform={myform} field={field} />
                                    </Suspense>
                                  )}
                                </React.Fragment>
                              )
                          )}
                        </form>
                      </Element>
                    </React.Fragment>
                  ))}
                </div>
              ) : (
                ''
              )}

              {/* Slideshow form */}
              {!displayFormInnerContainer ? (
                <div className={pagestyle.form_inner_container}>
                  {formOrder.map(
                    (fieldID, index) =>
                      fieldID === currentItemID && (
                        <React.Fragment key={index}>
                          <Element name={fieldID} className={` content ${showContent ? 'show' : ''} }`}>
                            <form onSubmit={handleSubmit((data) => handleFormSubmit(data, `Form ${fieldID}`))}>
                              {formFields.map(
                                (field, index) =>
                                  fieldID === field.id && (
                                    <div
                                      key={index}
                                      className={`${'container__block'} ${blockstyle.block_edit_with_nav} ${field.type} ${field.type === 'image' ? blockstyle.field_type_media : ''} ${field.imgsize === 'full-screen' ? blockstyle.full_screen_image : ''}`}
                                      style={
                                        field.imgsize === 'full-screen'
                                          ? {
                                              backgroundImage: `url(${field.value ? field.value : ''})`
                                            }
                                          : null // If imgsize is not 'full-screen', no backgroundImage style is applied
                                      }>
                                      <div className={`${'wrapper__block'} ${blockstyle.block_edit_inner}`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                          <Field
                                            isSlideshow={isSlideshow}
                                            field={field}
                                            formStyle={formStyleData}
                                            purestyle={style}
                                            formFields={formFields}
                                            updateField={handleFieldChange}
                                            removeField={removeField}
                                            handleDuplicateField={handleDuplicateField}
                                            refetch={refetch}
                                            myform={myform}
                                            isInlineOpenHOC={isInlineOpenHOC}
                                            setInlineOpenHOC={setInlineOpenHOC}
                                            setCurrentIndex={setCurrentIndex}
                                            activeSection={activeSection}
                                            fieldId={field.id}
                                            handleManualUp={hasSmartPath ? handleManualUpParentfield : handleManualUp}
                                            handleManualDown={hasSmartPath ? handleManualDownParentfield : handleManualDown}
                                            setFieldStatus={setFieldStatus}
                                            setCurrentSubParentField={setCurrentSubParentField}
                                            currentSubParentField={currentSubParentField}
                                            currentParentField={currentParentField}
                                            setIsSmartPathOpen={setIsSmartPathOpen}
                                            fieldOptions={fieldOptions}
                                            openSmartPath={openSmartPath}
                                    
                                            >
                                            {!isDesktop && (
                                              <ul className={`${blockstyle.block_item_nav} ${'block_nav'}`}>
                                                <li onClick={() => hasSmartPath? handleDragEndWithParent(field): handleManualUp(field)}>
                                                  <ArrowDropUpIcon />
                                                </li>
                                                <li onClick={() => hasSmartPath ? handleManualDownParentfield(field) : handleManualDown(field)}>
                                                  <ArrowDropDownIcon />
                                                </li>
                                              </ul>
                                            )}
                                          </Field>
                                        </Suspense>
                                        {isDesktop && (
                                          <ul className={`${blockstyle.block_item_nav} ${'block_nav'}`}>
                                            <li onClick={() => hasSmartPath ? handleManualDownParentfield(field) : handleManualUp(field)}>
                                              <ArrowDropUpIcon />
                                            </li>
                                            <RemoveField fieldId={field.id} activeSection={activeSection} refetch={refetch} field={field} setCurrentIndex={setCurrentIndex} setInlineOpenHOC={setInlineOpenHOC} formFields={formFields}>
                                              <DeleteIcon />
                                            </RemoveField>
                                            <li onClick={() => handleDuplicateField(field)}>
                                              <ContentCopyIcon />
                                            </li>
                                            <li onClick={() => hasSmartPath ? handleManualDownParentfield(field) : handleManualDown(field)}>
                                              <ArrowDropDownIcon />
                                            </li>
                                          </ul>
                                        )}
                                      </div>

                                      <footer className={`${'footer__item'} ${blockstyle.block_footer} ${isSlideshow ? blockstyle.block_footer_slideshow : blockstyle.block_footer_classic}`}>
                                        <EditSubmitButton currentIndex={currentIndex} currentfield={field} formFields={formFields} isSlideshow={isSlideshow} myform={myform} formStyle={formStyleData} stylerefetch={stylerefetch} />
                                      </footer>
                                    </div>
                                  )
                              )}
                            </form>
                          </Element>
                        </React.Fragment>
                      )
                  )}
                </div>
              ) : (
                ''
              )}

              {/* Footer for classic form */}
              {!isSlideshow && formFields?.length > 0 && (
                <footer className={`${'footer__item'} ${blockstyle.block_footer} ${isSlideshow ? blockstyle.block_footer_slideshow : blockstyle.block_footer_classic}`}>
                  <EditSubmitButton currentfield={''} formFields={formFields} isSlideshow={isSlideshow} myform={myform} formStyle={formStyleData} stylerefetch={stylerefetch} />
                </footer>
              )}
              {isSlideshow && activeSection === 'body' && currentIndex > 0 ? '' : <PoweredByPepperForm />}
            </main>
          </div>
        </div>
      ) : (
        'not a member'
      )}{' '}
    </>
  );
};

export default Editor;
