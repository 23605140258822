import React, { useRef, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { isDesktop } from 'react-device-detect';


import inlineeditorstyle from '../../css/editor/InlineEditor.module.scss';
import { UPDATE_FIELD } from '../../graphql/Field';
import { DELETE_FILE } from '../../graphql/Images';

import SubmitButtonPrefix from './SubmitButtonPrefix';
import LogoStackFilter from './LogoStackFilter';



const LogoStack = ({ props, setIsAllowOverflow }) => {
  const { field, refetch } = props;
  const [updateField] = useMutation(UPDATE_FIELD);
  const [deleteFile] = useMutation(DELETE_FILE);

  const [isOpenEditField, setIsOpenEditField] = useState(false);

  const editFieldDivRef = useRef(null);

  const handleToggleCaption = async (value) => {
    console.log(value)
    try {
      const result = await updateField({
        variables: {
          id: field.id,
          myform: props.myform,
          autoplay: value
        }
      });
      console.log(result);
      props.refetch();
    } catch (error) {
      console.error('Error updating field:', error);
    }
  };



  useEffect(() => {
    const handleClickOutsideEditField = (event) => {
      // Check if the ref element exists and if the event target is outside the ref element
      if (editFieldDivRef.current && !editFieldDivRef.current.contains(event.target)) {
        setIsOpenEditField(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideEditField);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEditField);
    };
  }, []);

  const autoplay = field.autoplay ? field.autoplay : false;

  return (
    <React.Fragment>
      {isDesktop ? (
        <li className={`${inlineeditorstyle.inline_sub_section} `} ref={editFieldDivRef}>
          <header onClick={() => setIsOpenEditField(!isOpenEditField)}>Filter</header>
          {isOpenEditField && <LogoStackFilter props={props} setIsOpenEditField={setIsOpenEditField} />}
        </li>
      ) : (
       <LogoStackFilter props={props} setIsOpenEditField={setIsOpenEditField} />
      )}
      <li className={`${autoplay  ? inlineeditorstyle.selected : ''}`} onClick={() => handleToggleCaption(!autoplay)}>
        Scroll
      </li>
    </React.Fragment>
  );
};

export default LogoStack;
