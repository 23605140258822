import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import { UPDATE_FIELD } from '../../graphql/Field';

import { DELETE_FILE } from '../../graphql/Images';
import Unsplash from './Unsplash';
import Pexels from './Pexels';
import UploadComponent from '../../utils/UploadComponent';

import inlinegallerystyle from '../../css/editor/InlineEditorGallery.module.scss';
import HTMLVideoRender from '../../utils/HTMLVideoRender';

import { StandardMediaGallery } from '../../utils/MediaGalleryList';

const imageposition = [{ name: 'left' }, { name: 'right' }];

const MediaGallery = ({ props, closeModal }) => {
  const currentfield = props?.field;
  const [selectedGallery, setMediaGallery] = useState(currentfield.imageOnFieldType ? currentfield.imageOnFieldType : 'unsplash');
  const [value, setValue] = useState(currentfield ? currentfield.imageOnFieldLayoutPosition : 'left');
  const [updateField] = useMutation(UPDATE_FIELD);
  const [deleteFile] = useMutation(DELETE_FILE);

  const handleUpdateImageForField = async (url, authorname, authorurl, authoralttext) => {
    //Delete in Amazon if image is uploaded
    if (currentfield.imageOnFieldType === 'upload-media' && currentfield.imageOnFieldURL !== null) {
      try {
        await deleteFile({
          variables: {
            pictureUrl: currentfield.imageOnFieldUrl
          }
        });
        console.log('Image deleted from S3');
      } catch (error) {
        console.error('Error deleting existing file:', error);
      }
    }

    try {
      const result = await updateField({
        variables: {
          id: currentfield.id,
          myform: props.myform,
          imageOnFieldUrl: url,
          imageOnFieldType: selectedGallery,
          imageOnFieldAuthorName: authorname,
          imageOnFieldAuthorUrl: authorurl,
          imageOnFieldAuthorUrl: authoralttext
        }
      });
      closeModal();
      props.refetch();
    } catch (error) {
      console.error('Error updating field:', error);
    }
  };

  const handleRemoveimageOnFieldUrl = async () => {
    //Delete in Amazon if image is uploaded
    if (currentfield.imageOnFieldType === 'upload-media' && currentfield.imageOnFieldURL !== null) {
      try {
        await deleteFile({
          variables: {
            pictureUrl: currentfield.imageOnFieldUrl
          }
        });
        console.log('Image deleted from S3');
      } catch (error) {
        console.error('Error deleting existing file:', error);
      }
    }

    // Update field to remove the image
    try {
      const result = await updateField({
        variables: {
          id: currentfield.id,
          myform: props.myform,
          imageOnFieldUrl: null,
          imageOnFieldType: null,
          imageOnFieldAuthorName: null,
          imageOnFieldAuthorUrl: null,
          imageOnFieldAuthorUrl: null
        }
      });
      props.refetch();
      closeModal();
    } catch (error) {
      console.error('Error updating field:', error);
    }
  };

  const handleUpdateImagePosition = async (event) => {
    const style = event.target.value;
    setValue(style);

    try {
      const result = await updateField({
        variables: {
          id: currentfield.id,
          myform: props.myform,
          imageOnFieldLayoutPosition: style
        }
      });
      props.refetch();
      closeModal();
    } catch (error) {
      console.error('Error updating field:', error);
    }
  };

  return (
    <div className={`${inlinegallerystyle.container}`}>
      <nav className={`${inlinegallerystyle.nav}`}>
        <ul className={`${inlinegallerystyle.media_list}`}>
          {StandardMediaGallery.map((media, index) => (
            <li key={index} onClick={() => setMediaGallery(media.name)} className={`${selectedGallery === media.name ? inlinegallerystyle.active : ''}`}>
              <span className={`${inlinegallerystyle.media_logo}`}>
                <img src={`${media.logo}`} alt={`${media.title} selected`} />
              </span>
              <span className={`${inlinegallerystyle.media_title}`}>{media.title}</span>
            </li>
          ))}
        </ul>
        {currentfield.imageOnFieldUrl && (
          <div className={`${inlinegallerystyle.current_img}`}>
            <header>
              Current {currentfield.imageOnFieldType === 'pexels' ? ' Video' : 'Image'} <i>{currentfield.imageOnFieldType}</i>
            </header>

            {currentfield.imageOnFieldType === 'pexels' ? (
              <div style={{ position: 'relative' }}>
                <HTMLVideoRender url={currentfield.imageOnFieldUrl} key={currentfield.imageOnFieldUrl} />
                <span onClick={() => handleRemoveimageOnFieldUrl(currentfield.imageOnFieldUrl)} className={`${inlinegallerystyle.delete_current_img}`}>
                  Remove
                </span>
                {/* <video key={currentfield.imageOnFieldUrl} autoPlay muted loop>
                        <source src={currentfield.imageOnFieldUrl} type="video/mp4" />
                    </video> */}
                {currentfield.imageOnFieldAuthorName ? (
                  <span>
                    Video by{' '}
                    <Link target="_blank" to={currentfield.imageOnFieldAuthorUrl}>
                      {currentfield?.imageOnFieldAuthorName}
                    </Link>
                  </span>
                ) : (
                  ''
                )}{' '}
              </div>
            ) : (
              <div style={{ position: 'relative' }}>
                <span onClick={() => handleRemoveimageOnFieldUrl(currentfield.imageOnFieldUrl)} className={`${inlinegallerystyle.delete_current_img}`}>
                  Remove
                </span>
                <img src={currentfield.imageOnFieldUrl} />

                {currentfield.imageOnFieldAuthorName ? (
                  <span style={{ position: 'absolute', fontSize: '12px', bottom: '12px', left: '8px' }}>
                    Photo by{' '}
                    <Link target="_blank" to={currentfield?.imageOnFieldAuthorUrl}>
                      {currentfield?.imageOnFieldAuthorName}
                    </Link>
                  </span>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        )}
      </nav>

      <div className={`${inlinegallerystyle.content}`}>
        {StandardMediaGallery.map((media, index) => selectedGallery === media.name && <header key={index}>Browse {selectedGallery === media.name ? media.title : 'Media'}</header>)}

        {console.log(props)}

        {selectedGallery === 'unsplash' && <Unsplash handleUpdateCaption={handleUpdateImageForField} />}
        {selectedGallery === 'pexels' && <Pexels handleUpdateCaption={handleUpdateImageForField} />}
        {selectedGallery === 'upload-media' && <UploadComponent setFileURL={handleUpdateImageForField} mediatype="Image" />}
      </div>
    </div>
  );
};
export default MediaGallery;
