import React, { useEffect, useState } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { isAuthenticated } from './Auth';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [authenticationComplete, setAuthenticationComplete] = useState(false);
  
    useEffect(() => {
      const checkAuthentication = async () => {
        const isAuthenticatedUser = await isAuthenticated();
        // console.log(isAuthenticatedUser); // Check the value returned by isAuthenticated
        setAuthenticated(isAuthenticatedUser);
        setAuthenticationComplete(true);
      };
  
      checkAuthentication();
    }, []);
  
    if (!authenticationComplete) {
      return null; // Return null or a loading component while authentication check is in progress
    }
  
    return authenticated ? (
      <Component {...rest} />
    ) : (
      <Navigate to="/login" replace={true} /> // Redirect to the login page if the user is not authenticated
    );
  };
  
  export default ProtectedRoute;
  