import gql from "graphql-tag";

export const GET_OPTION = gql`
  query($myfield: String!) {
    fieldOptions(myfield: $myfield) {
        id
        value
        selected
        type
        icon
        order
        asset
        description
        link
    }
  }
`;

export const GET_OPTION_BY_MYOPTION = gql`
  query($myoption: String!) {
    optionByMyOption(myoption: $myoption) {
        id
        value
        selected
        type
        icon
        order
        asset
        description
        link
    }
  }
`;


export const CREATE_OPTION = gql`
  mutation createOption(
    $myfield: String!
    $myform: String
    $value: String
    $order: Int
    $type: String
    $selected: Boolean
    $icon: String
    $asset: String
    $description: String
    $link: String
  ) {
    createOption(
      myfield: $myfield
      myform: $myform
      value: $value
      order: $order
      type: $type
      selected: $selected
      icon: $icon
      asset: $asset
      description: $description
      link: $link
    )
  }
`;


export const CREATE_A_PATH = gql`
  mutation createOptionSmartPath(
    $myfield: String!
    $myform: String
    $value: String
    $order: Int
    $type: String
    $selected: Boolean
    $icon: String
    $asset: String
    $description: String
    $link: String
  ) {
    createOptionSmartPath(
      myfield: $myfield
      myform: $myform
      value: $value
      order: $order
      type: $type
      selected: $selected
      icon: $icon
      asset: $asset
      description: $description
      link: $link
    )
  }
`;

export const UPDATE_OPTION = gql`
  mutation updateOption(
    $id: String!, 
    $value: String,
    $selected: Boolean,
    $type: String,
    $icon: String
    $asset: String
    $description: String
    $link: String
    ) {
    updateOption(
      id: $id
      value: $value
      selected: $selected
      type: $type
      icon: $icon
      asset: $asset
      description: $description
      link: $link
    ) {
      id
      value
      selected
      type
      icon
    }
  }
`;


export const UPDATE_OPTION_ICON = gql`
  mutation updateOption(
    $id: String!, 
    $value: String,
    $icon: String
    ) {
    updateOption(
      id: $id
      value: $value
      icon: $icon
    ) {
      id
      value
      icon
    }
  }
`;

export const UPDATE_OPTION_ASSET = gql`
  mutation updateOption(
    $id: String!, 
    $asset: String
    ) {
    updateOption(
      id: $id
      asset: $asset
    ) {
      id
      value
      asset
    }
  }
`;



export const DELETE_OPTION= gql`
  mutation ($id: String!) {
    deleteOption(id: $id)
  }
`;


export const REORDER_OPTION= gql`
  mutation ($optionsOrder: [OrderOption]) {
    reorderOptions(optionsOrder: $optionsOrder){
      id
      value
      icon
      selected
      order
      type
      asset
      description
      link
    }
  }
`;