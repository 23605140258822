import gql from "graphql-tag";

export const GET_STYLE = gql`
query($myform: String!) {
  formStyle(myform: $myform) {
      id
      font
      primaryColor
      selectionColor
      labelColor
      backgroundColor
      pagelayout
      headerTextAlign
      fieldbgcolor
      formStyle
      logo
      logosize
      logourl
      logoposition
      backgroundheadlineurl
      bgimgposition
      bgimgurl
      backgroundformcolor
      buttonsubmittext
      bgimgtint
      showshadowbgthankyou
      buttonprefix
      buttoncaption
      buttoncaptiontoggle
      videobackgroundurl
      videobackgroundtype
    }
  }
`;

export const GET_RECENT_STYLES = gql`
  query($myuser: String, $tilesCount: String, $myform: String, $page: Int) {
    recentStyles(myuser: $myuser, tilesCount: $tilesCount, myform: $myform, page: $page) {
      styles {
        id
        font
        primaryColor
        selectionColor
        labelColor
        backgroundColor
        pagelayout
        headerTextAlign
        fieldbgcolor
        formStyle
        logo
        logosize
        logourl
        logoposition
        backgroundheadlineurl
        bgimgposition
        bgimgtintblur
        bgimgurl
        backgroundformcolor
        buttonsubmittext
        bgimgtint
        showshadowbgthankyou
        buttonprefix
        buttoncaption
        buttoncaptiontoggle
        videobackgroundurl
        videobackgroundtype
      }
      totalCount
    }
  }
`;


export const UPDATE_STYLE = gql`
  mutation updateStyle(
    $myform: String!, 
    $font: String, 
    $primaryColor: String, 
    $selectionColor: String,
    $labelColor: String,
    $formStyle: String,
    $backgroundColor: String,
    $pagelayout: String,
    $headerTextAlign: String,
    $fieldbgcolor: String,
    $logo: String,
    $logosize: String,
    $logourl: String
    $logoposition: String
    $bgimgposition: String
    $bgimgurl: String
    $backgroundformcolor: String
    $buttonsubmittext: String
    $bgimgtint: Boolean
    $showshadowbgthankyou: Boolean
    $buttonprefix: String
    $buttoncaption: String
    $buttoncaptiontoggle: Boolean
    $videobackgroundurl: String
    $videobackgroundtype: String

  ) {
    updateStyle(
      myform: $myform
      font: $font
      primaryColor: $primaryColor
      selectionColor: $selectionColor
      labelColor: $labelColor
      formStyle: $formStyle
      backgroundColor: $backgroundColor
      pagelayout: $pagelayout
      headerTextAlign: $headerTextAlign
      fieldbgcolor: $fieldbgcolor
      logo: $logo,
      logosize: $logosize,
      logoposition: $logoposition,
      logourl: $logourl
      bgimgposition: $bgimgposition
      bgimgurl: $bgimgurl
      backgroundformcolor: $backgroundformcolor
      buttonsubmittext: $buttonsubmittext
      bgimgtint: $bgimgtint
      showshadowbgthankyou: $showshadowbgthankyou
      buttonprefix: $buttonprefix
      buttoncaption: $buttoncaption
      buttoncaptiontoggle: $buttoncaptiontoggle
      videobackgroundtype: $videobackgroundtype
      videobackgroundurl: $videobackgroundurl
    ) {
      id
    }
  }
`;

export const UPDATE_TO_QUIZ = gql`
mutation updateStyle(
  $myform: String!, 
  $pagelayout: String
) {
  updateStyle(
    myform: $myform
    pagelayout: $pagelayout
  ) {
    id
  }
}
`;

export const UPDATE_LOGO = gql`
mutation updateStyle(
  $myform: String!, 
  $logo: String,
  $logosize: String,
  $logourl: String,
  $logoposition: String
) {
  updateStyle(
    myform: $myform
    logo: $logo,
    logosize: $logosize
    logourl: $logourl
    logoposition: $logoposition
  ) {
    id
  }
}
`;


export const UPDATE_BUTTON_PREFIX = gql`
mutation updateStyle(
  $myform: String!, 
  $buttonprefix: String
) {
  updateStyle(
    myform: $myform
    buttonprefix: $buttonprefix
  ) {
    id
  }
}
`;

export const UPDATE_BUTTON_CAPTION = gql`
mutation updateStyle(
  $myform: String!, 
  $buttoncaption: String
  $buttoncaptiontoggle: Boolean
) {
  updateStyle(
    myform: $myform
    buttoncaption: $buttoncaption
    buttoncaptiontoggle: $buttoncaptiontoggle
  ) {
    id
  }
}
`;



export const UPDATE_PRIMARY_COLOR = gql`
mutation updateStyle(
  $myform: String!, 
  $primaryColor: String
) {
  updateStyle(
    myform: $myform
    primaryColor: $primaryColor
  ) {
    id
  }
}
`;

export const UPDATE_BG_HEADER = gql`
mutation updateStyle(
  $myform: String!, 
  $bgimgurl: String,
  $bgimgposition: String,
  $videobackgroundtype: String,
  $videobackgroundurl: String
) {
  updateStyle(
    myform: $myform
    bgimgurl: $bgimgurl
    bgimgposition: $bgimgposition
    videobackgroundtype: $videobackgroundtype
    videobackgroundurl: $videobackgroundurl
  ) {
    id
  }
}
`;

export const UPDATE_THANKYOU_BG = gql`
mutation updateStyle(
  $myform: String!, 
  $bgimgtintblur: String,
  $bgimgurl: String
) {
  updateStyle(
    myform: $myform
    bgimgtintblur: $bgimgtintblur
    bgimgurl: $bgimgurl
  ) {
    id
  }
}
`;

export const UPDATE_IMG_TINT_BLUR = gql`
mutation updateStyle(
  $myform: String!, 
  $bgimgtintblur: String
) {
  updateStyle(
    myform: $myform
    bgimgtintblur: $bgimgtintblur
  ) {
    id
  }
}
`;

export const UPDATE_IMG_TINT = gql`
mutation updateStyle(
  $myform: String!, 
  $bgimgtint: Boolean
) {
  updateStyle(
    myform: $myform
    bgimgtint: $bgimgtint
  ) {
    id
  }
}
`;


export const UPDATE_BG_POSITION = gql`
mutation updateStyle(
  $myform: String!, 
  $bgimgposition: String
) {
  updateStyle(
    myform: $myform
    bgimgposition: $bgimgposition
  ) {
    id
  }
}
`;


export const UPDATE_BG_SHADOW_THANKYOU = gql`
mutation updateStyle(
  $myform: String!, 
  $showshadowbgthankyou: Boolean
) {
  updateStyle(
    myform: $myform
    showshadowbgthankyou: $showshadowbgthankyou
  ) {
    id
  }
}
`;


export const UPDATE_BG_VIDEO_TYPE = gql`
mutation updateStyle(
  $myform: String!, 
  $videobackgroundtype: String
) {
  updateStyle(
    myform: $myform
    videobackgroundtype: $videobackgroundtype
  ) {
    id
  }
}
`;

export const UPDATE_BG_VIDEO_URL = gql`
mutation updateStyle(
  $myform: String!, 
  $videobackgroundurl: String,
  $bgimgurl: String
  $bgimgposition: String
) {
  updateStyle(
    myform: $myform
    videobackgroundurl: $videobackgroundurl
    bgimgurl: $bgimgurl
    bgimgposition: $bgimgposition
  ) {
    id
  }
}
`;


export const REMOVE_BG_VIDEO_SETTINGS = gql`
mutation updateStyle(
  $myform: String!, 
  $videobackgroundurl: String,
  $videobackgroundtype: String,
  $bgimgurl: String
  $bgimgposition: String
) {
  updateStyle(
    myform: $myform
    videobackgroundurl: $videobackgroundurl
    videobackgroundtype: $videobackgroundtype
    bgimgurl: $bgimgurl
    bgimgposition: $bgimgposition
  ) {
    id
  }
}
`;




export const CREATE_STYLE = gql`
  mutation createStyle(
    $myform: String!
  ) {
      createStyle(
        myform: $myform
    )
  }
`;


export const REMOVE_HEADLINE_BG = gql`
mutation updateStyle(
  $myform: String!, 
  $bgimgurl: String,
  $bgimgtint: Boolean
) {
  updateStyle(
    myform: $myform
    bgimgurl: $bgimgurl
    bgimgtint: $bgimgtint
  ) {
    id
  }
}
`;


export const REMOVE_LOGO = gql`
mutation updateStyle(
  $myform: String!, 
  $logo: String,
  $logosize: String
) {
  updateStyle(
    myform: $myform
    logo: $logo
    logosize: $logosize
  ) {
    id
  }
}
`;


