import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import styles from '../css/staff/Staff.module.scss'; // Import the SCSS file
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_TEMPLATE,UPDATE_TEMPLATE,DELETE_TEMPLATE, DELETE_CATEGORY_ROW,TEMPLATE,GET_TEMPLATE_CATEGORIES,CREATE_CATEGORY_ROWS } from '../graphql/Template'; // Import your queries
import {TemplateCategory} from './TemplateCategory';

const CreateTemplate = ({ shorthand }) => {
  const initialFormData = {
    previewImage: '',
    name: '',
    myform: '',
    shorthand: '',
    description: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [templateCreated, setTemplateCreated] = useState(false);
  const [markdownContent, setMarkdownContent] = useState(''); // New state variable for Markdown content
  const [currentTemplate, setCurrentTemplate] = useState(null); // New state variable for Markdown content
  const [selectedCategory, setSelectedCategory] = useState('');

  const [createTemplate] = useMutation(CREATE_TEMPLATE, {
    onCompleted: (data) => {
      // After a successful mutation, set templateCreated to true
      setTemplateCreated(true);
      window.location.reload();

      // Extract the ID from the response and set it to templateCreatedId
    },
  });
  const [deleteTemplate] = useMutation(DELETE_TEMPLATE, {}); // Define options as needed

  const [createCategoryRows] = useMutation(CREATE_CATEGORY_ROWS, {
    // Define any options you need for the mutation here
  });
  const [deleteCategoryRow] = useMutation(DELETE_CATEGORY_ROW, {
    // Define any options you need for the mutation here
  });
  const handleDeleteTemplate = async () => {
    try {
      await deleteTemplate({
        variables: {
          id: currentTemplate, // Pass the ID of the template to be deleted
        },
      });
      window.location.reload();

      // Handle any UI updates or redirects as needed after successful deletion
    } catch (error) {
      console.error('Error deleting template:', error);
      // Handle any errors here
    }
  };
  const handleDeleteCategory = async (categoryId) => {
    try {
      // Call the DELETE_CATEGORY_ROW mutation
      await deleteCategoryRow({
        variables: {
          id: categoryId, // Pass the ID of the category to be deleted
        },
      });
      // After successful deletion, you can update the UI or refetch data as needed
      refetchTemplateCat();
    } catch (error) {
      console.error('Error deleting category:', error);
      // Handle any errors here
    }
  };
    const handleCreateCategory = async () => {

    try {
      // Call the CREATE_CATEGORY_ROWS mutation
      await createCategoryRows({
        variables: {
          mytemplate: currentTemplate, // Pass the currentTemplate ID
          title: selectedCategory, // Pass the selected category
          featured: false, // You can adjust this value as needed
        },
      });
      // You might want to refetch the template categories or update the UI in some way
      refetchTemplateCat();
    } catch (error) {
      console.error('Error creating category:', error);
      // Handle any errors here
    }
  };



  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    onCompleted: (updateData) => {
      // After a successful mutation, set templateCreated to true
      setTemplateCreated(true);
      window.location.reload();

      // Extract the ID from the response and set it to templateCreatedId
    },
  });

  // Fetch the template data based on the provided templateId
  const { loading, error, data, refetch } = useQuery(TEMPLATE, {
    variables: { shorthand: shorthand },
    skip: !shorthand, // Skip if templateId is not provided
  });
  // Fetch the template data based on the provided templateId
  const { loading:loadingTemplateCat, error:errorTemplateCat, data:dataTemplateCat, refetch:refetchTemplateCat } = useQuery(GET_TEMPLATE_CATEGORIES, {
    variables: { mytemplate: currentTemplate },
    skip: !currentTemplate, // Skip if templateId is not provided
  });

  // Set formData based on the fetched data when it's available
  useEffect(() => {
    if (data && data.template) {
      const { name, myform, shorthand, description, previewImage, id } = data.template;
      setFormData({
        previewImage,
        name,
        myform,
        shorthand,
        description,
      });
      setCurrentTemplate(id);
      console.log(currentTemplate);
      setMarkdownContent(description);
    }
  }, [data]);

  useEffect(() => {
    // Update the Markdown content whenever formData.description changes
    setMarkdownContent(formData.description);
  }, [formData.description]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!shorthand){
         // Use formData to access the form data
    await createTemplate({
      variables: {
        previewImage: formData.previewImage,
        name: formData.name,
        myform: formData.myform,
        shorthand: formData.shorthand,
        description: formData.description,
      },
    });
    refetch();
    setTemplateCreated(true);

    }else {
      console.log('heyy')
      await updateTemplate({
        variables: {
          previewImage: formData.previewImage,
          name: formData.name,
          myform: formData.myform,
          shorthand: formData.shorthand,
          description: formData.description,
        },
      });
      refetch();
      setTemplateCreated(true);
    }
  };
  // console.log('formData.description:', formData.description); // Add this line

  return (
    <div className={`${styles.container}`}>
      {!shorthand ?<h1>Create Template</h1>:<h1>Edit Template</h1>}
      {shorthand && <div
      style={{padding:'12px',margin:"20px 0",border:'2px solid rgba(255,255,255,0.1)', width:'100%', boxSizing:'border-box'}}>
        <a style={{color:"#4deeea", cursor:"pointer", marginBottom:"4px"}} target="_blank" href={'/template/' + shorthand}>Go to template</a>
        <div style={{color:"#F08080", cursor:"pointer", marginBottom:"4px"}} onClick={handleDeleteTemplate}>Delete permanently</div>

            <div>
            {dataTemplateCat?.templatecategories.length>0&& <> <h2>Your categories</h2>
              {dataTemplateCat&&dataTemplateCat?.templatecategories.map((cat) => (
              <div key={cat.id}>{cat.title}
               <button onClick={() => handleDeleteCategory(cat.id)}>Delete</button>
              </div>
                  ))}
              </>}
              <h2 style={{fontSize:'14px',color:'white', textTransform:'uppercase',marginBottom:"10px",paddingTop:"10px"}}>Add categories</h2>
              <select style={{color:'white', backgroundColor:'rgba(0,0,0,0.3)',border:'2px solid rgba(255,255,255,0.1)', padding:"8px", borderRadius:'8px'}} value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                {TemplateCategory.map((topic) => (
                  <option key={topic.name} value={topic.name}>
                    {topic.label}
                  </option>
                ))}
              </select>

              <a style={{marginLeft:'12px',padding:"8px", borderRadius:"8px",backgroundColor:"white",color:"black"}} onClick={handleCreateCategory}>Add</a>
            </div>

      </div>}
      {!templateCreated ? (
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="previewImage">Preview Image:</label>
            <input
              type="text"
              id="previewImage"
              name="previewImage"
              value={formData.previewImage}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="name">Template Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="myform">My Form:</label>
            <input
              type="text"
              id="myform"
              name="myform"
              value={formData.myform}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="shorthand">Short Hand:</label>
            <input
              type="text"
              id="shorthand"
              name="shorthand"
              value={formData.shorthand}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.formGroupDesc}>
            <div>
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className={styles.descriptionTextarea}
            ></textarea>
            </div>
            <div className={styles.markdownPreview}>
              <h2>Markdown Preview</h2>
              <ReactMarkdown children={markdownContent} remarkPlugins={[gfm]} />
            </div>
          </div>


          <button type="submit" className={styles.submit}>
            {shorthand?'Update Template':'Create Template'}
          </button>
        </form>
      ) : (
        <div className={styles.success}>
          {!shorthand?<>
          <h2>Template Created!</h2>
          <p>Your new template has been created successfully.</p>
          </>:<>
          <h2>Template Updated!</h2>
          </>}
        </div>
      )}


    </div>
  );
};

export default CreateTemplate;
